import React from 'react';
import OurStory from './posts/OurStory';
import { postsDE, postsEn, postsNL } from '../../languages/blogPages';
import { Newsletter } from '../informative/Newsletter';
import { useParams } from 'react-router-dom';
import {
    createStyles,
    Group,
    Title,
    Text,
    Container,
    rem,
    Avatar,
    ActionIcon,
} from '@mantine/core';
import { IconBrandLinkedin } from '@tabler/icons-react';
import Recycling from './posts/Recycling';
import VAT from './posts/Vat';
import Cleaning from './posts/Cleaning';

function Blogposts({ media_link }) {
    if (media_link === 1) {
        return (<OurStory />)
    } else if (media_link === 2) {
        return (<Recycling />)
    } else if (media_link === 3) {
        return (<VAT />)
    } else {
        return (<Cleaning />)
    }
}

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    description: {
        fontWeight: 200,
    },

    card: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
    },

    name: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

}));


export default function BlogpostLayout() {

    const { classes } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '';
    const posts = languagePrefix === '/nl' ? postsNL : languagePrefix === '/de' ? postsDE : postsEn;

    const { post_id } = useParams();
    const post = posts.find((post) => post.id === parseInt(post_id, 10));

    if (!post) {
        return <div>{
            languagePrefix === '/nl'
                ? 'Helaas, deze blogpost bestaat niet'
                : languagePrefix === '/de'
                    ? 'Leider gibt es diesen Blogbeitrag nicht'
                    : 'Unfortunately, the blogpost you were searching for does not exist'}
        </div>;
    }

    return (
        <>
            <Container pt={100}>
                <Title order={4} weight={200} align="left" color="resirent" mb="md">
                    {post.date}
                </Title>
                <Title order={1} align="left" mt="md">
                    {post.title}
                </Title>
                <Text c="dimmed" className={classes.description} align="left" mt="md">
                    {post.description}
                </Text>
            </Container>

            {/* show the button if showScrollButton is true => Still to do */}

            <Container pt="md">
                <Blogposts media_link={post.id} />
            </Container >

            <Container mb="md">
                <Group noWrap>
                    <Avatar src={post.author.imageUrl} size={128} radius="md" />
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text pr="md" fz="xs" tt="uppercase" fw={700} c="dimmed">
                                {post.author.role}
                            </Text>
                            <ActionIcon component="a" href={post.author.href} color="resirent" variant="outline" key={post.author.name} size={28}>
                                <IconBrandLinkedin size="1.4rem" stroke={1.5} />
                            </ActionIcon>
                        </div>

                        <Text fz="lg" fw={500} className={classes.name}>
                            {post.author.name}
                        </Text>

                        <Group noWrap spacing={10} mt={5}>
                            <Text fz="xs" c="dimmed">
                                {post.author.description}
                            </Text>
                        </Group>
                    </div>
                </Group>
            </Container>

            <Newsletter />

            {/* Share your opinion */}
            {/*} <p className = "mt-6 text-sm text-gray-500 text-justify">
Have you found other solutions or have additional suggestions? 
Please share them with us by using the form below!
</p> */}
        </>
    )
}