import the_start from '../../../../assets/blogPosts/OurStory/TheVillage.jpg'
/* This is a blogpost layout to try */
import vat1 from '../../../../assets/blogPosts/tax/VAT/VAT1.png'
import vat2 from '../../../../assets/blogPosts/tax/VAT/VAT2.png'
import vat3 from '../../../../assets/blogPosts/tax/VAT/VAT3.png'
import vat5 from '../../../../assets/blogPosts/tax/VAT/VAT4.jpg'
import vat4 from '../../../../assets/blogPosts/tax/VAT/VAT5.jpg'
import { Image } from '@mantine/core';

export const vatEN = [
    {
        id: 'section-0',
        title: 'Introduction',
        content: 'The first thought that came to our minds when we learned that we became VAT obligated was: "even more administration!" We are now facing the third declaration and have already experienced many benefits, which we would like to share with our fellow hosts. It is not as daunting as we feared.   In this blog post, we will share what we have learned from preparing our VAT accounting and the questions we encountered, so you do not have to search for it anymore.',
    },
    {
        id: 'section-1',
        title: '1. Legal obligation VAT accounting: the conditions.',
        content: (<>
            Below, we provide you with the literal text of the law according to the VAT Code Article 44 §3 2° as of July 1, 2022:
            <span className="resirent-p-small italic" > "Providing furnished accommodation for a period of less than three months in hotels, motels, and establishments with a similar function that usually provide accommodation to paying guests for a period of less than three months, unless these establishments do not provide any of the following closely related services: physical reception of guests, provision of household linen, and, when furnished accommodation is provided for a period of more than one week, its replacement at least once a week, and the daily provision of breakfast, by the operator of the accommodation or by a third party on his behalf."  </span>
            Providing one of these services is sufficient to be VAT obligated. This means that we, as small hosts, who also offer these services, are now VAT obligated and must submit VAT returns.
        </>)
    },
    {
        id: 'section-2',
        title: '2. Where to apply for a VAT number',
        content: (
            <div>
                <p className="mt-6 leading-8 resirent-p text-justify">Step one, if you want to comply with the legislation, is to apply for a VAT number. This can be done through various channels, which we will briefly outline here.</p>
                <div class="py-6">
                    <div>
                        <ul class="list-decimal list-inside resirent-p text-justify">
                            <li>Through a social security office: Acerta, Xerius, Liantis. You will have to pay a small fee to them.</li>
                            <li>Through the VAT administration: Fill in Form 604A. This is free of charge.</li>
                            <li>Belgian resident living abroad: Since we do not reside in Belgium, we could not directly approach the VAT administration. We were assisted by the foreigners team: send an email to <a className="underline" href="mailto:foreigners.team1@minfin.fed.be">foreigners.team1@minfin.fed.be</a> </li>
                        </ul>
                    </div>
                </div>
                <p className="mt-6 leading-8 resirent-p text-justify">After receiving the VAT number, the real work begins: the administration. This involves a lot of tasks, such as recording receipts and expenses, submitting them to the VAT accounting in the correct format. You can choose to do it all by yourself, outsource it to an accountant, or subscribe to a software program.</p>
            </div>
        ),
    },
    {
        id: 'section-3',
        title: '3. The benefits and ease of a software program',
        content: (
            <div>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    When you have your accounting done by an accountant, you can rest assured that everything is properly recorded and your declarations reach the VAT administration on time. However, this comes at a high cost, easily taking up 20% of your profit margin.
                    Moreover, it seems difficult for small business owners to find an accountant.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    So we started looking for alternatives and came across DEXXTER. They specialize in accounting for self-employed part-time entrepreneurs with a sole proprietorship, as well as VAT accounting for vacation rentals. We got to know them through a webinar by ACERTA where Dexxter presented their software, specifically designed for vacation rentals. Their mantra is: "understandable and reliable software."
                    Below, we describe the advantages of working with accounting software.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">Not expensive:</span> A software program for 180 euros per year is not expensive at all if it delivers what it promises! Furthermore, through our blog, you can click on the discount code and get an additional 25 euros off for the first year: <span className="font-extrabold text-xl text-blue-600"><a href="https://dexxter.be">RESIRENT25</a></span>.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">Does not take much time:</span> The first weekend after signing up with Dexxter, I entered all the income and expenses, and all the suppliers into the program from the beginning of the year. I spent a total of 17 hours on this task. Now, if we keep track of all the income and expenses neatly throughout the quarter, we need a maximum of 15 minutes for the VAT declaration.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">Peace of mind:</span> Dexxter sends automatic reminders when the VAT declaration and customer list are due, so it gives us tremendous peace of mind. We have to submit the VAT declaration every quarter and the customer list once a year. Moreover, it also brings peace of mind to be in compliance with the legislation.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">More insight:</span> By entering everything ourselves, we gained a much better insight into our income and expenses. A small example for us is the price of garbage bags. They are very expensive! We have adjusted our system accordingly and now use small garbage bags that we put into the official large bags instead of putting out half-full garbage bags.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">I understand it better myself:</span> I didn't have any training in accounting myself. But with Dexxter, I did gain more knowledge. Simply by being involved in my own bookkeeping, I understood it better. The principle of VAT accounting is now clear to me, and it is actually very advantageous if you handle it correctly. As a landlord, you owe 6% on your income, but on most expenses and investments (e.g., not on insurance, you can find more explanation on the Dexxter website), you can reclaim 6% or 21%. This means that if your expenses and investments together amount to more than 28.5% (6/21) of your revenue, you always win. This will often be the case because your expenses include all the costs you incur (e.g., cleaning crew, coffee, cleaning products, laundry) plus investments such as maintenance work, replacing broken furniture or furnishings, renovations, and improvements to your property.
                </p>
            </div>
        ),
    },
    {
        id: 'section-4',
        title: '4. Tips for the typical accounting of a vacation rental',
        content: (
            <div>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    The accounting for a vacation rental is not exactly the same as for a sole proprietorship. We have listed the main differences and their solutions below.
                </p>
                <div class="py-6">
                    <div>
                        <ul class="list-disc list-inside resirent-p text-justify">
                            <li className="mt-6 mb-6">Taking into account personal use:</li>
                            <p className="leading-8 resirent-p text-justify">
                                If you also occasionally stay in your vacation rental, you need to adjust the VAT proportionally based on the actual usage for renting to third parties. We stay in our vacation apartment for slightly less than 10% of the year, so we reduce the VAT deduction to 90%.
                            </p>
                            <Image maw={600} mx="auto" radius="md" src={vat2} alt="description" />
                            <li className="mt-6 mb-6">How to account for income received through platforms:</li>
                            <p className="leading-8 resirent-p text-justify">
                                Initially, I had difficulties with this. Like most of us, we primarily receive our customers through major platforms such as booking.com, Airbnb, VRBO, etc. As a result, we usually do not invoice our customers directly. The Dexxter team suggested that we record the income from these sources as daily income. It is crucial to indicate that these amounts are gross income and include 6% VAT.
                            </p>
                            <p className="leading-8 resirent-p text-justify">
                                It is not very difficult in itself; it simply requires meticulous recording. Below is a screenshot of the income from booking.com in December 2022:
                            </p>
                            <Image maw={600} mx="auto" radius="md" src={vat3} alt="description" />
                            <li className="mt-6 mb-6">What to do when foreign guests request an invoice:</li>
                            <p className="leading-8 resirent-p text-justify">
                                This is not a problem for private individuals. You charge 6% VAT.
                            </p>
                            <p className="leading-8 resirent-p text-justify">
                                For legal entities with VAT, it is also the same! This is sometimes confusing because in most business operations, the VAT is reversed, so it is equal to zero euros.
                            </p>
                            <p className="leading-8 resirent-p text-justify">
                                We fall under hotel services, and in this case, the location of the "hotel," in our case the vacation rental, determines the place of VAT. Therefore, we must charge the local Belgian VAT. Practically:
                                We received a request for an invoice from someone who stayed at our vacation rental for a week for his company in Portugal. He had booked through Booking.com, and you cannot generate invoices for your customers via Booking.com.
                                Since Dexxter knows that you are a vacation rental owner (and thus must charge local Belgian VAT), 6% VAT is also charged here. You can also send it directly to the customer via email through Dexxter.
                            </p>
                            <li className="mt-6 mb-6">Managing expenses:</li>
                            <p className="leading-8 resirent-p text-justify">
                                Managing your expenses is very simple.
                                Take a scan of your invoice and upload it to the program. Think carefully about how you classify your suppliers. We only realized how interesting this is after the first settlement because you can clearly see which activities consume most of your budget. You can manually create your cost categories in DEXXTER. For our accounting, I created typical categories such as: Electricity, water, final cleaning, transaction costs, and commission costs of booking platforms, decoration, WIFI + TV, household purchases (coffee, tea, oil).
                                Below is an example of the invoice from the cleaning company we work with:
                            </p>
                            <Image maw={600} mx="auto" radius="md" src={vat1} alt="description" />
                            <p className="leading-8 resirent-p text-justify">
                                By maintaining accurate VAT accounting, we make vacation rental management more sustainable. Sustainability begins with "good management."
                            </p>
                        </ul>
                    </div>
                </div>
            </div>
        ),

    },
    {
        id: 'section-5',
        title: '5. Sustainable management of our accommodations',
        content: (<>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Sustainable management of our accommodations, which includes official invoices, is now a given and offers many benefits. Let's summarize them below:
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                <span className="leading-7 resirent-h-top text-left">Legislation: </span>We are very satisfied to have found a solution to comply with the regulations.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                <span className="leading-7 resirent-h-top text-left">Financial overview: </span>With the software program, we have a clear overview of our finances at all times. We can also easily identify trends in income and expenses and adjust our management accordingly.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                <span className="leading-7 resirent-h-top text-left">Investments in sustainable renovations: </span>One major opportunity we see with VAT accounting is the ability to recover VAT on investments. Without prior knowledge of the VAT legislation, we had already planned renovations and the redecoration of our vacation apartment in the second half of 2022. Thanks to this new regulation, we were able to recover a significant amount of resources. This financial boost prompted us to accelerate the final phase of our transition to a sustainable accommodation.
            </p>

            <Image maw={600} mx="auto" radius="md" src={vat4} alt="description2" />
            <Image maw={600} mx="auto" radius="md" src={vat5} alt="description3" />
            <p className="mt-6 leading-8 resirent-p text-justify">
                In November 2023, we are planning further renovations, including new energy-efficient windows, a new kitchen with efficient appliances, and new heating. Naturally, we are committed to engaging local businesses for all the work, thereby supporting the local economy. When it comes to lighting and furniture selection, we prioritize Belgian creations and manufacturing. For tasks such as installing the new flooring, painting, and making new curtains, we will entrust them to companies based in Ghent!
            </p>
        </>),
    },
    {
        id: 'section-6',
        title: '6. Conclusion: How did we get 2,482.42 Euros back from the VAT administration?',
        content: "  If you want to comply with the legislation, the first step is to apply for a VAT number and decide how you will manage your bookkeeping: whether you will handle it independently, outsource it to an accountant, or subscribe to a software program. Maintaining proper bookkeeping helps you run your vacation rental property sustainably, and the government supports us in this regard. By collaborating with official suppliers for final cleaning or laundry services and by recording our renovation works, we were able to claim back the VAT. Therefore, we received this amount by indeed working with official companies and accurately documenting our investments."
    },
    {
        id: 'section-7',
        title: '7. Dexxter',
        content: (<>
            If you're interested in a software program after reading this blog post, be sure to check out DEXXTER. You can immediately watch webinars and demos, and the first 30 days are free.
            By collaborating with them, we have a special discount code that offers a 25 euro discount on the first year of membership! You only need the code: <span className="font-extrabold text-xl text-blue-600"><a href="https://dexxter.be">RESIRENT25</a></span>.
        </>
        )
    },

    // Add more sections as needed
];

export const vatNL = [
    {
        id: 'section-0',
        title: 'Introductie',
        content: "De eerste gedachte die bij ons opkwam toen we vernamen dat we btw-plichtig werden was: “nog meer administratie!” We staan nu voor de derde aangifte en hebben ondertussen ook veel voordelen ondervonden, welke we graag delen met onze collega gastgevers. Het valt allemaal veel beter mee dan dat we vreesden. We vertellen je in deze blogpost wat we ondervonden bij het opmaken van onze btw-boekhouding en op welke vragen we gestoten zijn, zo hoeven jullie dit niet meer uit te zoeken.",
    },
    {
        id: 'section-1',
        title: '1. Wettelijke verplichting btw-boekhouding: de voorwaarden.',
        content: "Hier onder geven we jullie nog eens de letterlijke wettekst volgens het btw-wetboek art 44 §3 2° is sinds 1 juli 2022: ″Het verschaffen, voor een periode van minder dan drie maanden, van gemeubeld logies in hotels, motels en inrichtingen met een soortgelijke functie waar gewoonlijk aan betalende gasten onderdak wordt verleend voor een periode van minder dan drie maanden, tenzij die inrichtingen geen van de volgende nauw samenhangende diensten verrichten: instaan voor het fysiek onthaal van de gasten, de terbeschikkingstelling van huishoudlinnen en, wanneer het gemeubeld logies wordt verstrekt voor een periode van meer dan een week, de vervanging ervan tenminste eenmaal per week, en het dagelijks verschaffen van het ontbijt, door de exploitant van het logies of door een derde voor zijn rekening. Het verschaffen van één van deze diensten volstaat om btw-plichtig te zijn. Dit betekent dat wij, kleine gastgevers, die deze diensten ook aanbieden, nu ook btw-plichtig zijn en btw-aangifte moeten doen.",
    },
    {
        id: 'section-2',
        title: '2. Waar een btw-nummer aanvragen',
        content: (
            <div>
                <p className="mt-6 leading-8 resirent-p text-justify">Stap één als je in orde wil zijn met de wetgeving is een btw-nummer aanvragen. Dit kan via verschillende kanalen die we hier kort opsommen.</p>
                <div class="py-6">
                    <div>
                        <ul class="list-decimal list-inside resirent-p text-justify">
                            <li> Via een sociaal secretariaat: Acerta, Xerius, Liantis, aan hen zal je een kleine kost moeten betalen.</li>
                            <li> Via de btw-administratie: Formulier 604A invullen. Dit is gratis.</li>
                            <li> Belg die in het buitenland woont: Wij konden vermits we niet in België wonen niet rechtstreeks bij de btw-administratie terecht. We werden verder geholpen via het foreigners team: stuur een mail naar <a className="underline" href="mailto:foreigners.team1@minfin.fed.be">foreigners.team1@minfin.fed.be</a> </li>
                        </ul>
                    </div>
                </div>
                <p className="mt-6 leading-8 resirent-p text-justify">Na ontvangst van het btw-nummer begint het echte werk: de administratie. Hierbij komt heel wat kijken, het inboeken van ontvangsten en uitgaven, het in het juiste formaat doorsturen aan de btw-boekhouding. Je kan kiezen om het volledig zelf te doen, het uit handen te geven aan een boekhouder of je te abonneren op een softwareprogramma.</p>
            </div>
        ),
    },
    {
        id: 'section-3',
        title: '3. De voordelen en het gemak van een softwareprogramma',
        content: (
            <div>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Wanneer je je boekhouding door een boekhouder laat doen, kan je gerust zijn dat alles perfect ingeboekt is en je aangiften op tijd bij de btw-administratie aankomen. Hiertegenover staat dat de kost voor een boekhouder hoog is en makkelijk 20% van je winstmarge in beslag neemt.
                    Bovendien blijkt het vinden van een boekhouder voor kleine ondernemers moeilijk te zijn.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Dus gingen wij op zoek naar alternatieven en kwamen bij DEXXTER terecht. Zij hebben zich gespecialiseerd in boekhouden voor zelfstandigen in bijberoep met een eenmanszaak alsook de btw-boekhouding voor vakantiewoningen. We leerden hen kennen via een webinar van ACERTA waarin Dexxter ons hun software voorstelde, specifiek gericht op vakantieverhuur. Hun mantra luidt: “begrijpbare en betrouwbare software.”
                    We beschrijven hieronder de voordelen van het werken met een softwareprogramma voor je boekhouding.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">Niet duur: </span>Een softwareprogramma voor 180 euro per jaar is helemaal niet duur als het natuurlijk doet wat het belooft! Bovendien kan je via onze blog de korting code aanklikken en krijg je het eerste jaar nog eens 25 euro korting bovenop:  <span className="font-extrabold text-xl text-blue-600"><a href="https://dexxter.be">RESIRENT25</a></span> op de website van <span> <a href="https://dexxter.be"> DEXXTER </a></span>.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">Neemt niet veel tijd in beslag: </span>Het eerste weekend nadat ik me aangemeld had bij Dexxter heb ik alle in- en uitgaven, alle leveranciers in het programma ingebracht vanaf het begin van het jaar. Ik ben toch 17 uren effectief aan het werk geweest. Als we nu alle inkomsten en uitgaven netjes bijhouden gedurende het kwartaal hebben we voor de btw-aangifte maximaal 15 minuten nodig.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">Gemoedsrust: </span>Dexxter stuurt automatische reminders wanneer de btw-aangifte en de klantenlijst binnen moet zijn, dus dat geeft ons een enorme gemoedsrust.  De btw-aangifte moeten we elk kwartaal indienen, de klantenlisting 1 maal per jaar. Bovendien brengt het ook gemoedsrust in orde met de wetgeving te zijn.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">Meer inzicht: </span>Door alles zelf in te geven kregen we een veel beter inzicht in onze inkomsten en uitgaven. Een klein voorbeeld hiervan bij ons zijn de prijs van vuilzakken. Die zijn erg duur! We hebben onze systeem hieraan aangepast en gebruiken nu kleine vuilzakken die we telkens in de officiële grote zakken stoppen in plaats van halfvolle vuilzakken buiten te zetten.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    <span className="leading-7 resirent-h-top text-left">Ik begrijp het zelf beter: </span>Ik heb zelf geen opleiding in boekhouden gehad. Maar met Dexxter kreeg ik inderdaad meer kennis. Gewoon door zelf met mijn boekhouding bezig te zijn begreep ik het beter. Ook het principe van btw-boekhouding is nu duidelijk voor mij en dit is eigenlijk zeer voordelig als je het goed aanpakt. Op je inkomsten ben je als verhuurder 6% verschuldigd, maar op de meeste (bv. Niet op verzekeringen, meer uitleg vind je op de site van Dexxter) uitgaven en investeringen kan je (6% of 21%) terugvorderen. Dit betekent dat als je uitgaven en investeringen samen meer dan 28.5% (6/21) van je omzet bedragen, je dus altijd wint. Dit zal vaak het geval zijn want tot je uitgaven behoren alle kosten die je maakt (bv. kuisploeg, koffie, kuisproducten, wasserij…) vermeerderd met investeringen zoals onderhoudswerken, vervangen van kapotte meubels of meubilair, renovaties en verbeteringen van je vastgoed.
                </p>
            </div>
        ),
    },
    {
        id: 'section-4',
        title: '4. Tips voor de typische boekhouding van een vakantiewoning ',
        content: (
            <div>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    De boekhouding van een vakantiewoning is niet helemaal hetzelfde als voor een éénmanszaak. We hebben de belangrijkste verschillen en hun oplossingen hieronder opgesomd.
                </p>
                <div class="py-6">
                    <div>
                        <ul class="list-disc list-inside resirent-p text-justify">
                            <li className="mt-6 mb-6"> Rekening houden met privégebruik</li>
                            <p className="leading-8 resirent-p text-justify">
                                Als je zelf ook af en toe in je vakantiewoning verblijft, moet je de btw procentueel aanpassen aan het effectieve gebruik voor verhuur aan derden. Wij verblijven iets minder dan 10 % van de tijd van het jaar in ons vakantie appartement, dus verlagen we de btw-aftrek naar 90%.
                            </p>
                            <Image maw={600} mx="auto" radius="md" src={vat2} alt="description" />
                            <li className="mt-6 mb-6"> Hoe inkomsten inbrengen die we krijgen via de platforms:</li>
                            <p className="leading-8 resirent-p text-justify">
                                Aanvankelijk heb ik hier moeilijkheden ondervonden. Zoals de meeste onder ons krijgen we onze klanten vooral via de grote platforms zoals booking.com, Airbnb, VRBO enz. Hierdoor factureren wij meestal niet rechtstreeks aan onze klanten. Het team van Dexxter geeft ons voorgesteld om de inkomsten via deze bronnen als dag inkomsten te boeken. Heel belangrijk hierbij is aan te geven dat het om bruto-inkomsten gaat en dat deze bedragen inclusief 6% BTW zijn.
                            </p>
                            <p className="leading-8 resirent-p text-justify">
                                Dit is op zich niet zo moeilijk, het vraagt enkel nauwgezet inboeken. Hieronder zie je een screenshot van de inkomsten via booking.com december 2022:
                            </p>
                            <Image maw={600} mx="auto" radius="md" src={vat3} alt="DEXXTER" />
                            <li className="mt-6 mb-6"> Wat als buitenlandse gasten een factuur vragen: </li>
                            <p className="leading-8 resirent-p text-justify">
                                Bij privépersonen is dit sowieso geen probleem. Je rekent 6 % btw aan.
                            </p>
                            <p className="leading-8 resirent-p text-justify">
                                Bij rechtspersonen, dus met btw is dit ook zo! Dit is soms verwarrend omdat je bij de meeste bedrijfsvoeringen de btw verlegd, dus die is dan gelijk aan 0 euro.
                            </p>
                            <p className="leading-8 resirent-p text-justify">
                                Wij vallen onder hoteldiensten, en dan bepaalt de locatie van het “hotel” in ons geval vakantieverblijf de plaats van de btw. Wij moeten dus de lokale, Belgische btw aanrekenen. Praktisch:
                                Wij kregen een vraag naar een factuur van iemand die voor zijn bedrijf in Portugal bij ons een week verbleef. Hij had geboekt via Booking.com en via Booking.com kan je geen facturen aanmaken voor je klanten.
                                Omdat Dexxter weet dat je een verhuurder bent van vakantiewoning (én dus lokale, Belgische btw moet aanrekenen), wordt er ook hier 6% btw aangerekend. Ook het per mail versturen naar klant kan rechtstreeks via Dexxter.
                            </p>
                            <li className="mt-6 mb-6"> Uitgaven beheren: </li>
                            <p className="leading-8 resirent-p text-justify">
                                Het beheren van je uitgaven is heel eenvoudig.
                                Neem een scan van je factuur en laadt ze op in het programma. Denk goed na hoe je je leveranciers classificeert. Wij zagen pas na de eerste afrekening hoe interessant dit is, omdat je dan duidelijk kan zien welke activiteiten het meeste van je budget opslorpen. Je kan je kostencategorieën manueel aanmaken in DEXXTER. Ik maakte voor onze boekhouding typische categorieën op: Elektriciteit, water, eindreiniging, transactiekosten -en commissiekosten boekingsplatforms, decoratie, WIFI + TV, huishoudelijke aankopen (koffie, thee, olie).
                                Hieronder zie je een voorbeeld van de factuur van de poetsfirma waar we mee samenwerken:
                            </p>
                            <Image maw={600} mx="auto" radius="md" src={vat1} alt="BTW" />
                            <p className="leading-8 resirent-p text-justify">
                                Door een correcte btw-boekhouding te voeren maken we het beheer vakantiewoning duurzamer. Duurzaamheid begint bij “good management”.
                            </p>
                        </ul>
                    </div>
                </div>
            </div>
        ),

    },
    {
        id: 'section-5',
        title: '5. Duurzaam beheer van onze accommodaties.',
        content: (<><p className="mt-6 leading-8 resirent-p text-justify">
            Duurzaam beheer van onze accommodaties-dus met officiële facturen-is nu vanzelfsprekend en biedt vele voordelen.
            We zetten ze nog even op een rij:
        </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                <span className="leading-7 resirent-h-top text-left">Wetgeving: </span>we zijn heel tevreden een oplossing gevonden te hebben om ons aan de regelgeving te houden.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                <span className="leading-7 resirent-h-top text-left">Financieel overzicht: </span>Met het softwareprogramma hebben we ten alle tijden een overzicht over onze financiën. We kunnen ook gemakkelijker trends herkennen in de inkomsten en uitgaven en ons beheer daaraan aanpassen.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                <span className="leading-7 resirent-h-top text-left">Investeringen in duurzame renovaties: </span> Een grote kans die wij zien met de btw-boekhouding is de mogelijkheid om de btw op investeringen terug te trekken.
                Zonder vooraf te weten dat de btw-wetgeving eraan kwam hadden wij reeds renovaties en het nieuw inrichten van ons vakantieappartement in de tweede helft van 2022 gepland.  Wij hebben door deze nieuwe regeling veel middelen kunnen recupereren. Deze financiële meevaller deed ons besluiten versneld verder te gaan met het laatste deel van onze transitie naar een duurzame accommodatie.
            </p>

            <Image maw={600} mx="auto" radius="md" src={vat4} alt="description2" />
            <Image maw={600} mx="auto" radius="md" src={vat5} alt="description3" />
            <p className="mt-6 leading-8 resirent-p text-justify">
                In november 2023 plannen we een verdere renovatie: nieuwe goed isolerende ramen, een nieuwe keuken met zuinige toestellen en nieuwe verwarming.
                Vanzelfsprekend engageren we voor alle werken lokale bedrijven om zo de lokale economie te steunen.
                Bij de keuze van de verlichting, meubelen zoeken we naar Belgische creaties en fabricatie. Het installeren van de nieuwe vloer, het schilderen en het maken van de nieuwe gordijnen zullen we allemaal aan Gentse bedrijven overlaten!
            </p>
        </>),
    },
    {
        id: 'section-6',
        title: '6. Besluit: Hoe kregen we 2.482,42 Euro terug van de btw-administratie?',
        content: " Wil je ook in orde zijn met de wetgeving, dan zal je dus eerst en vooral een btw-nummer moeten aanvragen en beslissen hoe je de boekhouding gaat voeren: volledig zelfstandig, uitbesteden aan een boekhouder of je abonneren op een softwareprogramma. Het voeren van een boekhouding helpt je om je vakantiewoning duurzaam te runnen en de overheid komt ons tegemoet. Want als je samenwerkt met officiële leveranciers voor de eindreiniging of de was en als je renovatiewerken doet, kan je de btw terugvorderen. Wij kregen dit bedrag dus terug door inderdaad samen te werken met officiële bedrijven en door onze investeringen in te boeken."
    },
    {
        id: 'section-7',
        title: '7. Dexxter',
        content: (<>
            Mocht je na het lezen van deze blogpost interesse hebben in een softwareprogramma, neem dan zeker een kijkje bij: <span> <a href="https://dexxter.be"> DEXXTER </a></span>. Je kan onmiddellijk Webinars en demo’s bekijken. Bovendien zijn de eerste 30 dagen gratis.
            Door onze samenwerking met hen krijg je via onze kortingscode 25 euro korting op het eerste jaar lidmaatschap met de actiecode  <span className="font-extrabold text-xl text-blue-600"><a href="https://dexxter.be">RESIRENT25</a></span>!
        </>
        )
    },

    // Add more sections as needed
];