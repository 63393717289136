import React, { useState, useEffect } from 'react';
import { Text, NumberInput, Checkbox, Textarea, Select, createStyles, rem, Group, Title, SimpleGrid, Card, Button, MultiSelect } from '@mantine/core';
import axios from '../../api/axios';
import CalendarPriceView from '../calendar/Calendar-pricing/CalendarPriceView';
import useAuth from '../../hooks/useAuth';
import CalendarCleaningView from '../calendar/CleaningView/CalendarCleaningView';

const WEBSITE_URL = '/websitejson';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
    },

    titleCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    descriptionCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
        fontWeight: 400,
    },

    base: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        // Blue neutral color
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[2],
    },

    low: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.green[2] : theme.colors.green[2],
    },

    high: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
    },

    gray: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[2],
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

}));

const CalendarCleaning = () => {

    const { classes } = useStyles();
    const [saved, setSaved] = useState(false);

    return (
        <div>
            <Group position="center">
                <Title order={2} className={classes.title} my="sm" display={'block'} ta="center">
                    Cleaning Schedule
                </Title>
            </Group>

            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                {CalendarCleaningView()}
            </Card>
        </div>
    );
};

export default CalendarCleaning;
