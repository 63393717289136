import React from 'react';
import { Text, Box, SimpleGrid } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { IconHome } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
    paper: {
        m: "md",
        radius: "md",
        align: "center"
    },
    box: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[2],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[5] : theme.colors.blue[5],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    boxWeekday: {
        backgroundColor: theme.colors.resirent[0],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",
        color: theme.colors.white,
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    boxEmpty: {
        backgroundColor: theme.colors.white,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    highSeason: {
        backgroundColor: theme.colors.red[2],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[5] : theme.colors.red[5],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    lowSeason: {
        backgroundColor: theme.colors.green[2],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.green[5] : theme.colors.green[5],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    boxSelected: {
        backgroundColor: theme.colors.yellow[2],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.yellow[3] : theme.colors.yellow[3],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    halfStartBooked: {
        background: `linear-gradient(to right, ${theme.colors.red[2]} 50%, ${theme.colors.blue[2]} 50%)`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    halfEndBooked: {
        background: `linear-gradient(to left, ${theme.colors.red[2]} 50%, ${theme.colors.blue[2]}  50%)`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    halfStartInProcess: {
        background: `linear-gradient(to left, ${theme.colors.green[2]} 50%, ${theme.colors.blue[2]} 50%)`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    halfEndInProcess: {
        background: `linear-gradient(to right, ${theme.colors.green[2]} 50%, ${theme.colors.blue[2]} 50%)`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    halfStartClosed: {
        background: `linear-gradient(to right, ${theme.colors.gray[5]} 50%, ${theme.colors.blue[2]} 50%)`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    halfEndClosed: {
        background: `linear-gradient(to left, ${theme.colors.gray[5]} 50%, ${theme.colors.blue[2]} 50%)`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        border: `1px solid ${theme.colors.gray[0]}`,
    },
}));

export const CalendarMonthViewTourist = (inputForm, date, dateStates, selectedRange, setSelectedRange, itemDisplay) => {
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const { classes } = useStyles();
    const year = date.getFullYear();
    const month = date.getMonth();

    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const daysInMonth = lastDay.getDate();
    const startingDay = (firstDay.getDay() + 6) % 7;
    const today = new Date();
    const furthestBookableDate = new Date(today);
    furthestBookableDate.setFullYear(today.getFullYear() + inputForm.furthestBookableDate);

    const calendar = [];

    const headerRow = (
        <>
            {daysOfWeek.map((day) => (
                <Box className={classes.boxWeekday} key={day}>
                    <Text size="xs" weight={500} align="center" color='white'>
                        {day}
                    </Text>
                </Box>
            ))}
        </>
    );
    calendar.push(headerRow);

    let currentRow = [];
    for (let i = 0; i < startingDay; i++) {
        currentRow.push(
            <Box className={classes.boxEmpty} key={`empty-${i}`} />
        );
    }

    const isSelected = (dateKey) => {

        if (selectedRange.start && selectedRange.end) {
            const startDate = new Date(selectedRange.start);
            const endDate = new Date(selectedRange.end);
            const currentDate = new Date(dateKey);
            return currentDate >= startDate && currentDate <= endDate;
        }
        return false;
    };

    const handleDateClick = (dateKey) => {
        if (selectedRange.start === null && selectedRange.end === null) {
            setSelectedRange({ start: dateKey, end: null });
        } else if (selectedRange.start != null && selectedRange.end === null) {
            if (new Date(dateKey) < new Date(selectedRange.start)) {
                setSelectedRange({ start: dateKey, end: selectedRange.start });
            } else {
                setSelectedRange({ ...selectedRange, end: dateKey });
            }
        } else {
            setSelectedRange({ start: dateKey, end: null });
        }
    };

    for (let day = 1; day <= daysInMonth; day++) {
        const dateKey = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        const currentDate = new Date(dateKey);
        const dateStateInfo = Object.values(dateStates).find(range =>
            new Date(range.start) <= currentDate && new Date(range.end) >= currentDate
        );

        let boxClass = classes.box;
        let isClickable = true;
        let checkIn = false;
        let checkOut = false;

        if (currentDate < today || currentDate > furthestBookableDate) {
            boxClass = classes.boxEmpty;
            isClickable = false;
        } else if (isSelected(dateKey)) {
            boxClass = classes.boxSelected;
        } else if (dateStateInfo) {
            const { booked, start, end } = dateStateInfo;
            if (dateKey === start) {
                boxClass = classes.halfEndBooked;
                checkIn = true;
            } else if (dateKey === end) {
                boxClass = classes.halfStartBooked;
                checkOut = true;
            } else if (booked === 'booked' || booked === 'inProcess' || booked === 'closed') {
                boxClass = classes.highSeason;
                isClickable = false;
            }
        }

        if (inputForm.checkInDays.includes(currentDate.getDay()) || inputForm.checkInDays[0].value === 7) {
            checkIn = true;
        }

        currentRow.push(
            <Box className={boxClass} onClick={() => isClickable && handleDateClick(dateKey)} key={dateKey}>
                <Text size="xs" weight={500}>
                    <SimpleGrid columns={2} spacing="xs" >
                        {day}
                        {checkIn && <IconHome size={16} color="green" className={classes.iconRight} />}
                    </SimpleGrid>
                </Text>
            </Box>
        );

        if (currentRow.length === 7) {
            calendar.push(
                <React.Fragment key={`week-${day}`}>
                    {currentRow}
                </React.Fragment>
            );
            currentRow = [];
        }
    }

    while (currentRow.length < 7) {
        currentRow.push(
            <Box className={classes.boxEmpty} key={`empty-${currentRow.length}`} />
        );
    }

    calendar.push(
        <React.Fragment key="last-row">
            {currentRow}
        </React.Fragment>
    );

    return calendar;
};
