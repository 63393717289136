import {
  createStyles, SimpleGrid, Card, Image, Text, Container, AspectRatio, Title, Box,
  Group, Avatar, Button, rem, Center, ActionIcon
} from '@mantine/core';

import Anja from '../../assets/team/Anja_profile.jpeg';
import Jente from '../../assets/team/Jente_profile.jpg';
import Kasia from '../../assets/team/Kasia_profile.jpg';
import Jacob from '../../assets/team/Jacob_profile.jpeg';
import { IconRun, IconBallTennis, IconCooker, IconDog, IconGoGame, IconMountain, IconYoga, IconPaint, IconMusic, IconBook, IconCode, IconExternalLink, IconBrandLinkedin, IconToolsKitchen, IconPencil, IconDeviceGamepad } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  card: {
    transition: 'transform 150ms ease, box-shadow 150ms ease',

    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: theme.shadows.md,
    },

    backgroundColor: "#FFF5F9",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600,
  },

  control: {
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.colors.resirentPink[5],
    },
    border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
    backgroundColor: theme.white,
    color: theme.colors.resirentPink[5],
    textAlign: 'center', // Center-align the contents
  },
}));

export default function Team() {
  const { classes } = useStyles();
  const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

  const teamEN = [
    {
      id: 1,
      name: 'Anja Bulteel',
      role: 'Founder and Content Lead',
      imageUrl: Anja,
      description: "Anja wears many hats at ResiRent. From providing the team with insights based on her experience as a small host to crafting engaging content for the platform, she plays a pivotal role in shaping ResiRent's identity. Anja also establishes and nurtures partnerships with local businesses and tourism offices to enhance the offerings for hosts.",
      href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
      icons: [IconDog, IconToolsKitchen, IconMountain],
    },
    {
      id: 2,
      name: 'Jente Clarysse',
      role: 'Software engineer, Creative Director',
      imageUrl: Jente,
      description: "Jente leads the creative team, overseeing the design and branding aspects of ResiRent. She collaborates closely with our tech experts to ensure a seamless fusion of technology and design in our solutions.      ",
      href: 'https://www.linkedin.com/in/jente-clarysse',
      icons: [IconRun, IconPencil, IconDog, IconMountain, IconCode],
    },
    {
      id: 3,
      name: 'Kasia Krasnopolska',
      role: 'Software engineer ',
      imageUrl: Kasia,
      description: "Kasia is a key player in our tech team, responsible for developing and maintaining the software architecture that powers ResiRent's digital solutions. From implementing new features to optimizing performance, Kasia ensures our platforms are robust and user-friendly.",
      href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
      icons: [IconYoga, IconMountain, IconMusic, IconDeviceGamepad, IconCode],
    },
    {
      id: 4,
      name: 'Jacob Clarysse',
      role: 'Software engineer ',
      imageUrl: Jacob,
      description: "As a key architect in our tech team, Jacob shapes and refines the backend infrastructure that propels ResiRent's digital solutions. From optimizing data processes to introducing cutting-edge features, Jacob ensures our platforms deliver a seamless experience.",
      href: 'https://www.linkedin.com/in/jacob-clarysse',
      icons: [IconBallTennis, IconBook, IconMountain, IconCode],
    },
  ];

  const teamNL = [
    {
      id: 1,
      name: 'Anja Bulteel',
      role: 'Oprichter en Content Lead',
      imageUrl: Anja,
      description: "Anja draagt bij ResiRent veel verschillende petten. Van het delen van inzichten met het team op basis van haar ervaring als kleine host tot het creëren van boeiende content voor het platform, speelt ze een cruciale rol in het vormgeven van de identiteit van ResiRent. Anja legt ook contacten en onderhoudt samenwerkingen met lokale bedrijven en toerismekantoren om het aanbod voor hosts te verbeteren.",
      href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
      icons: [IconDog, IconToolsKitchen, IconMountain],
    },
    {
      id: 2,
      name: 'Jente Clarysse',
      role: 'Software engineer, Creatief Directeur',
      imageUrl: Jente,
      description: "Jente leidt het creatieve team en houdt toezicht op het ontwerp en de branding van ResiRent. Ze werkt nauw samen met onze tech-experts om een naadloze fusie van technologie en design in onze oplossingen te garanderen.",
      href: 'https://www.linkedin.com/in/jente-clarysse',
      icons: [IconRun, IconPencil, IconDog, IconMountain, IconCode],
    },
    {
      id: 3,
      name: 'Kasia Krasnopolska',
      role: 'Software engineer',
      imageUrl: Kasia,
      description: "Kasia is een sleutelfiguur in ons techteam, verantwoordelijk voor de ontwikkeling en het onderhoud van de softwarearchitectuur die de digitale oplossingen van ResiRent aandrijft.",
      href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
      icons: [IconYoga, IconMountain, IconMusic, IconDeviceGamepad, IconCode],
    },
    {
      id: 4,
      name: 'Jacob Clarysse',
      role: 'Software engineer',
      imageUrl: Jacob,
      description: "Als een belangrijke architect in ons techteam vormt Jacob de backend-infrastructuur die de digitale oplossingen van ResiRent aandrijft.",
      href: 'https://www.linkedin.com/in/jacob-clarysse',
      icons: [IconBallTennis, IconBook, IconMountain, IconCode],
    },
  ];

  const teamDE = [
    {
      id: 1,
      name: 'Anja Bulteel',
      role: 'Gründerin und Leiterin für Inhalte',
      imageUrl: Anja,
      description: "Anja trägt bei ResiRent viele verschiedene Hüte. Vom Teilen von Erkenntnissen mit dem Team aufgrund ihrer Erfahrung als kleine Gastgeberin bis hin zur Erstellung von ansprechenden Inhalten für die Plattform spielt sie eine entscheidende Rolle bei der Gestaltung der Identität von ResiRent. Anja knüpft außerdem Kontakte und pflegt Partnerschaften mit lokalen Unternehmen und Tourismusbüros, um das Angebot für Gastgeber zu verbessern.",
      href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
      icons: [IconDog, IconToolsKitchen, IconMountain],
    },
    {
      id: 2,
      name: 'Jente Clarysse',
      role: 'Softwareentwicklerin, Kreativdirektorin',
      imageUrl: Jente,
      description: "Jente leitet das Kreativteam und überwacht die Design- und Branding-Aspekte von ResiRent. Sie arbeitet eng mit unseren Technikexperten zusammen, um eine nahtlose Fusion von Technologie und Design in unseren Lösungen zu gewährleisten.",
      href: 'https://www.linkedin.com/in/jente-clarysse',
      icons: [IconRun, IconPencil, IconDog, IconMountain, IconCode],
    },
    {
      id: 3,
      name: 'Kasia Krasnopolska',
      role: 'Softwareentwicklerin',
      imageUrl: Kasia,
      description: "Kasia ist eine Schlüsselfigur in unserem Technikteam, verantwortlich für die Entwicklung und Wartung der Softwarearchitektur, die die digitalen Lösungen von ResiRent antreibt.",
      href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
      icons: [IconYoga, IconMountain, IconMusic, IconDeviceGamepad, IconCode],
    },
    {
      id: 4,
      name: 'Jacob Clarysse',
      role: 'Softwareentwickler',
      imageUrl: Jacob,
      description: "Als wichtiger Architekt in unserem Technikteam gestaltet Jacob die Backend-Infrastruktur, die die digitalen Lösungen von ResiRent antreibt.",
      href: 'https://www.linkedin.com/in/jacob-clarysse',
      icons: [IconBallTennis, IconBook, IconMountain, IconCode],
    },
  ];

  const team = window.location.pathname.startsWith('/nl') ? teamNL : window.location.pathname.startsWith('/de') ? teamDE : teamEN;

  const content = {
    '/': {
      'groupTitle': 'Meet our team',
      'description': 'Since our start in 2023 as a startup with four enthusiastic and dedicated team members, our mission has been crystal clear: to provide solutions and expertise to small hosts! With combined efforts stemming from diverse backgrounds, we strive to offer the best technologies and guidance to support small hosts.',
    },
    '/nl': {
      'groupTitle': 'Ontmoet ons team',
      'description': 'Sinds onze start in 2023 als een startup met vier enthousiaste en toegewijde teamleden is onze missie duidelijk: oplossingen en expertise bieden aan kleine hosts! Met gecombineerde inspanningen uit diverse achtergronden streven we ernaar om de beste technologieën en begeleiding te bieden om kleine hosts te ondersteunen.',
    },
    '/de': {
      'groupTitle': 'Unser Team kennenlernen',
      'description': 'Seit unserem Start im Jahr 2023 als Start-up mit vier begeisterten und engagierten Teammitgliedern ist unsere Mission klar: Lösungen und Fachkenntnisse für kleine Gastgeber bereitzustellen! Mit gemeinsamen Anstrengungen aus unterschiedlichen Hintergründen bemühen wir uns, die besten Technologien und Anleitungen bereitzustellen, um kleine Gastgeber zu unterstützen.',
    },
  };

  return (
    <>
      <Container py={100}>
        <Title order={1} align="center" className={classes.title}>
          {content[languagePrefix].groupTitle}
        </Title>
        <Text mt="xl" align="center" style={{ maxWidth: 600, margin: 'auto' }}>
          {content[languagePrefix].description}
        </Text>
        <SimpleGrid
          cols={2}
          breakpoints={[
            { maxWidth: 1000, cols: 1 },
            { maxWidth: 700, cols: 1 },
          ]}
          spacing={20}
          mt={50}
        >
          {team.map((member) => (
            <Card key={member.id} shadow="sm" className={classes.card}>
              <Box position="relative">
                <Avatar align="center" radius="xl" size={200} src={member.imageUrl} alt={member.name} />
                <ActionIcon className={classes.control} href={member.href} variant="outline" color="resirentPink" size="md" position="absolute" right={0} bottom={0} mt="xl">
                  <IconBrandLinkedin />
                </ActionIcon>
              </Box>
              <Title order={3} className={classes.title} mt={2}>
                {member.name}
              </Title>
              <Text size="sm" color="dimmed" mb={4}>
                {member.role}
              </Text>
              <Text size="sm" mb={4}>
                {member.description}
              </Text>
              <Box mt={4}>
                <Group spacing="xs">
                  {member.icons.map((Icon) => (
                    <Icon key={Icon} size={24} />
                  ))}
                </Group>
              </Box>
            </Card>
          ))}
        </SimpleGrid>

      </Container>
    </>
  );
}