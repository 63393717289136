import {
    createStyles,
    Image,
    Center,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    rem,
    Box,
    SimpleGrid,
} from '@mantine/core';
import { Icon24Hours, IconChecklist, IconCoinEuro, IconDiamond, IconGlobe, IconHome, IconHomeEco, IconHomeHeart, IconInfoCircle, IconMedal, IconMicrophone, IconPhone } from '@tabler/icons-react';



const useStyles = createStyles((theme) => ({
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 4)`,
        paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    },
    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirent[8],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirent[8],
        textAlign: 'center', // Center-align the contents
    },

    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
        marginLeft: rem(20),
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        alignItems: 'center',
        width: '80%',
    },

    content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,

        [theme.fn.smallerThan('md')]: {
            maxWidth: '100%',
            marginRight: 0,
        },
    },

    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
        },
        maxWidth: '100%',
        textAlign: 'center',
        marginBottom: rem(20),
        marginTop: rem(20),
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
        },
        maxWidth: '100%',
        textAlign: 'center',
        marginBottom: rem(20),
    },

    highlight: {
        position: 'relative',
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
    },

    container: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
        // adapt to phone
    },
    
}));

// list of motivaitons with an icon from react-icons library and a title and description
const motivationsNL = [
    {
        icon: IconDiamond,
        title: 'Professionele uitstraling',
        description: 'Wek vertrouwen en toon kwaliteit'
    },
    {
        icon: IconChecklist,
        title: 'Volledige controle',
        description: 'Jij bepaalt inhoud, regels en prijzen'
    },
    {
        icon: IconPhone,
        title: 'Directe communicatie',
        description: 'Je kan veel beter inspelen op de wensen van jouw gasten'
    },
    {
        icon: IconCoinEuro,
        title: 'Lagere kosten',
        description: 'Geen commissie per boeking'
    },
    {
        icon: IconMicrophone,
        title: 'Marketingtool',
        description: 'Verwijs via sociale media, mond-op-mond reclame en schrijf bedrijven aan'
    },
    {
        icon: IconInfoCircle,
        title: 'Bron van informatie',
        description: 'Google Maps integratie, suggesties, beschikbaarheid, prijzen, veelgestelde vragen en meer'
    },
];

const motivationsDE = [
    {
        icon: IconDiamond,
        title: 'Professionelles Erscheinungsbild',
        description: 'Vertrauen wecken und Qualität zeigen'
    },
    {
        icon: IconChecklist,
        title: 'Volle Kontrolle',
        description: 'Sie bestimmen Inhalt, Regeln und Preise'
    },
    {
        icon: IconPhone,
        title: 'Direkte Kommunikation',
        description: 'Sie können viel besser auf die Wünsche Ihrer Gäste eingehen'
    },
    {
        icon: IconCoinEuro,
        title: 'Niedrigere Kosten',
        description: 'Keine Provision pro Buchung'
    },
    {
        icon: IconMicrophone,
        title: 'Marketing-Tool',
        description: 'Verweisen Sie über soziale Medien, Mundpropaganda und schreiben Sie Unternehmen an'
    },
    {
        icon: IconInfoCircle,
        title: 'Informationsquelle',
        description: 'Google Maps-Integration, Vorschläge, Verfügbarkeit, Preise, häufig gestellte Fragen und mehr'
    },
];

const motivationsEN = [
    {
        icon: IconDiamond,
        title: 'Professional appearance',
        description: 'Build trust and show quality'
    },
    {
        icon: IconChecklist,
        title: 'Full control',
        description: 'You determine content, rules and prices'
    },
    {
        icon: IconPhone,
        title: 'Direct communication',
        description: 'You can respond much better to the wishes of your guests'
    },
    {
        icon: IconCoinEuro,
        title: 'Lower costs',
        description: 'No commission per booking'
    },
    {
        icon: IconMicrophone,
        title: 'Marketing tool',
        description: 'Refer via social media, word-of-mouth advertising and write to companies'
    },
    {
        icon: IconInfoCircle,
        title: 'Source of information',
        description: 'Google Maps integration, suggestions, availability, prices, frequently asked questions and more'
    },
];


// Hero section with a video to showcase the product

export default function MotivationBanner() {
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const motivations = languagePrefix === '/nl' ? motivationsNL : languagePrefix === '/de' ? motivationsDE : motivationsEN;

    const { classes } = useStyles();
    return (

        <Container size="lg" className={classes.container}>
           <SimpleGrid cols={6} breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 3 }]} spacing={1}>
    {/* map over the motivations array and display the icon, title, and description */}
    {motivations.map((motivation, index) => (
        <Box key={index} className={classes.paperProduct} style={{ display: 'flex', flexDirection: 'column' }}>
            <Center>
                <ThemeIcon radius="xl" color="blue" size="xl" variant="filled" className={classes.control}>
                    <motivation.icon />
                </ThemeIcon>
            </Center>
            <Title order={3} className={classes.title}>
                {motivation.title}
            </Title>
            {/* <Box key={index} className={classes.paperProduct} style={{ display: 'flex', flexDirection: 'column' }}> */}
            <Text className={classes.description}>
                {motivation.description}
            </Text>
        {/* </Box> */}
        </Box>
        
    ))}
    {/* {motivations.map((motivation, index) => (
        <Box key={index} className={classes.paperProduct} style={{ display: 'flex', flexDirection: 'column' }}>
            <Text className={classes.description}>
                {motivation.description}
            </Text>
        </Box>
    ))} */}
</SimpleGrid>

        </Container>


    );
}