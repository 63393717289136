import { createStyles, Text, Title, TextInput, Button, Image, rem } from '@mantine/core';
import image from '../../assets/homepage/newsletter.svg';

const useStyles = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: `calc(${theme.spacing.xl} * 2)`,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column-reverse',
            padding: theme.spacing.xl,
        },
    },

    image: {
        maxWidth: '40%',

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },

    body: {
        paddingRight: `calc(${theme.spacing.xl} * 4)`,

        [theme.fn.smallerThan('sm')]: {
            paddingRight: 0,
            marginTop: theme.spacing.xl,
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        lineHeight: 1,
        marginBottom: theme.spacing.md,
    },

    controls: {
        display: 'flex',
        marginTop: theme.spacing.xl,
    },

    inputWrapper: {
        width: '100%',
        flex: '1',
    },

    input: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 0,
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },
}));


export function Newsletter() {
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const { classes } = useStyles();

    const header = {
        '/': {
            'title': 'Wait a minute...',
            'subtitle': 'Subscribe to our newsletter!',
            'workshop': ' Join the ResiRent community! The best way to receive interesting articles about responsible hosting and updates on new upcoming features! Our newsletters are only twice a month, but you can unsubscribe at any time. It is really easy!',
            'workshopButton': "Sign up",
        },
        '/nl': {
            'title': 'Wacht even...',
            'subtitle': 'Abonneer je op onze nieuwsbrief!',
            'workshop': 'Word lid van de ResiRent-community! De beste manier om interessante artikelen over verantwoord gastheerschap en updates over nieuwe aankomende functies te ontvangen! Onze nieuwsbrieven verschijnen slechts twee keer per maand, maar je kunt je op elk moment uitschrijven. Het is echt eenvoudig!',
            'workshopButton': "Aanmelden",
        },
        '/de': {
            'title': 'Förderung verantwortungsvoller Vermietungspraktiken',
            'subtitle': 'Bewusstes Hosting für blühenden Tourismus',
            'workshop': 'Melden Sie sich für unseren Newsletter an',
            'workshopButton': "Anmelden",
        },
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.body}>
                <Title className={classes.title}>  {header[languagePrefix].title}</Title>
                <Text fw={500} fz="lg" mb={5}>
                    {header[languagePrefix].subtitle}
                </Text>
                <Text fz="sm" c="dimmed">
                    {header[languagePrefix].workshop}
                </Text>

                <div className={classes.controls}>
                    <Button align="center" radius="xl" component="a" mt="md" size="md" className={classes.control} href="http://eepurl.com/isztuc" >
                        {header[languagePrefix].workshopButton}
                    </Button>
                </div>
            </div>
        </div>
    );
}