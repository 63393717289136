import resiweb from '../../assets/homepage/resiweb.jpg'
import resiAccessoires from '../../assets/homepage/resiaccessoires.png'
import resiCal from '../../assets/homepage/Calendar.png'
import blog from '../../assets/homepage/Blog.png'
import image from '../../assets/consulting/consulting.jpeg'
import {
    createStyles,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    Image,
    AspectRatio,
    Button,
} from '@mantine/core';

const mockdata = [
    {
        title: {
            '/': 'Website',
            '/nl': 'Website',
            '/de': 'Website',
        },
        description: {
            '/': 'Develop your own identity and express it on your own website',
            '/nl': 'Ontwikkel je eigen identiteit en uit het op je eigen website',
            '/de': 'Entwickeln Sie Ihre eigene Identität und drücken Sie sie auf Ihrer eigenen Website aus',
        },
        source: resiweb,
        href: {
            '/': '/products/resirent-website',
            '/nl': '/nl/producten/resirent-website',
            '/de': '/de/resiwebsite',
        },
        href2: {
            '/': '/contact',
            '/nl': '/nl/contact',
            '/de': '/de/kontakt',
        },
        buttonText2: {
            '/': 'Contact us',
            '/nl': 'Contacteer ons',
            '/de': 'Kontaktieren Sie uns',
        },
        buttonText: {
            '/': 'Explore ResiWeb',
            '/nl': 'Ontdek ResiWeb',
            '/de': 'Entdecken Sie ResiWeb',
        },
        subtitle: {
            '/': 'WEBSITE',
            '/nl': 'WEBSITE',
            '/de': 'WEBSITE',
        },
    },
    {
        title: {
            '/': 'Calendar',
            '/nl': 'Calendar',
            '/de': 'Calendar',
        },
        description: {
            '/': 'Add a calendar plug-in to your website to enable direct bookings',
            '/nl': 'Voeg een kalender plug-in toe aan je website om directe boekingen mogelijk te maken',
            '/de': 'Fügen Sie ein Kalender-Plug-In auf Ihrer Website hinzu, um direkte Buchungen zu ermöglichen',
        },
        source: resiCal,
        href: {
            '/': '/products/resirent-calendar',
            '/nl': "/nl/producten/resirent-calendar",
            '/de': './de/resiwebsite',
        },
        href2: {
            '/': '/contact',
            '/nl': '/nl/contact',
            '/de': '/de/kontakt',
        },
        buttonText2: {
            '/': 'Contact us',
            '/nl': 'Contacteer ons',
            '/de': 'Kontaktieren Sie uns',
        },
        buttonText: {
            '/': 'Explore ResiCalendar',
            '/nl': 'Ontdek ResiCalendar',
            '/de': 'Entdecken Sie ResiCalendar',
        },
        subtitle: {
            '/': 'CALENDAR',
            '/nl': 'KALENDER',
            '/de': 'KALENDER',
        },

    },
    // {
    //     title: {
    //         '/': 'Stickers and other accessories',
    //         '/nl': 'Stickers en andere accessoires',
    //         '/de': 'Stickers und andere Accessoires',
    //     },
    //     description: {
    //         '/': 'Hosting can be an overwhelming responsibility! Discover our monthly blog posts and accessories designed to make your life as a host easier',
    //         '/nl': 'Hosting kan een overweldigende verantwoordelijkheid zijn! Ontdek onze maandelijkse blogberichten en accessoires die zijn ontworpen om uw leven als gastheer gemakkelijker te maken',
    //         '/de': 'Hosting kann eine überwältigende Verantwortung sein! Entdecken Sie unsere monatlichen Blog-Beiträge und Accessoires, die dazu dienen, Ihr Leben als Gastgeber einfacher zu machen',
    //     },
    //     href: {
    //         '/': '/coming-soon',
    //         '/nl': '/coming-soon',
    //         '/de': '/coming-soon',
    //     },
    //     buttonText: {
    //         '/': 'Explore the accessories',
    //         '/nl': 'Ontdek de accessoires',
    //         '/de': 'Entdecken Sie die Accessoires',
    //     },
    //     source: resiAccessoires,
    //     subtitle: {
    //         '/': 'STICKERS',
    //         '/nl': 'STICKERS',
    //         '/de': 'STICKERS',
    //     },
    // },
    // {
    //     title: {
    //         '/': 'BLOG',
    //         '/nl': 'BLOG',
    //         '/de': 'BLOG',
    //     },
    //     description: {
    //         '/': 'Hosting can be an overwhelming responsibility! Discover our monthly blog posts and accessories designed to make your life as a host easier',
    //         '/nl': 'Hosting kan een overweldigende verantwoordelijkheid zijn! Ontdek onze maandelijkse blogberichten en accessoires die zijn ontworpen om uw leven als gastheer gemakkelijker te maken',
    //         '/de': 'Hosting kann eine überwältigende Verantwortung sein! Entdecken Sie unsere monatlichen Blog-Beiträge und Accessoires, die dazu dienen, Ihr Leben als Gastgeber einfacher zu machen',
    //     },
    //     href: {
    //         '/': '/coming-soon',
    //         '/nl': '/coming-soon',
    //         '/de': '/coming-soon',
    //     },
    //     buttonText: {
    //         '/': 'Explore the accessories',
    //         '/nl': 'Ontdek de accessoires',
    //         '/de': 'Entdecken Sie die Accessoires',
    //     },
    //     source: blog,
    //     subtitle: {
    //         '/': 'BLOG',
    //         '/nl': 'BLOG',
    //         '/de': 'BLOG',
    //     },
    // },
];

const mockdataOther = [
    {
        title: {
            '/': 'Get advice',
            '/nl': 'Krijg advies',
            '/de': 'Erhalte Beratung',
        },
        description: {
            '/': 'Get in touch with us for consultation on your hosting needs',
            '/nl': 'Neem contact met ons op voor advies over uw hostingbehoeften',
            '/de': 'Kontaktieren Sie uns für eine Beratung zu Ihren Hosting-Bedürfnissen',
        },
        source: image,
        href: {
            '/': '/products/consulting',
            '/nl': '/nl/producten/advies',
            '/de': '/de/produkte/beratung',
        },
        buttonText: {
            '/': 'Explore our consulting services',
            '/nl': 'Ontdek onze adviesdiensten',
            '/de': 'Entdecken Sie unsere Beratungsdienstleistungen',
        },
        subtitle: {
            '/': 'WEBSITE',
            '/nl': 'WEBSITE',
            '/de': 'WEBSITE',
        },
    },
];
// Assuming you have a variable called 'languagePrefix' that determines the selected language
// You can use it to access the appropriate translations like this:


const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
    },

    titleCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    descriptionCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
        fontWeight: 400,
    },

    card: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: "#FFF5F9",
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

}));

const content = {
    '/': {
        'groupTitle': 'EMPOWERING SMALL HOSTS',
        'mainTitle': 'Streamline Your Accommodation Experience: Discover All-in-One Solutions for Your Hosting Needs!',
        'description': 'At ResiRent, we help you to short let your accommodation sustainably and optimize your revenues. Our approach is rooted in the idea of being professional, independent and sustainable. Hop on board and get your short-term rental ready for the 21st century.',
    },
    '/nl': {
        'groupTitle': 'Voor de verhuurder van vakantiewoningen',
        'mainTitle': 'Optimaliseer uw vakantieverhuur met onze tools: website, kalender, advies en stickers',
        'description': 'Bij ResiRent helpen we u om uw accommodatie duurzaam te verhuren en uw inkomsten te optimaliseren. Onze aanpak is gebaseerd op het idee van professionaliteit, onafhankelijkheid en duurzaamheid. Stap aan boord en maak uw kortetermijnverhuur klaar voor de 21e eeuw.',
    },
    '/de': {
        'groupTitle': 'KLEINE GASTGEBER STÄRKEN',
        'mainTitle': 'Optimieren Sie Ihr Unterkunftserlebnis: Entdecken Sie All-in-One-Lösungen für Ihre Hosting-Bedürfnisse!',
        'description': 'Bei ResiRent helfen wir Ihnen, Ihre Unterkunft nachhaltig zu vermieten und Ihre Einnahmen zu maximieren. Unser Ansatz basiert auf den Prinzipien von Professionalität, Unabhängigkeit und Nachhaltigkeit. Steigen Sie ein und machen Sie Ihre Kurzzeitvermietung fit für das 21. Jahrhundert.',
    },
};

export function Featuring() {
    const { classes } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    const features = mockdata.map((feature) => (
        <Card key={feature.title[languagePrefix]} p="md" radius="md" className={classes.card}>
            <AspectRatio ratio={1920 / 1080}>
                <Image
                    radius="md"
                    src={feature.source}
                    alt={feature.title[languagePrefix]} />
            </AspectRatio>
            {/* <Title order={4} weight={300} color="resirentPink">
                {feature.subtitle[languagePrefix]}
            </Title> */}
            <Title order={2} className={classes.titleCard} my="sm" display={'block'}>
                {feature.title[languagePrefix]}
            </Title>
            <Text fz="sm" mt="sm" className={classes.descriptionCard}>
                {feature.description[languagePrefix]}
            </Text>
            <Button align="center" mb="md" radius="xl" component="a" href={feature.href[languagePrefix]} mt="md" size="md" className={classes.control}>
                {feature.buttonText[languagePrefix]}
            </Button>
            <Button align="center" mb="md" radius="xl" component="a" href={feature.href2[languagePrefix]} mt="md" size="md" className={classes.controlSecond}>
                {feature.buttonText2[languagePrefix]}
            </Button>
        </Card>
    ));

    const featuresNew = mockdataOther.map((featureNew) => (
        <Card key={featureNew.title[languagePrefix]} p="md" radius="md" className={classes.card}>
            <AspectRatio ratio={1920 / 1080}>
                <Image
                    radius="md"
                    src={featureNew.source}
                    alt={featureNew.title[languagePrefix]} />
            </AspectRatio>
            {/* <Title order={4} weight={300} color="resirentPink">
                {feature.subtitle[languagePrefix]}
            </Title> */}
            <Title order={2} className={classes.titleCard} my="sm" display={'block'}>
                {featureNew.title[languagePrefix]}
            </Title>
            <Text fz="sm" mt="sm" className={classes.descriptionCard}>
                {featureNew.description[languagePrefix]}
            </Text>
            <Button align="center" mb="md" radius="xl" component="a" href={featureNew.href[languagePrefix]} mt="md" size="md" className={classes.control}>
                {featureNew.buttonText[languagePrefix]}
            </Button>
        </Card>
    ));


    return (
        <Container size="lg" pt={100}>
            <Group position="center">
                <Title order={4} weight={300} align="center" color="resirentPink">
                    {content[languagePrefix].groupTitle}
                </Title>
            </Group>
            <Group position="center">
                <Title order={2} className={classes.title} my="sm" display={'block'} ta="center">
                    {content[languagePrefix].mainTitle}
                </Title>
            </Group>
            <Text className={classes.description} ta="center" my="lg">
                {content[languagePrefix].description}
            </Text>

            <SimpleGrid pt="md" cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                {features}
            </SimpleGrid>
            <SimpleGrid pt="md" cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                {featuresNew}
            </SimpleGrid>
        </Container>
    );
}