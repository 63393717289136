import React from 'react'
import image from '../../../assets/products/main.png';
import { createStyles, Group, rem, Button, Title, Box, Container, Text, Image, Paper, SimpleGrid } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        alignItems: 'center',
        width: '100%',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function MainProduct({ language }) {

    const { classes } = useStyles();

    const content = {
        '/': {
            'groupTitle': 'EASY-TO-USE WEBSITE BUILDER',
            'mainTitle': 'Develop your own identity in the marketplace',
            'description': 'As convenient as standard booking platforms that do not require more skills than you need to be listed on a standard booking platform...',
            'description2': '..., but this is your own website without any transactional commission fees to be paid.',
            'description3': ' Edit paragraphs, insert images, add reviews, and links to make your content shine.',
        },
        '/nl': {
            'groupTitle': 'GEBRUIKSVRIENDELIJKE WEBSITEBOUWER',
            'mainTitle': 'Ontwikkel je eigen identiteit op de markt',
            'description': 'Net zo gebruiksvriendelijk als boekingsplatforms',
            'description2': '',
            'description3': "",
        },
        '/de': {
            'groupTitle': 'BENUTZERFREUNDLICHER WEBSITE-ERSTELLER',
            'mainTitle': 'Entwickeln Sie Ihre eigene Identität auf dem Markt',
            'description': 'So bequem wie herkömmliche Buchungsplattformen, die nicht mehr Fähigkeiten erfordern, als Sie benötigen, um auf einer herkömmlichen Buchungsplattform aufgeführt zu sein...',
            'description2': '..., aber dies ist Ihre eigene Website ohne anfallende Transaktionsprovisionen.',
            'description3': ' Bearbeiten Sie Absätze, fügen Sie Bilder ein, fügen Sie Bewertungen und Links hinzu, um Ihren Inhalt zum Strahlen zu bringen.',
        },
    };
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box>
            <Container size="lg" py={100}>
                <SimpleGrid cols={2} breakpoints={[{ maxWidth: 768, cols: 1 }, { maxWidth: 1200, cols: 2 }]} spacing={20}>
                    <Box>
                        <Title order={4} weight={300} className={classes.title} color="resirentPink">
                            {content[languagePrefix].groupTitle}
                        </Title>

                        <Title order={2} className={classes.title} my="sm" display={'block'}>
                            {content[languagePrefix].mainTitle}
                        </Title>

                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].description}
                        </Text>

                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].description2}
                        </Text>

                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].description3}
                        </Text>
                    </Box>

                    <Paper shadow="xl" p="md" radius="xl" withBorder className={classes.paperProduct} >
                        <Image src={image} alt="Website Editing" />
                    </Paper>

                </SimpleGrid>
            </Container>

        </Box >
    )
}
