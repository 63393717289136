import React, { useState } from 'react';
import { Container, Paper, Text, Box, Divider, Collapse, Button, Group, Modal } from '@mantine/core';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  booked: {
    backgroundColor: theme.colors.red[1],
    borderColor: theme.colors.red[4],
  },
  closed: {
    backgroundColor: theme.colors.gray[1],
    borderColor: theme.colors.gray[4],
  },
  inProcess: {
    backgroundColor: theme.colors.green[1],
    borderColor: theme.colors.green[4],
  },
  title: {
    fontSize: theme.fontSizes.lg,
    marginBottom: theme.spacing.md,
  },
  bookingContainer: {
    marginBottom: theme.spacing.md,
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    border: `1px solid ${theme.colors.gray[4]}`,
  },
  bookingHeader: {
    marginBottom: theme.spacing.xs,
    cursor: 'pointer',
  },
  bookingDetails: {
    marginTop: theme.spacing.sm,
  },
  sectionTitle: {
    fontSize: theme.fontSizes.md,
    fontWeight: 500,
    marginBottom: theme.spacing.xs,
  },
  sectionContent: {
    fontSize: theme.fontSizes.sm,
  },
}));

const BookingList = ({ dateStates, handleAccept, handleDecline, handleStop }) => {
  const { classes } = useStyles();
  const [openedBookings, setOpenedBookings] = useState({});
  const [confirmModal, setConfirmModal] = useState({ isOpen: false, type: '', key: '' });
  const currentDate = new Date().toISOString().split('T')[0];

  const toggleBookingDetails = (key) => {
    setOpenedBookings((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const openConfirmModal = (type, key) => {
    setConfirmModal({ isOpen: true, type, key });
  };

  const closeConfirmModal = () => {
    setConfirmModal({ isOpen: false, type: '', key: '' });
  };

  const handleConfirmAction = () => {
    const { type, key } = confirmModal;
    if (type === 'accept') {
      handleAccept(key);
    } else if (type === 'decline') {
      handleDecline(key);
    } else if (type === 'stop') {
      handleStop(key);
    }
    closeConfirmModal();
  };

  const renderBookingItem = (key, booking) => {
    const isOpened = openedBookings[key];
    let boxClass;

    switch (booking.booked) {
      case 'booked':
        boxClass = classes.booked;
        break;
      case 'closed':
        boxClass = classes.closed;
        break;
      case 'inProcess':
        boxClass = classes.inProcess;
        break;
      default:
        boxClass = '';
        break;
    }

    return (
      <Paper
        key={`${booking.start}-${booking.end}`}
        className={`${classes.bookingContainer} ${boxClass}`}
      >
        <Group position="apart" className={classes.bookingHeader} onClick={() => toggleBookingDetails(key)}>
          <Text color="resirent" weight={600} size="md">
            {booking.booked === 'inProcess' ? (
              <>Request: {booking.label} ({booking.start} - {booking.end})</>
            ) : (
              <>{booking.booked}: {booking.label} ({booking.start} - {booking.end})</>
            )}
          </Text>
          <Button variant="subtle" color="resirent" compact>
            {isOpened ? 'Hide Details' : 'Show Details'}
          </Button>
        </Group>
        <Collapse in={isOpened} className={classes.bookingDetails}>
          <Divider my="sm" />
          <Text className={classes.sectionTitle}>Details:</Text>
          <Box className={classes.sectionContent}>
            <Text><strong>Status:</strong> {booking.booked}</Text>
            <Text><strong>Check-in:</strong> {booking.start}</Text>
            <Text><strong>Check-out:</strong> {booking.end}</Text>
            <Text><strong>Number of Guests:</strong> {booking.formDate?.numberOfGuests ?? 'N/A'}</Text>
            {booking.formDate?.numberOfDogs !== undefined && (
              <Text><strong>Number of Dogs:</strong> {booking.formDate.numberOfDogs}</Text>
            )}
            {booking.formDate?.underCityTaxAge !== undefined && (
              <Text><strong>Under City Tax Age:</strong> {booking.formDate.underCityTaxAge}</Text>
            )}
            {booking.formDate?.breakfast !== undefined && (
              <Text><strong>Breakfast:</strong> {booking.formDate.breakfast ? 'Yes' : 'No'}</Text>
            )}
            {booking.formDate?.email && (
              <Text><strong>Email:</strong> {booking.formDate.email}</Text>
            )}
            {booking.formDate?.message && (
              <Text><strong>Message:</strong> {booking.formDate.message}</Text>
            )}
          </Box>
          <Group mt="md">
            <Button color="green" onClick={() => openConfirmModal('accept', key)}>Accept</Button>
            <Button color="red" onClick={() => openConfirmModal('decline', key)}>Decline</Button>
          </Group>
        </Collapse>
      </Paper>
    );
  };

  const futureBookings = Object.entries(dateStates)
    .filter(([key, booking]) => booking.end >= currentDate)
    .sort((a, b) => new Date(a[1].start) - new Date(b[1].start));

  const pastBookings = Object.entries(dateStates)
    .filter(([key, booking]) => booking.end < currentDate)
    .sort((a, b) => new Date(b[1].start) - new Date(a[1].start));

  return (
    <Container size="xl" mt="xl">
      <Text size="xl" weight={700} align="center" mb="xl" className={classes.title}>
        Current and Future Bookings
      </Text>
      {futureBookings.map(([key, booking]) => renderBookingItem(key, booking))}
      <Divider my="xl" />
      <Text size="xl" weight={700} align="center" mb="xl" className={classes.title}>
        Past Bookings
      </Text>
      {pastBookings.map(([key, booking]) => renderBookingItem(key, booking))}

      <Modal
        opened={confirmModal.isOpen}
        onClose={closeConfirmModal}
        title={`Confirm ${confirmModal.type.charAt(0).toUpperCase() + confirmModal.type.slice(1)} Booking`}
      >
        <Text>Are you sure you want to {confirmModal.type} this booking?</Text>
        <Group position="center" mt="md">
          <Button color="red" onClick={handleConfirmAction}>Yes</Button>
          <Button variant="default" onClick={closeConfirmModal}>No</Button>
        </Group>
      </Modal>
    </Container>
  );
};

export default BookingList;
