import React from 'react'
import image from '../../../assets/products/resiWebsite.png';
import { createStyles, Group, rem, Button, Title, Box, Container, Text, Image, Paper, SimpleGrid } from '@mantine/core';
import { Icon24Hours, IconDiamond, IconMedal, IconPhone } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        width: '80%',
        marginTop: rem(60),
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function ProductPage({ language }) {

    const { classes, theme } = useStyles();
    const { t } = useTranslation();

    const content = {
        '/': {
            'mainTitle': "Explore additional features",
            'groupTitle': 'Why ResiWebsite?',
            'description': "Show your hidden gems.",
            'text': 'Add thoughtfull suggestions regarding food, activities, museum at much more to your website based on your experience as a host.',
            'description2': 'Highlight your booking reviews on other booking platforms.',
            'text2': "Show your guests that you're a trusted host by highlighting your reviews from other booking platforms.",
            'description3': 'Mobile friendly.',
            'text3': "Your website will be mobile friendly and will look great on all devices.",
        },
        '/nl': {
            'mainTitle': "Verken aanvullende functies",
            'groupTitle': 'Waarom ResiWebsite?',
            'description': "Laat je verborgen pareltjes zien.",
            'text': 'Verrijk je website met je persoonlijke aanbevelingen over eten, activiteiten, musea en meer. Zo draag je actief bij aan de lokale gemeenschap.',
            'description2': 'Benadruk je boekingsbeoordelingen op andere boekingsplatforms.',
            'text2': "Laat je gasten zien dat je een betrouwbare gastheer bent door je beoordelingen van andere boekingsplatforms te benadrukken.",
            'description3': 'Mobielvriendelijk.',
            'text3': "Je website is mobielvriendelijk en ziet er geweldig uit op alle apparaten.",
        },
        '/de': {
            'mainTitle': "Entdecken Sie zusätzliche Funktionen",
            'groupTitle': 'Warum ResiWebsite?',
            'description': "Zeige deine versteckten Schätze.",
            'text': 'Fügen Sie Ihrer Website aufgrund Ihrer Erfahrung als Gastgeber durchdachte Vorschläge zu Essen, Aktivitäten, Museen und vielem mehr hinzu.',
            'description2': 'Heben Sie Ihre Buchungsbewertungen auf anderen Buchungsplattformen hervor.',
            'text2': "Zeigen Sie Ihren Gästen, dass Sie ein vertrauenswürdiger Gastgeber sind, indem Sie Ihre Bewertungen von anderen Buchungsplattformen hervorheben.",
            'description3': 'Mobilfreundlich.',
            'text3': "Ihre Website ist mobilfreundlich und sieht auf allen Geräten großartig aus.",
        },
    };

    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box>
            <Container size="lg" py={100} align="center">

                <Title order={2} className={classes.title} ta="center" mt="sm">
                    {content[languagePrefix].mainTitle}
                </Title>
                <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 3 }, { maxWidth: 'xl', cols: 3 }]} spacing="md" mt="md">

                    <Paper radius="ld" shadow='md' p="md">
                        <IconDiamond size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                        <Title order={4} ta="center" mt="sm">
                            {content[languagePrefix].description}
                        </Title>
                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].text}
                        </Text>
                    </Paper>
                    <Paper shadow='md' p="md">
                        <IconMedal size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                        <Title order={4} ta="center" mt="sm">
                            {content[languagePrefix].description2}
                        </Title>
                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].text2}
                        </Text>
                    </Paper>
                    <Paper shadow='md' p="md">
                        <IconPhone size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                        <Title order={4} ta="center" mt="sm">
                            {content[languagePrefix].description3}
                        </Title>
                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].text3}
                        </Text>
                    </Paper>
                </SimpleGrid>

            </Container>
            {/* <Box align="right">
                        <Text component='a' href={imageButton[currentImage].link} order={1} weight={400} color="dimmed" mt="md">
                            {imageButton[currentImage].name}
                        </Text>
                    </Box> */}
        </Box >
    )
}
