import blogPost from '../../assets/blogPosts/blogPage/blog_post.jpg'
import {
    createStyles,
    Group,
    Title,
    Text,
    Card,
    Container,
    rem,
    Image,
    Box,
} from '@mantine/core';
import { postsDE, postsEn, postsNL } from '../../languages/blogPages';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',
        fontWeight: 200,
    },

    card: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
    },
}));

export default function Blog() {
    const { classes } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '';
    const posts = languagePrefix === '/nl' ? postsNL : languagePrefix === '/de' ? postsDE : postsEn;
    const { t } = useTranslation();

    return (
        <Box>
            <Image
                src={blogPost}
                alt="Landscape"
                height={400}
            ></Image>
            <Container size="lg" py={100}>
                <Group position="center">
                    <Title order={4} weight={100} align="center" color="resirentPink">
                        <h1>{t("blog")}</h1>
                    </Title>
                </Group>

                <Title order={2} className={classes.title} ta="center" mt="sm">
                    {languagePrefix === '/nl' ? "Al onze laatste artikelen" : languagePrefix === '/de' ? "Unsere neuesten Blogbeiträge" : "All the latest articles"}
                </Title>

                <Container align="center" pt="md">
                    {[...posts].reverse().map((post) => (
                        <Card key={post.title} m="md" p="md" radius="md" component="a" href={`${languagePrefix}/blog/${post.id}`} className={classes.card}>
                            <Title order={4} weight={200} align="center" color="resirent" mb="md">
                                {post.date}
                            </Title>
                            <Title order={3} weight={100} align="center">
                                {post.title}
                            </Title>
                            <Text c="dimmed" className={classes.description} ta="center" mt="md">
                                {post.description}
                            </Text>
                        </Card>
                    ))}
                </Container>

            </Container>
        </Box >
    );
}
