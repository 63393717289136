// Skeleton of the ResiRent website
import { Outlet } from 'react-router-dom';
import React from 'react';
import Footer from './components/footer/Footer';
import FullNavBar from './components/navBar/FullNavBar';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const Layout = () => {

    const { t } = useTranslation();

    return (
        <main>
            <FullNavBar />
      
            <CookieConsent
  cookieName="myAwesomeCookieName2"
  location='bottom'
  style={{ background: "rgba(128, 128, 128, 0.8)", fontSize: "18px", fontFamily: "Avenir", padding: "20px"}}
  buttonStyle={{ color: "#FFFFFF", fontSize: "20px", background: "#003D53", borderRadius: "5px", padding: "15px 25px", textDecoration: "none", marginLeft: "15px" }}
  buttonText={t('cookies.button')}
  expires={1}
>
        {t('cookies.text')}
</CookieConsent>

            <Outlet /> 
            <Footer />
        </main>
    );
}

export default Layout;