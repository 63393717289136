import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {

    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        console.log("Refresh Token")
        console.log(response)
        setAuth(prev => {
            return {
                ...prev,
                username: response.data.username,
                role: response.data.role,
                accessToken: response.data.accessToken,
                resiWeb: response.data.resiWeb,
                resiCal: response.data.resiCal
            }
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken;