import React from 'react'
import image from '../../../assets/products/resiWebsite.png';
import { createStyles, Group, rem, Button, Title, Box, Container, Text, Image, Paper } from '@mantine/core';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        width: '80%',
        marginTop: rem(60),
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function ResiWebsiteHeader({ language }) {

    const { classes } = useStyles();
    const { t } = useTranslation();

    const content = {
        '/': {
            'groupTitle': 'RESIWEBSITE',
            'mainTitle': 'Create a captivating, high-quality website with ease.',
            'description': 'Having a high-quality website that resonates with your audience is now more seamless than ever. With ResiWebsite, even as a small host, you will not have to compromise on quality. An intuitively designed website template provides your website with a professional edge right from the start. The template editor is tailored for small hosts, by small hosts, ensuring ease, quality, and authenticity.',
        },
        '/nl': {
            'groupTitle': 'RESIWEBSITE',
            'mainTitle': 'Maak een attractieve website op een eenvoudige manier.',
            'description': 'Een aantrekkelijke website is nu eenvoudiger dan ooit. Met ResiWebsite doe je dit met de glimlach. Een intuïtief ontworpen websitetemplate helpt je hierbi. Deze template is ontworpen voor niet-programmeurs en staat garant voor gemak, kwaliteit en authenticiteit.',
        },
        '/de': {
            'groupTitle': 'RESIWEBSEITE',
            'mainTitle': 'Erstellen Sie ganz einfach eine fesselnde, hochwertige Webseite.',
            'description': 'Eine hochwertige Webseite zu haben, die auf Ihr Publikum abgestimmt ist, war noch nie so einfach. Mit ResiWebsite müssen Sie auch als kleiner Gastgeber keine Abstriche bei der Qualität machen. Eine intuitiv gestaltete Webseitenvorlage verleiht Ihrer Webseite von Anfang an einen professionellen Touch. Der Vorlagen-Editor ist speziell auf kleine Gastgeber zugeschnitten und steht für Benutzerfreundlichkeit, Qualität und Authentizität.',
        },
    };

    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box>
            <Container size="lg" py={100} align="center">
                <Group position="center">
                    <Title order={4} weight={300} align="center" color="resirentPink">
                        {content[languagePrefix].groupTitle}
                    </Title>
                </Group>

                <Title order={2} className={classes.title} ta="center" mt="sm">
                    {content[languagePrefix].mainTitle}
                </Title>

                <Text className={classes.description} ta="center" my="lg">
                    {content[languagePrefix].description}
                </Text>
                <Button align="center" mb="md" color="resirentPink" radius="xl" component="a" href={t("resiweb.href")} mt="md" size="md" className={classes.control}>
                    {t("resiweb.title")}
                </Button>

                <Paper shadow="xl" radius="xl" withBorder className={classes.paperProduct}>
                    <Image radius="lg" src={image} alt="ResiRent" className={classes.imageProduct} />
                </Paper>
            </Container>
            {/* <Box align="right">
                        <Text component='a' href={imageButton[currentImage].link} order={1} weight={400} color="dimmed" mt="md">
                            {imageButton[currentImage].name}
                        </Text>
                    </Box> */}
        </Box >
    )
}
