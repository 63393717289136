import { createStyles, SimpleGrid, Card, Image, Text, Container, AspectRatio, Title, rem } from '@mantine/core';
import { postsDE, postsEn, postsNL } from '../../languages/blogPages';

const useStyles = createStyles((theme) => ({
    card: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
    },

    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '100%',
    },

}));

export function BlogHomePage() {
    const { classes } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '';
    const posts = languagePrefix === '/nl' ? postsNL : languagePrefix === '/de' ? postsDE : postsEn;

    const cards = posts.map((article) => (
        <Card key={article.title} p="md" radius="md" component="a" href={`${languagePrefix}/blog/${article.id}`} className={classes.card}>
            <AspectRatio ratio={1920 / 1080}>
                <Image src={article.image} />
            </AspectRatio>
            <Text size="xs" transform="uppercase" mt="md" weight={300} color="resirentPink">
                {article.date}
            </Text>
            <Text className={classes.title} mt={5}>
                {article.title}
            </Text>
        </Card>
    ));

    return (
        <Container py={100} >
            <Title order={2} className={classes.title} my="sm" ta="center">
                {languagePrefix === '/nl' ? "Op onze blog " : languagePrefix === '/de' ? "Auf unserem Blog" : "From our blog"}
            </Title>
            <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                {cards}
            </SimpleGrid>
        </Container >
    );
}