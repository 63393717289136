/* This is a blogpost layout to try */
import { List, Anchor, Image } from '@mantine/core';
import { vatEN, vatNL } from './languages/vat';

function TableOfContents({ sections }) {
    return (
        <List spacing="xs">
            {sections.map((section, index) => (
                <List.Item key={index}>
                    <Anchor color="black" href={`#${section.id}`} size="md">
                        {section.title}
                    </Anchor>
                </List.Item>
            ))}
        </List>
    );
}

function Section({ id, title, content, imageSrc, altText }) {
    return (
        <div id={id}>
            <h2>{title}</h2>
            <p>{content}</p>
            <Image maw={360} mx="auto" radius="md" src={imageSrc} alt={altText} />
        </div>
    );
}

export default function VAT() {

    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '';
    const sections = languagePrefix === '/nl' ? vatNL : languagePrefix === '/de' ? vatEN : vatEN;

    return (
        <div>
            {/* Table of Contents */}
            <TableOfContents sections={sections} />

            <div>
                {sections.map((section) => (
                    <Section
                        key={section.id}
                        id={section.id}
                        title={section.title}
                        content={section.content}
                        imageSrc={section.imageSrc}
                        altText={section.altText}
                    />
                ))}
            </div>

        </div>
    )
}