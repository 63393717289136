import React, { useEffect, useState } from 'react';
import { Container, Paper, Text, Button, Box, SimpleGrid, Select, rem, Textarea } from '@mantine/core';
import { createStyles } from '@mantine/core';
import useAuth from '../../../hooks/useAuth';
import axios from '../../../api/axios';
import MonthViewHost from './CalendarMonthViewHost';
import ConfirmModal from './ConfirmModal';
import BookingInfoModal from './BookingInfoModal';
import BookingList from './BookingList';
import { useTranslation } from "react-i18next";
import { t } from 'i18next';
import CalendarOverview from '../Calendar-overview/CalendarOverview';

const CalendarBookedView = (inputForm) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const { classes } = useStyles();
    const [selectedRange, setSelectedRange] = useState({ start: null, end: null });
    const [dateStates, setDateStates] = useState('');
    const { auth } = useAuth(); // get username. Right now hard-coded
    const username = auth?.resiWeb;
    const WEBSITE_URL = '/websitejson';
    const [saved, setSaved] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [bookingInfoModalOpen, setBookingInfoModalOpen] = useState(false);
    const [currentRangeKey, setCurrentRangeKey] = useState(null);
    const [currentBookingInfo, setCurrentBookingInfo] = useState(null);

    const art = "en";
    const title = "calendar-bookings";

    const [booked, setBooked] = useState('');
    const [label, setLabel] = useState('');

    const bookingOptions = [
        { value: 'booked', label: 'Booked', labelText: '' },
        { value: 'inProcess', label: 'Request', labelText: '' },
        { value: 'closed', label: 'Closed', labelText: '' },
    ];

    // Fetch the DateStates from the database
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art, title },
                });
                setDateStates(JSON.parse(response.data.entry.content));
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [username, art, title]);

    const handleCategorySaveClick = (selectedRange, booked, label) => {

        // Save the selected range to the database
        const newDateStates = { ...dateStates };

        // Get the start and end date of the selected range
        const startDate = new Date(selectedRange.start);
        const endDate = new Date(selectedRange.end);

        // Get the start and end key of the selected range
        const startKey = startDate.toISOString().slice(0, 10);
        const endKey = endDate.toISOString().slice(0, 10);

        // Function: Check if the selected range overlaps with any existing range
        // const rangesOverlap = (range1, range2) => {
        //     return (
        //         new Date(range1.start) <= new Date(range2.end) &&
        //         new Date(range2.start) <= new Date(range1.end)
        //     );
        // };

        // Function: Check if the selected range overlaps with any existing range
        // const overlappingRanges = Object.entries(newDateStates).filter(([key, range]) => {
        //     return rangesOverlap(range, { start: startKey, end: endKey });
        // });

        // Adding the new range to the dateStates
        const newRangeKey = `${startKey}-${endKey}`;
        newDateStates[newRangeKey] = {
            start: startKey,
            end: endKey,
            booked,
            label
        };

        // overlappingRanges.forEach(([key, range]) => {
        //     if (range.booked !== 'booked') {
        //         if (new Date(range.start) < new Date(startKey)) {
        //             const beforeRangeKey = `${range.start}-${new Date(new Date(startKey).setDate(startDate.getDate())).toISOString().slice(0, 10)}`;
        //             newDateStates[beforeRangeKey] = {
        //                 start: range.start,
        //                 end: new Date(new Date(startKey).setDate(startDate.getDate() - 1)).toISOString().slice(0, 10),
        //                 booked: range.booked,
        //                 label: range.label
        //             };
        //         }
        //         if (new Date(range.end) > new Date(endKey)) {
        //             const afterRangeKey = `${new Date(new Date(endKey).setDate(endDate.getDate() + 1)).toISOString().slice(0, 10)}-${range.end}`;
        //             newDateStates[afterRangeKey] = {
        //                 start: new Date(new Date(endKey).setDate(endDate.getDate() + 1)).toISOString().slice(0, 10),
        //                 end: range.end,
        //                 booked: range.booked,
        //                 label: range.label
        //             };
        //         }
        //     }
        // });

        // overlappingRanges.forEach(([key]) => {
        //     delete newDateStates[key];
        // });

        setDateStates(newDateStates);
        saveFormData(newDateStates);
        setBooked('');
        setLabel('');
        setSelectedRange({ start: null, end: null });
    };

    const handleStop = (rangeKey) => {
        const newDateStates = { ...dateStates };
        if (newDateStates[rangeKey]) {
            delete newDateStates[rangeKey];
            setDateStates(newDateStates);
            saveFormData(newDateStates);
        }
    };

    const saveFormData = (dateStates) => {
        const update = async () => {
            try {
                await axios.put(WEBSITE_URL, {
                    username,
                    art,
                    title,
                    content: dateStates,
                });
                setSaved(true);
                setTimeout(() => {
                    setSaved(false);
                }, 5000);
            } catch (err) {
                console.log(err);
            }
        };
        update();
    };

    const handlePrevMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setCurrentDate(newDate);
    };

    const handleNextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setCurrentDate(newDate);
    };

    const handleAccept = (rangeKey) => {
        const newDateStates = { ...dateStates };
        if (newDateStates[rangeKey]) {
            newDateStates[rangeKey].booked = 'booked';
            setDateStates(newDateStates);
            saveFormData(newDateStates);
        }
    };

    const handleDecline = (rangeKey) => {
        const newDateStates = { ...dateStates };
        if (newDateStates[rangeKey]) {
            delete newDateStates[rangeKey];
            setDateStates(newDateStates);
            saveFormData(newDateStates);
        }
    };

    useEffect(() => {
        if (selectedRange.start && selectedRange.end) {
            const selectedStart = new Date(selectedRange.start);
            const selectedEnd = new Date(selectedRange.end);

            const overlappingRanges = Object.values(dateStates).some(range => {
                const rangeStart = new Date(range.start);
                const rangeEnd = new Date(range.end);
                const isBoundary = selectedRange.start === range.end || selectedRange.end === range.start;

                return !isBoundary && (
                    (selectedStart <= rangeEnd && selectedStart >= rangeStart) ||
                    (selectedEnd <= rangeEnd && selectedEnd >= rangeStart) ||
                    (selectedStart <= rangeStart && selectedEnd >= rangeEnd)
                );
            });
            setIsSaveDisabled(overlappingRanges);
        } else {
            setIsSaveDisabled(true);
        }
    }, [selectedRange, dateStates]);

    return (
        <>
            <Paper className={classes.paper}
                m="md"
                sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
                })}>
                <SimpleGrid cols={1} spacing="md" verticalSpacing="md" breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 1 }]}>
                    <Container size="xl" m="md" mt="xl">
                        <SimpleGrid cols={3} spacing="md" verticalSpacing="md">
                            <Button variant="outline" color="resirentPink" onClick={handlePrevMonth}>
                                {t('calendar-view.previous')}
                            </Button>
                            <Text size="md" weight={500} align="center">
                                {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
                            </Text>
                            <Button variant="outline" color="resirentPink" onClick={handleNextMonth}>
                                {t('calendar-view.next')}
                            </Button>
                        </SimpleGrid>

                        <SimpleGrid cols={7} spacing="xss" verticalSpacing="xs" mt="md">
                            {MonthViewHost(inputForm, currentDate, dateStates, selectedRange, setSelectedRange, 'Booked', handleAccept, handleDecline, handleStop)}
                        </SimpleGrid>
                    </Container>
                </SimpleGrid>
                <Container size="xl" m="md">
                    <Text size="xl" weight={500}>
                        Set the booked range
                    </Text>
                    <Select
                        data={bookingOptions}
                        placeholder="Select booked range"
                        value={booked}
                        onChange={(value) => setBooked(value)}
                        disabled={!selectedRange.start}
                    />
                    <Text size="xl" weight={500}>
                        Add a note
                    </Text>
                    <Textarea
                        id="noteBooking"
                        autosize
                        minRows={2}
                        value={label}
                        onChange={(event) => setLabel(event.currentTarget.value)}
                    />

                    {!selectedRange.start && !selectedRange.end && (
                        <Text size="sm" weight={500} color="red">
                            Select a date range first
                        </Text>
                    )}

                    <Box style={{ marginTop: '20px' }}>
                        <Button
                            align="center"
                            mb="md"
                            radius="xl"
                            onClick={() => handleCategorySaveClick(selectedRange, booked, label)}
                            size="md"
                            className={classes.controlSecond}
                            disabled={isSaveDisabled}
                        >
                            {t('calendar-view.save')}
                        </Button>
                    </Box>
                </Container>
            </Paper>

            <ConfirmModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onAccept={() => {
                    handleAccept(currentRangeKey);
                    setModalOpen(false);
                }}
                onDecline={() => {
                    handleDecline(currentRangeKey);
                    setModalOpen(false);
                }}
                bookingInfo={currentBookingInfo}
            />

            <BookingInfoModal
                isOpen={bookingInfoModalOpen}
                onClose={() => setBookingInfoModalOpen(false)}
                onCancel={() => {
                    handleStop(currentRangeKey);
                    setBookingInfoModalOpen(false);
                }}
                bookingInfo={currentBookingInfo}
            />

            <BookingList
                dateStates={dateStates}
                handleAccept={handleAccept}
                handleDecline={handleDecline}
                handleStop={handleStop}
            />
        </>
    );
};

export default CalendarBookedView;

const useStyles = createStyles((theme) => ({
    datepicker: {
        mt: "md"
    },

    paper: {
        m: "md",
        radius: "md",
        align: "center"
    },

    box: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",

        '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        },
    },

    input: {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[4],
        color: theme.black,

        '&::placeholder': {
            color: theme.colors.gray[5],
        },
    },

    inputLabel: {
        color: theme.black,
    },

    control: {
        backgroundColor: theme.colorScheme.resirentPink,
    },

    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center',
    },
}));
