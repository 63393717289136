import {
    createStyles,
    Table,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    Image,
    Box,
} from '@mantine/core';
import { IconCalendar, IconUser, IconCheck, IconSmartHome, IconShare, IconHomeHand, IconHomeStats } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";
import { Features } from './Features';
import image from '../../assets/consulting/consulting.jpeg'

// Define translations for titles and descriptions
const titleTranslations = {
    '/': {
        ResiWeb: "ResiWeb",
        ResiCal: "ResiCal",
        "Web and Cal": "Web and Cal",
    },
    '/nl': {
        ResiWeb: "ResiWeb",
        ResiCal: "ResiCal",
        "Web and Cal": "Web en Cal",
    },
    '/de': {
        ResiWeb: "ResiWeb",
        ResiCal: "ResiCal",
        "Web and Cal": "Web und Cal",
    },
};

const mockdata = [
    {
        title: {
            '/': 'Comprehensive Guide',
            '/nl': 'Opstartpakket',
            '/de': 'Comprehensive Guide',
        },
        description: {
            '/': 'A comprehensive guide to the vacation rental industry, this package is ideal for those who are new to the business. We provide you with all the tools you need to get started, from a detailed step-by-step plan to legally sound terms and conditions. We also include mandatory materials and communication templates to ensure you are fully prepared to welcome your first guests. This package is priced at €1500 and includes:',
            '/nl': 'Pakket waarin we samen met jullie de vakantieverhuur opstarten, van inrichting tot eerste klant. Dit pakket omvat: ',
            '/de': 'Ein umfassender Leitfaden für die Ferienhausbranche, dieses Paket ist ideal für diejenigen, die neu im Geschäft sind. Wir stellen Ihnen alle Werkzeuge zur Verfügung, die Sie zum Einstieg benötigen, von einem detaillierten Schritt-für-Schritt-Plan bis hin zu rechtlich einwandfreien Allgemeinen Geschäftsbedingungen. Wir enthalten auch obligatorische Materialien und Kommunikationsvorlagen, um sicherzustellen, dass Sie vollständig auf den Empfang Ihrer ersten Gäste vorbereitet sind. Dieses Paket kostet 1500 € und beinhaltet:',
        },
        price: {
            '/': '€1500',
            '/nl': '€1500',
            '/de': '€1500',
        },
        monthly_price: {
            '/': '€ 19 per extra language/year',
            '/nl': '€ 19 per extra taal/jaar',
            '/de': '€ 19 für jede zusätzliche Sprache/Jahr',
        },
        btw: {
            '/': 'excl. VAT',
            '/nl': 'excl. BTW',
            '/de': 'exkl. MwSt.',
        },
        featureList: [
            {
                '/': '1. Six hours of intensive guidance by us (to be planned together) in which we go through and adapt to your region and specific accommodation.',
                '/nl': '1.	Zes uur intensieve begeleiding waarin we samen de te nemen stappen aangepast aan jouw regio en specifieke accomodatie doornemen.',
                '/de': '1. Sechs Stunden intensive Anleitung von uns (gemeinsam zu planen), in denen wir durchgehen und an Ihre Region und spezifische Unterkunft anpassen.',
            },
            {
                '/': '2. A comprehensive guide that you will receive from us in which all steps are still worked out and which you can use before and after the guidance.',
                '/nl': '2. Een uitgewerkt witboek dat je van ons krijgt waarin alle stappen nog uitgewerkt staan en die je kan gebruiken voor en na de begeleiding.',
                '/de': '2. Ein detailliertes Handleitung, das Sie von uns erhalten, in dem alle Schritte noch ausgearbeitet sind und das Sie vor und nach der Anleitung verwenden können.',
            }
        ],
        icon: IconSmartHome,
    },
    {
        title: {
            '/': 'Additional guidance',
            '/nl': 'Bijkomend advies op maat:',
            '/de': 'Zusätzliche Anleitung',
        },
        description: {
            '/': "Do you have any specific questions, for example to train your cleaning service, outsource certain tasks such as placing you on different booking sites, organizing communication with your customers, etc... we are happy to do this at a predetermined hourly rate.",
            '/nl': 'Heb je nog specifieke vragen om bijvoorbeeld je schoonmaakdienst op te leiden, het uitbesteden van bepaalde taken zoals je op verschillende boekingssites plaatsen, de organisatie van communicatie met je klanten, enz… we doen dit graag aan een voorafbepaald uurtarief.',
            '/de': 'Haben Sie noch spezifische Fragen, z. B. zur Schulung Ihres Reinigungsdienstes, zur Auslagerung bestimmter Aufgaben wie der Platzierung auf verschiedenen Buchungsseiten, zur Organisation der Kommunikation mit Ihren Kunden usw. ... wir tun dies gerne zu einem vorher festgelegten Stundensatz.',
        },
        price: {
            '/': '',
            '/nl': '',
            '/de': '',
        },
        monthly_price: {
            '/': '€ 19 per extra language/year',
            '/nl': '€ 19 per extra taal/jaar',
            '/de': '€ 19 für jede zusätzliche Sprache/Jahr',
        },
        btw: {
            '/': '',
            '/nl': '',
            '/de': '',
        },
        featureList: [

        ],
        icon: IconHomeHand,
        icon2: IconCalendar,
    },
];

const useStyles = createStyles((theme) => ({
    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    card: {
        border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },

    cardSpecial: {
        border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },

    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '100%',
    },

}));

export function Consulting() {
    const { classes, theme } = useStyles()
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const { t } = useTranslation();

    const features = mockdata.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div>
                    <feature.icon size={rem(50)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                </div>
                <div>
                    <Text fw={500} className={classes.cardTitle} fz="xl" mt="md" mb="md">
                        {feature.title[languagePrefix] || feature.description['/']}

                    </Text>
                </div>
                <td style={{ textAlign: 'left' }}>
                    {feature.description[languagePrefix] || feature.description['/']}
                </td>
                <div style={{ flex: 1 }} mb="md">
                    <Table striped highlightOnHover color="#FFF5F9">
                        <tbody>
                            {feature.featureList.map((value, index) => (
                                <tr key={`${feature.title}-${index}`}>
                                    <td style={{ textAlign: 'left' }}>
                                        <IconCheck size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                                    </td>
                                    <td style={{ textAlign: 'left' }}>
                                        {value[languagePrefix] || value['/']} {/* Use the translation based on the selected language or fallback to English */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div style={{ marginTop: 'auto' }} mt="md">
                    <Text mb="md" mt="md" fz="xl" c="dimmed" align="left">
                        {feature.price[languagePrefix] || feature.price['/']} {/* Use the translation based on the selected language or fallback to English */}
                    </Text>
                    {/* Smaller text than above */}
                    <Text mb="md" fz="xs" c="dimmed" align="left">
                        {feature.btw[languagePrefix] || feature.btw['/']}
                    </Text>
                </div>
            </div>
        </Card>
    ));

    return (
        <Box>
            <Image
                src={image}
                alt="Consulting - hospitality in the small things"
                height={400}
            ></Image>
            <Container size="lg" py={100}>

                <Group position="center">
                    <Title order={4} weight={300} align="center" color="resirentPink">
                        {t("consulting.tag")}
                    </Title>
                </Group>

                <Title order={2} className={classes.title} ta="center" mt="sm">
                    {t("consulting.title")}
                </Title>

                <Text className={classes.description} ta="center" my="lg">
                    {t("consulting.text")}
                </Text>

                <Text className={classes.description} ta="center" my="lg">
                    {t("consulting.text2")}
                </Text>

                {/* <Features /> */}

                <Title order={2} className={classes.title} ta="center" mt="sm">
                    {t("consulting.subtitle3")}
                </Title>

                <Group position="center" pb="xl">
                    <SimpleGrid cols={2} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                        {features}
                    </SimpleGrid>
                </Group>

                <Title order={2} className={classes.title} ta="center" mt="sm">
                    {t("consulting.subtitle4")}
                </Title>

                <Text className={classes.description} ta="center" my="lg">
                    {t("consulting.text4")}
                </Text>

                <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2r7IfyTxToIup5NcLdAzJZAVUrElKsCkGsd-R2INmcI-WpqtlGNXVLP67uNApAjYcXeHWPLedq?gv=true" width="100%" height="600" frameborder="0"></iframe>
            </Container>
        </Box>
    );
}