import React, { useState, useEffect } from 'react';
import { Text, Container, Box, Divider, SimpleGrid, Paper } from '@mantine/core';
import axios from '../../../api/axios';
import { useParams } from 'react-router-dom';

export default function BookingSummary({
  selectedRangeStart,
  selectedRangeEnd,
  inputForm,
  numPeople,
  numDogs,
  hasBreakfast,
  numUnderage,
  message,
  onSummaryCalculated // New prop to pass the calculated summary back to the parent component
}) {
  const [totalPrice, setTotalPrice] = useState(0);
  const [numNights, setNumNights] = useState(0);
  const [numBasePrice, setNumBasePrice] = useState(0);
  const [numHighPrice, setNumHighPrice] = useState(0);
  const [numLowPrice, setNumLowPrice] = useState(0);
  const [weekendExtraCostCounter, setWeekendExtraCostCounter] = useState(0);
  const [extraCostPerPersonCounter, setExtraCostPerPersonCounter] = useState(0);
  const [discountMonthly, setDiscountMonthly] = useState(0);
  const [discountWeekly, setDiscountWeekly] = useState(0);
  const currencyUsed = inputForm.currencyUsed;
  const WEBSITE_URL = '/websitejson';
  const art = "en";
  const title = "calendar-pricing";
  const [dateStates, setDateStates] = useState('');
  const { url_website_host } = useParams();
  const username = url_website_host;

  // Fetch the DateStates from the database
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${WEBSITE_URL}`, {
          params: { username, art, title },
        });
        setDateStates(JSON.parse(response.data.entry.content));
        console.log(JSON.parse(response.data.entry.content));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [username, art, title]);

  useEffect(() => {
    let basePrice = 0;
    let maxPeopleBasePrice = 0;
    let extraCostPerPerson = 0;
    let weekendExtraCost = 0;
    let weekend = 0;
    const startDate = new Date(selectedRangeStart);
    const endDate = new Date(selectedRangeEnd);

    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const timeDifference = endDate.getTime() - startDate.getTime();
    const numNightsCal = timeDifference / (1000 * 3600 * 24);

    let priceCounter = 0;
    let basePriceCount = 0;
    let highPriceCount = 0;
    let lowPriceCount = 0;

    const getDateRangeInfo = (currentDate) => {
      const formattedCurrentDate = currentDate.toISOString().split('T')[0];

      for (const rangeInfo of Object.values(dateStates)) {
        const rangeStart = rangeInfo.start;
        const rangeEnd = rangeInfo.end;

        if (formattedCurrentDate >= rangeStart && formattedCurrentDate <= rangeEnd) {
          return rangeInfo;
        }
      }

      return { price: 'base' }; // Default to 'base' if no range matches
    };

    for (let number = 1; number <= numNightsCal; number++) {
      const currentDate = new Date(startDate.getTime() + number * oneDay);
      const dateStateInfo = getDateRangeInfo(currentDate);
      const priceLabel = dateStateInfo.price;

      const price = priceLabel === 'high' ? 'highSeason' : priceLabel === 'low' ? 'lowSeason' : 'basePrice';
      if (price === 'basePrice') {
        basePriceCount++;
      } else if (price === 'highSeason') {
        highPriceCount++;
      } else if (price === 'lowSeason') {
        lowPriceCount++;
      }

      basePrice = inputForm.pricing[price].amount;
      maxPeopleBasePrice = inputForm.pricing[price].maxPeoplePrice;
      extraCostPerPerson = inputForm.pricing[price].extraCostPerPerson;
      weekendExtraCost = inputForm.pricing[price].weekendExtraCost;

      if (numPeople > maxPeopleBasePrice) {
        basePrice += (numPeople - maxPeopleBasePrice) * extraCostPerPerson;
        setExtraCostPerPersonCounter(((numPeople - maxPeopleBasePrice) * extraCostPerPerson) * numNightsCal);
      }

      const day = currentDate.getDay();
      // Check if the day is a weekend day (Friday (5), Saturday (6) or Sunday (0))
      if (day === 0 || day === 6 || day === 5) {
        basePrice += weekendExtraCost;
        weekend++;
        setWeekendExtraCostCounter(weekend);
      }
      priceCounter += basePrice;
    }

    if (numNightsCal >= 28) {
      priceCounter *= 1 - inputForm.pricing.discountMonthly.amount * 0.01;
      setDiscountMonthly(1);
    }

    if (numNightsCal >= 7 && numNightsCal < 28) {
      priceCounter *= 1 - inputForm.pricing.discountWeek.amount * 0.01;
      setDiscountWeekly(1);
    }

    // Calculation of the city tax
    let cityTax = inputForm.pricing.cityTax.amount * ((numUnderage) * numNightsCal);

    const dogFee = numDogs === 0 ? 0 : inputForm.pricing.dogFee.amount;
    const cleaningFee = inputForm.pricing.cleaningFee ? inputForm.pricing.cleaningFee.amount : 0;
    const breakfastFee = hasBreakfast ? inputForm.pricing.breakfastFee.amount * numPeople * numNightsCal : 0;

    const finalTotalPrice = priceCounter + dogFee + breakfastFee + cleaningFee + cityTax;

    setTotalPrice(finalTotalPrice);
    setNumNights(numNightsCal);
    setNumBasePrice(basePriceCount);
    setNumHighPrice(highPriceCount);
    setNumLowPrice(lowPriceCount);
    setWeekendExtraCostCounter(weekend);

    console.log(extraCostPerPerson);
    console.log(numPeople);
    console.log(maxPeopleBasePrice);

    // Prepare summary data to pass back to the parent component
    const summaryData = {

      welcomeSentence: inputForm.welcomeSentence,
      numNights: numNightsCal,
      totalPrice: finalTotalPrice,

      basePrice: inputForm.pricing["basePrice"].amount,
      basePriceCount: basePriceCount,

      highPrice: inputForm.pricing["highSeason"].amount,
      highPriceCount: highPriceCount,

      lowPrice: inputForm.pricing["lowSeason"].amount,
      lowPriceCount: lowPriceCount,

      showDogFee: inputForm.pricing.dogFee.included,
      dogFee: dogFee,

      showBreakfastFee: inputForm.pricing.breakfastFee.included,
      breakfastFee: breakfastFee,

      showCleaningFee: inputForm.pricing.cleaningFee ? true : false,
      cleaningFee: cleaningFee,

      showCityTax: inputForm.pricing.cityTax.included,
      cityTax: cityTax,

      weekendExtraCostCounter: weekend,
      weekendExtraCost: weekendExtraCost,

      extraCostPerPersonCounter: numPeople > maxPeopleBasePrice,
      extraCostPerPerson: extraCostPerPerson,

      discountMonthlyPercentage: inputForm.pricing.discountMonthly.amount,
      discountMonthly: numNightsCal >= 28 ? 1 : 0,
      discountWeeklyPercentage: inputForm.pricing.discountWeek.amount,
      discountWeekly: numNightsCal >= 7 && numNightsCal < 28 ? 1 : 0,

      currencyUsed: currencyUsed,
    };

    onSummaryCalculated(summaryData);

  }, [selectedRangeStart, selectedRangeEnd, inputForm, numPeople, numDogs, hasBreakfast, numUnderage]);

  return (
    <Paper padding="xl" shadow="xs" style={{ width: '100%' }} sx={(theme) => ({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
    })}>
      <Container p="md">
        <Box mt="lg" mb="lg">
          <Text size="xl" weight={700} align="center">
            Booking Request Summary
          </Text>
        </Box>
        <Text align="center">{inputForm.welcomeSentence}</Text>
        <Divider mt="md" mb="md" />
        <SimpleGrid cols={2} spacing="md" mt="md">
          <Box>
            <Text fs="italic" size="md" weight={500}>
              Stay Period:
            </Text>
            <Text>
              Check-in date: <strong>{selectedRangeStart}</strong>
            </Text>
            <Text>
              Check-out date: <strong>{selectedRangeEnd}</strong>
            </Text>
            <Text>
              Number of nights: <strong>{numNights}</strong>
            </Text>
          </Box>
          <Box>
            <Text fs="italic" size="md" weight={500}>
              Guests:
            </Text>
            <Text>
              Number of guests: <strong>{numPeople}</strong>
            </Text>
            <Text>
              Number of guests with city tax exemption: <strong>{(numPeople - numUnderage)}</strong>
            </Text>
            {inputForm.pricing.dogFee.included && (
              <Text>
                Number of dogs: <strong>{numDogs}</strong>
              </Text>
            )}
          </Box>
        </SimpleGrid>
        <Divider mt="md" mb="md" />
        <Box mt="md">
          <Text fs="italic" size="md" weight={500}>
            Pricing Breakdown:
          </Text>
          <Text>
            Base nights: <strong>{numBasePrice}</strong> with price of <strong>{inputForm.pricing.basePrice.amount} {currencyUsed}</strong>
          </Text>
          <Text>
            High season nights: <strong>{numHighPrice}</strong> with price of <strong>{inputForm.pricing.highSeason.amount} {currencyUsed}</strong>
          </Text>
          <Text>
            Low season nights: <strong>{numLowPrice}</strong> with price of <strong>{inputForm.pricing.lowSeason.amount} {currencyUsed}</strong>
          </Text>
          {inputForm.pricing.dogFee.included && (
            <Text>
              Dog fee: <strong>{inputForm.pricing.dogFee.amount * numDogs} {currencyUsed}</strong> with a price of <strong>{inputForm.pricing.dogFee.amount} {currencyUsed}  </strong> per dog.
            </Text>
          )}
          {hasBreakfast && (
            <Text>
              Breakfast fee: <strong>{inputForm.pricing.breakfastFee.amount * numNights * numPeople} {currencyUsed} </strong> with a price of <strong>{inputForm.pricing.breakfastFee.amount}{currencyUsed}</strong> per person per breakfast.
            </Text>
          )}
          {inputForm.pricing.cleaningFee && (
            <Text>
              Cleaning fee: <strong>{inputForm.pricing.cleaningFee.amount} {currencyUsed}</strong>
            </Text>
          )}
          {inputForm.pricing.cityTax.included && (
            <Text>
              City tax: <strong>{inputForm.pricing.cityTax.amount * ((numUnderage) * numNights)} {currencyUsed}</strong>
            </Text>
          )}
          <Text>
            Weekend extra cost (Friday, Saturday and Sunday night): <strong>{weekendExtraCostCounter}</strong> night(s) with an extra price of  <strong> {inputForm.pricing.basePrice.weekendExtraCost} {currencyUsed}</strong>
          </Text>
          {extraCostPerPersonCounter != 0 &&
            <Text>
              Extra total cost of additional guests starting from {inputForm.pricing['basePrice'].maxPeoplePrice + 1}: <strong> {extraCostPerPersonCounter}{currencyUsed} </strong> with an additional cost of <strong>{inputForm.pricing.basePrice.extraCostPerPerson} {currencyUsed} </strong> per extra person per night.
            </Text>
          }
          {discountMonthly != 0 &&
            <Text>
              Discount (more than 28 days): <strong>{inputForm.pricing.discountMonthly.amount}%</strong>
            </Text>
          }
          {discountWeekly != 0 &&
            <Text>
              Discount (more than 7 days): <strong>{inputForm.pricing.discountWeek.amount}%</strong>
            </Text>
          }

        </Box>
        <Divider mt="md" mb="md" />
        <Box mt="md">
          <Text fs="italic" size="md" weight={500}>
            Your message to the host:
          </Text>
          <Text>
            {message}
          </Text>
        </Box>
        <Divider mt="md" mb="md" />
        <Divider mt="md" mb="md" />
        <Box mt="md" mb="md">
          <Text size="md" weight={700}>
            Total Price: <strong>{totalPrice} {currencyUsed}</strong>
          </Text>
        </Box>
        <Divider mt="md" mb="md" />
        <Divider mt="md" mb="md" />
        <Box mt="md" mb="md">
          <Text fs="italic" size="md" weight={500}>
            Cancellation policy:
          </Text>
          <Text>{inputForm.cancellationPolicy}</Text>
          <Divider mt="md" mb="md" />
          <Text fs="italic" size="md" weight={500}>
            House Rules:
          </Text>
          <Text>{inputForm.houseRules}</Text>
        </Box>
      </Container>
    </Paper >
  );
}
