import React from 'react'
import { createStyles, rem, Text, Box, Container, List, Title } from '@mantine/core';
import { IconHome } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        width: '80%',
        marginTop: rem(60),
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '100%',
        margin: 'auto',
        fontWeight: 400,
    },
}));

export default function Benefits({ language }) {

    const { classes, theme } = useStyles();

    const content = {
        '/': {
            'mainTitle': 'Why Have Your Own Website?',
            'intro': 'Because this...',
            'resiWebsiteListItem1': '... allows you to attract the customers you really want, not just those looking for the cheapest option with the best location.',
            'resiWebsiteListItem2': '... allows you to communicate with your guests before they book and allows your regular guests to book directly.',
            'resiWebsiteListItem3': '... gives you the opportunity to add your personal touches and provide tips about the area that you find important.',
            'resiWebsiteListItem4': '... gives you control over prices, cancellation policies, and more.',
            'resiWebsiteListItem5': '... makes you easier to find and refer to by customers.',
            'resiWebsiteListItem6': '... allows you to avoid commission and transaction fees when customers book through the calendar option.',
        },
        '/nl': {
            'mainTitle': 'Waarom je eigen website hebben?',
            'intro': 'Omdat dit...',
            'resiWebsiteListItem1': '... je in staat stelt om de klanten aan te trekken die je echt wilt, niet alleen degenen die op zoek zijn naar de goedkoopste optie met de beste locatie.',
            'resiWebsiteListItem2': '... je in staat stelt om te communiceren met je gasten voordat ze boeken en je vaste gasten direct te laten boeken.',
            'resiWebsiteListItem3': '... je de mogelijkheid geeft om persoonlijke accenten toe te voegen en tips te geven over het gebied dat jij belangrijk vindt.',
            'resiWebsiteListItem4': '... je controle geeft over prijzen, annuleringsvoorwaarden en meer.',
            'resiWebsiteListItem5': '... zorgt ervoor dat je gemakkelijker te vinden en door te verwijzen bent voor klanten.',
            'resiWebsiteListItem6': '... je in staat stelt om commissies en transactiekosten te vermijden wanneer klanten via de kalenderoptie boeken.',
        },
        '/de': {
            'mainTitle': 'Warum eine eigene Website haben?',
            'intro': 'Weil dies...',
            'resiWebsiteListItem1': '... Ihnen ermöglicht, die Kunden anzulocken, die Sie wirklich möchten, und nicht nur diejenigen, die nach der günstigsten Option mit der besten Lage suchen.',
            'resiWebsiteListItem2': '... Ihnen ermöglicht, vor der Buchung mit Ihren Gästen zu kommunizieren und Ihren Stammgästen die direkte Buchung zu ermöglichen.',
            'resiWebsiteListItem3': '... Ihnen die Möglichkeit gibt, persönliche Akzente zu setzen und Tipps zur Umgebung zu geben, die Ihnen wichtig sind.',
            'resiWebsiteListItem4': '... Ihnen die Kontrolle über Preise, Stornierungsrichtlinien und mehr gibt.',
            'resiWebsiteListItem5': '... dafür sorgt, dass Sie von Kunden leichter gefunden und weiterempfohlen werden.',
            'resiWebsiteListItem6': '... Ihnen ermöglicht, Provisionen und Transaktionsgebühren zu vermeiden, wenn Kunden über die Kalenderoption buchen.',
        },
    };


    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box>
            <Container size="lg" py={100}>
                <Title order={2} className={classes.title} my="sm" display={'block'}>
                    {content[languagePrefix].mainTitle}
                </Title>
                <Text className={classes.description} my="lg">
                    {content[languagePrefix].intro}
                </Text>
                <List
                    mt={30}
                    spacing="sm"
                    size="sm"
                    icon={
                        <IconHome size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                    }
                >
                    <Text className={classes.description} >
                        <List.Item>
                            {content[languagePrefix].resiWebsiteListItem1}

                        </List.Item>
                        <List.Item>
                            {content[languagePrefix].resiWebsiteListItem2}

                        </List.Item>
                        <List.Item>
                            {content[languagePrefix].resiWebsiteListItem3}
                        </List.Item>
                        <List.Item>
                            {content[languagePrefix].resiWebsiteListItem4}
                        </List.Item>
                        <List.Item>
                            {content[languagePrefix].resiWebsiteListItem5}
                        </List.Item>
                        <List.Item>
                            {content[languagePrefix].resiWebsiteListItem6}
                        </List.Item>
                    </Text>
                </List>

            </Container>
            {/* <Box align="right">
                        <Text component='a' href={imageButton[currentImage].link} order={1} weight={400} color="dimmed" mt="md">
                            {imageButton[currentImage].name}
                        </Text>
                    </Box> */}
        </Box >
    )
}
