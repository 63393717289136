import {
  createStyles,
  Text,
  Title,
  SimpleGrid,
  Group,
  ActionIcon,
} from '@mantine/core';
import { IconMail, IconSun } from '@tabler/icons-react';
import { socialMediaLinks } from '../../languages/translations';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: 'border-box',
    padding: `calc(${theme.spacing.xl} * 2.5)`,

    [theme.fn.smallerThan('sm')]: {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.colors.resirentPink[0],
    lineHeight: 1,
  },

  description: {
    color: theme.black,

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
    '&:hover': {
      boxShadow: theme.shadows.xl,
      transform: 'scale(1.01)',
    },
  },

  social: {
    color: theme.colorScheme.resirent,
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    '&::placeholder': {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: theme.colorScheme.resirentPink,
  },
}));

export function Contact() {
  const { classes } = useStyles();
  const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '';
  const { t } = useTranslation();

  const icons = socialMediaLinks.map((item) => (
    <ActionIcon component="a" href={item.href} color="resirent" variant="filled" key={item.title} size={28}>
      <item.Icon size="1.4rem" stroke={1.5} />
    </ActionIcon>
  ));

  return (
    <div className={classes.wrapper}>
      <SimpleGrid cols={2} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>

        <div>
          <Title className={classes.title}> {t("contact.title")}</Title>
          <Text className={classes.description} mt="sm" mb={30}>{t("contact.description")}</Text>
          <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ02L8LZkytqFtf7P6ewf38_e2P_WzKPvfFF4uOoXZXAaJoykYMJJghk2SYydiNOBcSA_iYWBhlE?gv=true" width="100%" height="600" frameborder="0"></iframe>
        </div>
        <div>
          <Title className={classes.title}>{t("contact.title2")}</Title>
          <Text className={classes.description} mt="sm" mb={30}>
            {t("contact.description2")}
          </Text>
          <IconMail strokeWidth={1} size={28} />
          <Title order={6} weight={200} color="resirent-pink">
            {t("contact.mail")}
          </Title>
          {/* Anchor to mail */}
          <Text className={classes.description} mb="md"><a href="mailto:resirent@resirent.org">resirent@resirent.org</a></Text>
          <IconSun size={28} strokeWidth={1} />
          <Title order={6} weight={200} color="resirent-pink">
            {t("contact.working_hours")}
          </Title>
          <Text className={classes.description} mb="md">8 a.m. – 4 p.m.</Text>
          <Group mt="xl">{icons}</Group>
        </div>
      </SimpleGrid>
    </div>
  );
}