import { useEffect, useState, useRef } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';
import { useTranslation } from "react-i18next";

const LOGIN_URL = '/login';

export default function Login() {

  const { setAuth } = useAuth();
  const { t } = useTranslation();

  const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  // const [user, setUser] = useState('');
  const [user, resetUser, userAttribs] = useInput('user', '');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [check] = useToggle('persist', true);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      const accessToken = response?.data?.accessToken;
      const role = response?.data?.role;
      const resiWeb = response?.data?.resiWeb;
      const resiCal = response?.data?.resiCal;
      setAuth({ user, pwd, role, resiWeb, resiCal, accessToken });
      // setUser('');
      resetUser();
      setPwd('');
      navigate('/dashboard/profile', { replace: true }); //TODO: navigate where??
      // navigate(from, {replace: true});

    } catch (err) {
      // if (!err?.response) {
      //     setErrMsg("No server response");
      // } else if (err.response?.status === 400) {
      //     setErrMsg("No such E-Mail registered")
      // } else if (err.response?.status === 401) {
      //     setErrMsg("Wrong Password.")
      // } else if (err.response?.status === 402) {
      //     setErrMsg("You are not an admin of this website.")
      // } else {
      //     setErrMsg("Login failed. Unknown error.")
      // }
      // errRef.current.focus();
      console.error(err);
    }
  }

  return (
    <>

      {
        <div style={{ display: "flex", minHeight: "100vh", alignItems: "center", justifyContent: "center", padding: "24px 16px", background: "white" }}>
          <p ref={errRef} aria-live="assertive">{errMsg}</p>
          <div style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "8px", width: "100%", maxWidth: "md", marginTop: "32px", padding: "24px" }}>
            <div>
              <h2 style={{ textAlign: "center", marginTop: "24px", fontSize: "24px", fontWeight: "bold", color: "#1F2937" }}><h1>{t("login.title")}</h1></h2>
            </div>
            <form onSubmit={handleSubmit} style={{ marginTop: "24px" }} action="#" method="POST">
              <input type="hidden" name="remember" defaultValue="true" />
              <div style={{ marginTop: "16px" }}>
                <label htmlFor="email-address" style={{ display: "none" }}>Email address</label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  ref={userRef}
                  autoComplete="email"
                  required
                  style={{ marginBottom: "16px", display: "block", width: "100%", borderRadius: "4px", border: "1px solid #E5E7EB", padding: "12px", fontSize: "16px", lineHeight: "24px" }}
                  placeholder="Mail"
                  {...userAttribs}
                />
              </div>
              <div style={{ marginTop: "16px" }}>
                <label htmlFor="password" style={{ display: "none" }}>{t("login.labelpass")}</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  style={{ marginBottom: "16px", display: "block", width: "100%", borderRadius: "4px", border: "1px solid #E5E7EB", padding: "12px", fontSize: "16px", lineHeight: "24px" }}
                  placeholder="Password"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
              </div>
              <div style={{ marginTop: "24px" }}>
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "12px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: "4px",
                    border: "none",
                    backgroundColor: "#E4C577",
                    color: "#FFFFFF",
                    cursor: check ? "pointer" : "default",
                  }}
                >
                  {t("login.login")}
                </button>
                <Link to="/forgot-password">
                  {t("login.forgot")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      }
      {/* <Container size={420} my={40}>
        <Title ta="center">
          Welcome back!
        </Title>
        <Text c="dimmed" size="sm" ta="center" mt={5}>
          Do not have an account yet?{' '}
          <Anchor size="sm" component="button">
            Create account
          </Anchor>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput label="Email" placeholder="you@mantine.dev" required />
          <PasswordInput label="Password" placeholder="Your password" required mt="md" />
          <Group justify="space-between" mt="lg">
            <Checkbox label="Remember me" />
            <Anchor component="button" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button fullWidth mt="xl">
            Sign in
          </Button>
        </Paper>
      </Container> */}
    </>
  );
}