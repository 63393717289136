import React from 'react'

export default function SignUp() {

    const language = window.location.pathname.startsWith('/nl') ? 'nl' : 'en';

    return (
        <div>
            SignUp
            {language}
        </div>
    )
}
