import * as React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import Layout from './layout';
import Home from './components/home/Home';
import { MantineProvider } from '@mantine/core';
import RequireAuth from './context/RequireAuth';
import Dashboard from './components/dashboard/Dashboard';
import Blog from './components/blog/Blog';
import ComingSoon from './components/informative/ComingSoon';
import Login from './components/authorization/Login';
import Register from './components/authorization/Registration';
import SignUp from './components/informative/SignUp';
import About from './components/about/About';
import Hosts from './components/hosts/Hosts';
import ResiWebsite from './components/products/ResiWebsite';
import ResiAccessoires from './components/products/ResiAccessoires';
import ResiCalendar from './components/products/ResiCalendar';
import { ErrorPage } from './components/informative/ErrorPage';
import { Contact } from './components/about/Contact';
import BlogpostLayout from './components/blog/BlogPostLayout';
import { Prices } from './components/prices/Prices';
import CalendarExample from './components/calendar/CalendarExample';
import PersistLogin from './context/PersistLogin';
import Demo from './components/informative/BlogInputForm';
import Philosophy from './components/about/Philosophy';
import Team from './components/about/Team';
import Partners from './components/Community/Partners';
import PasswordReset from './components/authorization/PasswordReset';
import ForgotPassword from './components/authorization/ForgotPassword';
import LayoutDashboard from './components/dashboard/layout';
import { Consulting } from './components/consulting/Consulting';
import FormRegister from './components/informative/FormRegister';
import DIY from './components/informative/DIY';
import FormRegisterResiCal from './components/informative/FormRegiserResiCal';
import CalendarSettings from './components/dashboard/Calendar-settings';
import CalendarViewHost from './components/dashboard/Calendar-view';
import WebsiteCalendarTourist from './components/calendar/TouristView/WebsiteCalendarTourist';
import CalendarCleaningView from './components/calendar/CleaningView/CalendarCleaningView';
import CalendarCleaning from './components/dashboard/calendar-cleaning';

const App = () => {

  return (
    <MantineProvider
      theme={{
        colors: {
          // override dark colors to change them for all components
          'resirent': ['#003D51', '#5FCCDB', '#44CADC', '#2AC9DE', '#1AC2D9', '#11B7CD', '#003D51', '#0E99AC', '#128797', '#147885'],
          'resirentPink': ['#F06595', '#F06595', '#F06595', '#F06595', '#F06595', '#F06595', '#F06595', '#F06595', '#F06595', '#F06595'],
        },
        fontFamily: 'Open Sans, sans-serif',
      }}
      withGlobalStyles withNormalizeCSS>

      <Routes>
        <Route element={<PersistLogin />}>
          <Route path='/' element={<Layout />}>


            {/* public routes */}
            <Route key="coming-soon" path="/coming-soon" element={<ComingSoon />} />
            <Route key="coming-soon-nl" path="/nl/komt-binnerkort" element={<ComingSoon />} />
            <Route key="login" path="/login" element={<Login />} />
            <Route key="register" path="/register" element={<Register />} />
            <Route key="reset" path="/reset-password/:user/:token" element={<PasswordReset />} />
            <Route key="request-reset" path="/forgot-password/" element={<ForgotPassword />} />
            <Route key="sign-up" path="/sign-up" element={<SignUp />} />
            <Route path='/*' element={<ErrorPage />} />
            <Route path='/calendar-example' element={<CalendarExample />} />

            {/* En version */}
            <Route key="home" path="/" element={<Home language="en" />} />
            <Route key="blog-en" path="/blog" element={<Blog />} />
            <Route key="about" path="/about" element={<About language="en" />} />
            <Route key="philosophy" path="/philosophy" element={<Philosophy />} />
            <Route key="contact" path="/contact" element={<Contact language="en" />} />
            <Route key="hosts" path="/hosts" element={<Hosts language="en" />} />
            <Route key="prices" path="/prices" element={<Prices language="en" />} />
            <Route key="team" path="/team" element={<Team />} />
            <Route key="resiwebsite" path="/products/resirent-website" element={<ResiWebsite language="en" />} />
            <Route key="resiaccessoires" path="/products/resirent-stickers" element={<ResiAccessoires language="en" />} />
            <Route key="resicalendar" path="/products/resirent-calendar" element={<ResiCalendar language="en" />} />
            <Route key="partner" path='/partners' element={<Partners />} />
            <Route key="consulting" path='/products/consulting' element={<Consulting />} />
            <Route key="inputRegisterfromResiCal" path='/resical/signup' element={<FormRegisterResiCal />} />
            <Route key="inputRegisterfrom" path='/resiweb/signup' element={<FormRegister />} />
            <Route key="DIY" path="register/diy" element={<DIY />} />

            {/* Dutch version */}
            <Route key="startpagina" path="/nl" element={<Home language="nl" />} />
            <Route key="blog-nl" path="/nl/blog" element={<Blog />} />
            <Route key="about-nl" path="/nl/over-ons" element={<About language="nl" />} />
            <Route key="philosophy-nl" path="/nl/filosofie" element={<Philosophy />} />
            <Route key="contact-nl" path="/nl/contact" element={<Contact language="nl" />} />
            <Route key="hosts-nl" path="/nl/gastgevers" element={<Hosts language="nl" />} />
            <Route key="prices-nl" path="/nl/prijzen" element={<Prices language="nl" />} />
            <Route key="team-nl" path="/nl/team" element={<Team />} />
            <Route key="resiwebsite-nl" path="/nl/producten/resirent-website" element={<ResiWebsite language="nl" />} />
            <Route key="resiaccessoires-nl" path="/nl/producten/resirent-stickers" element={<ResiAccessoires language="nl" />} />
            <Route key="resicalendar-nl" path="/nl/producten/resirent-calendar" element={<ResiCalendar language="nl" />} />
            <Route key="inputform-nl" path="/nl/workshop-aanmelden" element={<Demo />} />
            <Route key="partner-nl" path='/nl/partners' element={<Partners />} />
            <Route key="inputRegisterfrom-nl" path='/nl/resiweb/aanmelden' element={<FormRegister />} />
            <Route key="inputRegisterfromResiCal-nl" path='/nl/resical/aanmelden' element={<FormRegisterResiCal />} />
            <Route key="consulting-nl" path='/nl/producten/advies' element={<Consulting />} />

            {/* German version */}
            <Route key="startseite" path="/de" element={<Home language="de" />} />
            <Route key="blog-de" path="/de/blog" element={<Blog />} />
            <Route key="about-de" path="/de/uber-uns" element={<About language="de" />} />
            <Route key="philosophy-de" path="/de/philosophie" element={<Philosophy />} />
            <Route key="contact-de" path="/de/kontakt" element={<Contact language="de" />} />
            <Route key="hosts-de" path="/de/gastgeber" element={<Hosts language="de" />} />
            <Route key="prices-de" path="/de/preise" element={<Prices language="de" />} />
            <Route key="team-de" path="/de/team" element={<Team />} />
            <Route key="resiwebsite-de" path="/de/produkte/resirent-website" element={<ResiWebsite language="de" />} />
            <Route key="resiaccessoires-de" path="/de/produkte/resirent-stickers" element={<ResiAccessoires language="de" />} />
            <Route key="resicalendar-de" path="/de/produkte/resirent-calendar" element={<ResiCalendar language="de" />} />
            <Route key="inputform-de" path="/de/workshop-anmeldung" element={<Demo />} />
            <Route key="partner-de" path='/de/partners' element={<Partners />} />
            <Route key="inputRegisterfrom-de" path='/de/resiweb/anmelden' element={<FormRegister />} />
            <Route key="inputRegisterfromResiCal-de" path='/de/resical/anmelden' element={<FormRegisterResiCal />} />
            <Route key="consulting-de" path='/de/produkte/beratung' element={<Consulting />} />

            {/* BlogPosts */}
            <Route key="blogPosts_english" path="/blog/:post_id" element={<BlogpostLayout />} />
            <Route key="blogPosts_dutch" path="/nl/blog/:post_id" element={<BlogpostLayout />} />
            <Route key="blogPosts_german" path="/de/blog/:post_id" element={<BlogpostLayout />} />
          </Route>

          <Route key="calendar" path="/calendar/:url_website_host" element={<WebsiteCalendarTourist />} />

          {/* protected routes*/}

          <Route element={<RequireAuth allowedRole={"User"} />}>
            <Route path='/dashboard' element={<LayoutDashboard />}>
              <Route path="/dashboard/profile" element={<Dashboard />} />
              <Route path="/dashboard/calendar-settings" element={<CalendarSettings />} />
              <Route path="/dashboard/calendar-view" element={<CalendarViewHost />} />
              <Route path="/dashboard/calendar-cleaning" element={<CalendarCleaning />} />
            </Route>
          </Route>
        </Route>

      </Routes>
    </MantineProvider>
  );
}

export default App;

// Add persisten login !!!
// Dashboard: save the language of the user and adapt the dashboard
