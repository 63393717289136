import React, { useEffect, useState } from 'react';
import { Container, Paper, MultiSelect, Text, Button, Box, SimpleGrid, InputBase, TextInput, NumberInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import {
    createStyles,
} from '@mantine/core';
import { MonthViewHost } from "./Calendar-booking/CalendarMonthViewHost";
import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';

// Calendar generator for HostView
// Takes username and gets calendar data from database
// Displays the Host view on monthly basis
// ToDO: extend to year/host view parameters
const Calendar = () => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const { classes } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '';
    const [selectedRange, setSelectedRange] = useState({ start: null, end: null });
    const [nameInput, setNameInput] = useState('');
    const [minpplInput, setMinpplInput] = useState('');
    const [priceInput, setPriceInput] = useState(50);
    const [pp_priceInput, setpp_priceInput] = useState(5);
    const [priceDatesInput, setPriceDatesInput] = useState('');
    const [pricesfieldInput, setPricesfieldInput] = useState(false);
    const [availability, setAvailability] = useState('');
    const [dateStates, setDateStates] = useState('');
    const { auth } = useAuth(); // get username. Right now hard-coded
    const username = "Default"
    const CALENDAR_URL = '/calendar-example';


    // Fetch calendar (DateStates) using username
    useEffect(() => {
        async function fetch() {
            console.log("Getting calendar of " + username)
            await axios.get(`${CALENDAR_URL}`, {
                params: { username },
            }).then((res) => {

                setDateStates(JSON.parse(res.data.entry.content));

            }).catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        }
        fetch()
    }, [username])

    // Update DateStates based on the Price Inputs
    // Pushes updates to the database
    const handlePriceSaveClick = (selectedRange, priceInput, pp_priceInput, minpplInput, priceDatesInput) => {
        // Create a copy of the current dateStates
        const newDateStates = { ...dateStates };

        // Loop through the selected dates within the range
        for (let currentDate = new Date(selectedRange.start); currentDate <= new Date(selectedRange.end); currentDate.setDate(currentDate.getDate() + 1)) {

            // check if date is in selected dates to apply changes
            const day = currentDate.getDay()
            if (!priceDatesInput.includes(7) && !priceDatesInput.includes(day)) {
                continue;
            }

            // Convert the date to a string in 'YYYY-MM-DD' format
            const dateKey = currentDate.toISOString().slice(0, 10);

            // Check if the dateKey exists in newDateStates
            if (!newDateStates[dateKey]) {

                // Create an empty object if it doesn't exist
                newDateStates[dateKey] = {};
            }

            // Update DateState information
            newDateStates[dateKey].price = priceInput ?? newDateStates[dateKey].price;
            newDateStates[dateKey].pp_price = pp_priceInput ?? newDateStates[dateKey].pp_price;
            newDateStates[dateKey].minppl = minpplInput ?? newDateStates[dateKey].minppl;
        }

        // Push newDateStates to the Database
        const CalendarUpdate = {
            username: username,
            content: newDateStates
        };
        PushCalendar(CalendarUpdate)

        // Update the dateStates state with the new locally
        setDateStates(newDateStates);

        // Reset user input values
        setAvailability('');
        setPriceInput('');
        setNameInput('');
        setpp_priceInput('');
        setMinpplInput('');
        setSelectedRange({ start: null, end: null });
        setPriceDatesInput('');
        setPricesfieldInput(false);
    };

    // Update DateStates based on a availability or category Input
    // Pushes updated to the database
    const handleCategorySaveClick = (selectedRange, nameInput, availabilityInput) => {

        // Create a copy of the current dateStates
        const newDateStates = { ...dateStates };

        // Loop through the selected dates within the range
        for (let currentDate = new Date(selectedRange.start); currentDate <= new Date(selectedRange.end); currentDate.setDate(currentDate.getDate() + 1)) {

            // Convert the date to a string in 'YYYY-MM-DD' format
            const dateKey = currentDate.toISOString().slice(0, 10);

            // Check if the dateKey exists in newDateStates
            if (!newDateStates[dateKey]) {
                newDateStates[dateKey] = {}; // Create an empty object if it doesn't exist
            }

            newDateStates[dateKey].name = nameInput ?? newDateStates[dateKey].name;
            newDateStates[dateKey].availability = availabilityInput ?? newDateStates[dateKey].availability;
        }

        // Update the dateStates state with the new values
        setDateStates(newDateStates);


        // Push changes to the Database
        const CalendarUpdate = {
            username: username,
            content: newDateStates
        };
        PushCalendar(CalendarUpdate)

        // Reset user input values
        setAvailability('');
        setPriceInput('');
        setNameInput('');
        setpp_priceInput('');
        setMinpplInput('');
        setSelectedRange({ start: null, end: null });
        setPriceDatesInput('');
    };

    // Color select light green or reset if null
    const handleSelectStartDateInput = (date) => {
        if (date !== null) {
            setSelectedRange({ start: date, end: selectedRange.end });
            setCurrentDate(date)
        }
        else {
            setSelectedRange({ start: null, end: selectedRange.end });
        }
    };

    // push dateStates to database based on username
    // Can only be called inside a hook!
    // Username entry must exist
    const PushCalendar = (username, dateStates) => {
        const update = async () => {
            if (username) {
                await axios.put(`${CALENDAR_URL}`, {
                    username: username,
                    content: dateStates
                })
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((err) => {
                        console.error(err);
                    })
            }
        }
        update();
    };


    const handleSelectEndDateInput = (date) => {
        if (date !== null) {
            const nextDay = new Date(date.getTime() + (24 * 60 * 60 * 1000));
            setSelectedRange({ start: selectedRange.start, end: nextDay });
        }
        else {
            setSelectedRange({ start: selectedRange.start, end: null });
        }
    };

    const handleCancelInput = () => {
        // Reset user input values
        setAvailability('');
        setPriceInput('');
        setNameInput('');
        setpp_priceInput('');
        setMinpplInput('');
        setSelectedRange({ start: null, end: null });
        setPriceDatesInput('');
        setPricesfieldInput(false);
    };

    const handlePrevMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setCurrentDate(newDate);
    };

    const handleNextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setCurrentDate(newDate);
    };

    return (
        <>
            <Paper className={classes.paper}
                withBorder
                sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                })}>
                <Box p="md">
                    <DatePickerInput className={classes.datepicker}
                        clearable
                        label="From"
                        placeholder="Pick date"
                        description="pick days first, then edit booking details"
                        onChange={(e) => handleSelectStartDateInput(e)}
                        maw={400}
                    />
                </Box>
                <Box p="md">
                    <DatePickerInput className={classes.datepicker}
                        clearable
                        label="To"
                        placeholder="Pick date"
                        description="pick days first, then edit booking details"
                        onChange={(e) => handleSelectEndDateInput(e)}
                        //mx="auto"
                        maw={400}
                    />
                </Box>
            </Paper>
            {selectedRange.start && selectedRange.end && !pricesfieldInput &&
                < Paper className={classes.paper}
                    withBorder
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                    })}>
                    <InputBase label="Select availability type"
                        component="select"
                        mt="md"
                        placeholder="Is the accomodation booked?"
                        onChange={(e) => setAvailability(e.target.value)} s>
                        <option value=""> </option>
                        <option value="booked">Booked</option>
                        <option value="privateBooked">Personal</option>
                        <option value="available">Available</option>
                    </InputBase>
                    <TextInput
                        label="Category"
                        default=""
                        placeholder="Booking.com"
                        description="Set a small personal note for this range of dates"
                        mt="md"
                        classNames={{ input: classes.input, label: classes.inputLabel }}
                        onChange={(e) => setNameInput(e.target.value)}
                    />
                    <Box p="md">
                        <SimpleGrid
                            cols={3}
                            spacing={'md'}
                            verticalSpacing={'md'}
                        >
                            <Button color="resirentPink"
                                onClick={() => handleCategorySaveClick(selectedRange, nameInput, availability)}
                            >
                                {languagePrefix === '/nl' ? "Opslaan" : languagePrefix === '/de' ? "Speichern" : "Save"}
                            </Button>
                            <Button
                                variant="outline"
                                color="resirent"
                                onClick={() => setPricesfieldInput(true)}
                            >
                                {languagePrefix === '/nl' ? "Stel prijzen in" : languagePrefix === '/de' ? "Set prices" : "Set prices"}
                            </Button>
                            <Button
                                variant="filled"
                                color="resirent"
                                onClick={() => handleCancelInput()}
                            >
                                {languagePrefix === '/nl' ? "Cancel" : languagePrefix === '/de' ? "Cancel" : "Cancel"}
                            </Button>
                        </SimpleGrid>
                    </Box>
                </ Paper>
            }
            {selectedRange.start && selectedRange.end && pricesfieldInput &&
                < Paper className={classes.paper}
                    withBorder
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                    })}>
                    <MultiSelect
                        label="Apply prices to days in the selected range"
                        placeholder="Pick days"
                        data={[{ value: 1, label: 'Monday' },
                        { value: 2, label: 'Tuesday' },
                        { value: 3, label: 'Wednesday' },
                        { value: 4, label: 'Thursday' },
                        { value: 5, label: 'Friday' },
                        { value: 6, label: 'Saturday' },
                        { value: 0, label: 'Sunday' },
                        { value: 7, label: 'All' }]}
                        onChange={(e) => setPriceDatesInput(e)}
                        hidePickedOptions
                    />
                    <NumberInput
                        label={
                            languagePrefix === '/nl'
                                ? "Stel prijs in per nacht"
                                : languagePrefix === '/de'
                                    ? "Set base price per night"
                                    : "Set base price per night"
                        }
                        //defaultValue={50}
                        placeholder="Set price"
                        mt="md"
                        onChange={(e) => setPriceInput(e)}
                    />
                    <NumberInput
                        label={
                            languagePrefix === '/nl'
                                ? "Maximum aantal personen voor de basis-prijs"
                                : languagePrefix === '/de'
                                    ? "Set maximum number of people for the base price"
                                    : "Set maximum number of people for the base price"
                        }
                        description="Up to this many people, you only charge the base price"
                        //defaultValue={50}
                        placeholder="Set price"
                        mt="md"
                        onChange={(e) => setMinpplInput(e)}
                    />
                    <NumberInput
                        label={
                            languagePrefix === '/nl'
                                ? "Stel prijs in per extra persoon"
                                : languagePrefix === '/de'
                                    ? "Set price per extra person"
                                    : "Set price per extra person"
                        }
                        //defaultValue={50}
                        description="Price per extra person more than the maximum for the base price"
                        placeholder="Set price"
                        mt="md"
                        onChange={(e) => setpp_priceInput(e)}
                    />
                    <Box p="md">
                        <SimpleGrid
                            cols={2}
                            spacing={'md'}
                            verticalSpacing={'md'}
                        >
                            <Button color="resirentPink"
                                onClick={() => handlePriceSaveClick(selectedRange, priceInput, pp_priceInput, minpplInput, priceDatesInput)}
                            >
                                {languagePrefix === '/nl' ? "Opslaan" : languagePrefix === '/de' ? "Speichern" : "Save prices"}
                            </Button>
                            <Button
                                variant="filled"
                                color="resirent"
                                onClick={() => handleCancelInput()}
                            >
                                {languagePrefix === '/nl' ? "Cancel" : languagePrefix === '/de' ? "Cancel" : "Cancel"}
                            </Button>
                        </SimpleGrid>
                    </Box>
                </Paper >
            }
            <Paper className={classes.paper}
                withBorder
                align="center"
                sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                })}>
                <Container size="xl">
                    <Box p="md">
                        <SimpleGrid cols={3} spacing="md" verticalSpacing="md">
                            <Button variant="outline" color="resirentPink" onClick={handlePrevMonth}>
                                Previous Month
                            </Button>
                            <Text size="xl" weight={500} align="center">
                                {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
                            </Text>
                            <Button variant="outline" color="resirentPink" onClick={handleNextMonth}>
                                Next Month
                            </Button>
                        </SimpleGrid>
                    </Box>
                    <SimpleGrid cols={7} spacing="md" verticalSpacing="md">
                        {MonthViewHost(currentDate, dateStates, selectedRange, setSelectedRange)}
                    </SimpleGrid>
                </Container>
            </Paper>
        </>
    );
};

export default Calendar;

const useStyles = createStyles((theme) => ({
    datepicker: {
        mt: "md"
    },

    paper: {
        m: "md",
        radius: "md",
        align: "center"
    },

    box: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",

        '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        },
    },

    input: {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[4],
        color: theme.black,

        '&::placeholder': {
            color: theme.colors.gray[5],
        },
    },

    inputLabel: {
        color: theme.black,
    },

    control: {
        backgroundColor: theme.colorScheme.resirentPink,
    },
}));