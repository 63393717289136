import React from 'react'
import image from '../../../assets/products/Colors.png';
import { createStyles, rem, Title, Box, Container, Text, Image, Paper, SimpleGrid } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        alignItems: 'center',
        width: '80%',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function MainProductSecond({ language }) {

    const { classes } = useStyles();

    const content = {
        '/': {
            'groupTitle': 'SIMPLE LAYOUT EDITOR',
            'mainTitle': 'Discover the Perfect Authentic Look',
            'description': 'Customize colors and fonts to give your website an authentic and unique appearance.',
        },
        '/nl': {
            'groupTitle': 'EENVOUDIGE LAYOUT-EDITOR',
            'mainTitle': 'Ontdek de Perfecte Authentieke Uitstraling',
            'description': 'Pas kleuren en lettertypen aan om uw website een authentieke en unieke uitstraling te geven.',
        },
        '/de': {
            'groupTitle': 'EINFACHER LAYOUT-EDITOR',
            'mainTitle': 'Entdecken Sie den perfekten authentischen Look',
            'description': 'Passen Sie Farben und Schriftarten an, um Ihrer Website ein authentisches und einzigartiges Aussehen zu verleihen.',
        },
    };

    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box>
            <Container size="lg" pt={100}>
                <SimpleGrid cols={2} breakpoints={[{ maxWidth: 768, cols: 1 }, { maxWidth: 1200, cols: 2 }]} spacing={10}>

                    <Paper shadow="xl" pb="md" pt="md" radius="xl" withBorder className={classes.paperProduct} >
                        <Image src={image} alt="Website Editing" />
                    </Paper>

                    <Box>
                        <Title order={4} weight={300} className={classes.title} color="resirentPink">
                            {content[languagePrefix].groupTitle}
                        </Title>

                        <Title order={2} className={classes.title} my="sm" display={'block'}>
                            {content[languagePrefix].mainTitle}
                        </Title>

                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].description}
                        </Text>
                    </Box>

                </SimpleGrid>
            </Container>

        </Box >
    )
}
