import blogTax from "../assets/blogPosts/tax/tax.jpg"
import hausclarysse from "../assets/blogPosts/Recycling/AustriaLinkedinResiRent.jpg"
import thecityghent from "../assets/blogPosts/OurStory/living_room.jpg"
import Anja from "../assets/team/Anja_profile.jpeg"
import Conny from "../assets/hosts/Conny.jpeg"
import ConnyHuis from "../assets/hosts/ConnyHuis.jpeg"
import Annemie from "../assets/hosts/Annemie.jpg"
import CaseRosalie from "../assets/hosts/CasaRosalie.jpg"
import Darline from "../assets/hosts/darline.jpg"
import zilverdauw from "../assets/hosts/zilverdauw.jpg"



// ============= Host showcases ============= //

export const hostsEn = [
    {
        id: 1,
        place: 'Ghent, Belgium',
        accommodationName: 'The City Ghent',
        websiteUrl: 'https://thecityghent.com/home',
        image: thecityghent,
        description:
            'The City Ghent is an apartment located in the heart of Ghent. The apartment is recognized by Tourism Flanders and follows responsible hosting principles, including the use of an official cleaning service, VAT accounting, energy efficiency, and waste management.',
        host: {
            name: 'Anja Bulteel',
            job: 'Founder ResiRent',
            imageUrl: Anja,
            description: "Meet Anja, the host of The City Ghent. Although she and her family moved to Switzerland back in 2016, Anja's heart remains firmly rooted in Ghent, and she makes frequent returns to her beloved city. With her deep affection for Ghent and its charming spots, she shares her favorite gems in the 'Suggestion' section of her website. What's more, she's also the co-founder of ResiRent, adding an extra touch of sustainability to her hosting practices.",
        },
    },
    {
        id: 2,
        place: 'Schladming, Austria',
        accommodationName: 'Bergzauber',
        websiteUrl: 'https://bergzauber.eu/home',
        image: ConnyHuis,
        description:
            'The apartment where you can stay with 4 people overlooks the mountains: an enchanting view! Everything is brand new and very comfortably furnished. The terrace invites you to linger while gazing at the mountains.',
        host: {
            name: 'Conny van Barneveld',
            job: 'Founder ResiRent',
            imageUrl: Conny,
            description: "We got to know Conny when she and her family were building in our neighborhood. Conny has been living in Austria since 2005 and shares her home with her sons and husband. She is now half Dutch, half Austrian. She works as a physiotherapist in various hotels. Just like everyone in the neighborhood, she rents out a part of her house as a holiday apartment. She welcomes her guests with incredible hospitality and knows the region inside and out. Meanwhile, they have also furnished the upper floor and are now renting it out as a holiday apartment.",
        },
    },
    {
        id: 3,
        place: 'Schladming, Austria',
        accommodationName: 'Haus Clarysse',
        websiteUrl: 'https://hausclarysse.com',
        image: hausclarysse,
        description:
            'Relax and find tranquility at Haus Clarysse, situated on a plateau where the sun always seems to shine, with stunning mountain views. Take a look at the photos on our website, let yourself be convinced, and consider that many guests have already written that the house and the surroundings are even better than the pictures can show.',
        category: { title: 'Good Management', href: '#' },
        host: {
            name: 'Anja Bulteel',
            job: 'Founder ResiRent',
            imageUrl: Anja,
            description: 'In 2013, we built our vacation home in Schladming. We fell in love with this area and made many friends in the region. Our house has been constructed in a sustainable manner and offers an enchanting view of the mountains. For those who love the outdoors, this is a place where one can find joy in every season. That is why, when we are not staying there ourselves, we rent it out as a vacation home. If you are planning to come to Schladming with more than 10 people, just send us an email. We know our neighbors, who also rent accommodations, so we will surely find a place to sleep for everyone!',
        },
    }, 
    {
        id: 4,
        place: 'Lokeren, Belgium',
        accommodationName: 'Casa Rosalie/Mie',
        websiteUrl: 'https://casa-rosalie.com',
        image: CaseRosalie,
        description:
            'Welcome to Casa Mie and Casa Rosalie, two charming holiday homes located side by side in Lokeren. Ideal for families, groups of friends, and expats looking for a comfortable and cozy accommodation during their stay in the region.',
        category: { title: 'Good Management', href: '#' },
        host: {
            name: 'Annemie',
            imageUrl: Annemie,
            description: 'Hello, I am Annemie, 48 years old, and with great pride, I am the owner of Casa Rosalie and Casa Mie. These cozy accommodations have emerged from a renovated farmhouse where my father spent his youth. With much love, he completely transformed his childhood home. So, what are you waiting for? Pack your bags, leave your worries behind, and come to Casa Rosalie or Casa Mie!',
        },
    },
    {
        id: 5,
        place: 'Mol, Belgium',
        accommodationName: 'Zilverdauw',
        websiteUrl: 'https://zilverdauw.com',
        image: zilverdauw,
        description:
            'Looking to relax with family, relatives, or friends? Ever wanted to cycle through the Kempen or play around with the kids by the water? Dreaming of long walks with your dog in the beautiful countryside? Then Zilverdauw is definitely for you! Check out this cozy vacation home for 6 people by the water. Plan plenty of activities at the recreation park and the beautiful spacious surroundings. Look forward to spending time together, creating beautiful memories, and above all... enjoying yourselves!',
        category: { title: 'Good Management', href: '#' },
            host: {
            name: 'Darline',
            imageUrl: Darline,
            description: "Welcome, we are Gerda and Maurits, and together with our 4 teenagers and our four-legged friend Ragnar, we want to give others the chance to enjoy a stay by the water at Zilverdauw. Upon arrival at Zilverdauw, we instantly get that 'holiday feeling' and immediately start living in slow motion. Taking a moment to unwind can be so refreshing;) Would you like to experience this too? We warmly invite you to discover the Kempen with family or friends and create the memories you'll cherish later. With love, Gerda and Maurits.",
        },
    },
    // More posts...
]

export const hostsNL = [
    {

        id: 1,
        place: 'Gent, België',
        accommodationName: 'The City Ghent',
        websiteUrl: 'https://thecityghent.com/home',
        image: thecityghent,
        description:
            'The City Ghent is een appartement gelegen in het hart van Gent. Het appartement wordt erkend door Toerisme Vlaanderen en volgt verantwoorde gastvrijheidsprincipes, waaronder het gebruik van een officiële schoonmaakdienst, BTW-administratie, energie-efficiëntie en afvalbeheer.',
        host: {
            name: 'Anja',
            job: 'Founder ResiRent',
            imageUrl: Anja,
            description: "Maak kennis met Anja, de gastvrouw van The City Ghent. Hoewel zij en haar familie in 2016 naar Zwitserland verhuisden, blijft Anja's hart stevig verankerd in Gent, en ze keert regelmatig terug naar haar geliefde stad. Met haar diepe genegenheid voor Gent en zijn charmante plekjes deelt ze haar favoriete pareltjes in de 'Suggestie' sectie van haar website. Bovendien is ze ook mede-oprichter van ResiRent, wat een extra duurzaam tintje toevoegt aan haar gastvrijheidspraktijken.",
        },
    },

    {

        id: 2,
        place: 'Schladming, Oostenrijk',
        accommodationName: 'Bergzauber',
        websiteUrl: 'https://bergzauber.eu/home',
        image: ConnyHuis,
        description:
            'Het appartement waar je met 4 personen kunt verblijven kijkt uit op de bergen: een betoverend uitzicht! Alles is gloednieuw en zeer gezellig ingericht. Het terras nodigt uit tot lang verblijven met uitzicht op de bergen.',
        host: {
            name: 'Conny van Barneveld',
            job: 'Founder ResiRent',
            imageUrl: Conny,
            description: "We hebben Conny leren kennen toen ze met haar gezin aan het bouwen was in onze buurt. Conny woont sinds 2005 in Oostenrijk en deelt haar huis met haar zonen en echtgenoot. Ze is inmiddels half Nederlands, half Oostenrijks. Ze werkt als fysiotherapeute in verschillende hotels. Net als iedereen in de buurt verhuurt ze een deel van haar huis als vakantieappartement. Ze ontvangt haar gasten met ongelooflijke gastvrijheid en kent de regio van binnen en van buiten. Ondertussen hebben ze ook de bovenverdieping ingericht en verhuren ze die nu ook als vakantieappartement.",
        },
    },
    {

        id: 3,
        place: 'Schladming, Oostenrijk',
        accommodationName: 'Haus Clarysse',
        websiteUrl: 'https://hausclarysse.com',
        image: hausclarysse,
        description:
            "Ontspan en vind rust in Haus Clarysse, gelegen op een plateau waar de zon altijd lijkt te schijnen, met prachtig uitzicht op de bergen. Bekijk de foto's op onze website, laat u overtuigen en bedenk dat veel gasten reeds schreven, dat het huis en de omgeving nog beter zijn dan de foto's kunnen tonen. Geniet met je familie, vrienden (maximaal 10 personen) van een wondermooi plek in de bergen.",
        host: {
            name: 'Anja Bulteel',
            job: 'Founder ResiRent',
            imageUrl: Anja,
            description:
                "In 2013 hebben wij onze vakantiewoning in Schladming gebouwd. We werden verliefd op dit gebied en hebben vele vrienden in de regio gemaakt. Ons huis is op een duurzame wijze opgetrokken en biedt een betoverend uitzicht op de bergen. Voor iedereen die van het buitenleven houdt, is dit een plek waar men in elk seizoen zijn hart kan ophalen. Omdat we er niet altijd zijn, verhuren we het als vakantiewoning. Mocht u met meer dan 10 personen naar Schladming willen komen, stuur ons gewoon een e-mail. We kennen de buren, die ook accommodaties verhuren, dus we zullen zeker een slaapplek vinden voor iedereen!",
        },
    },
    {
        id: 4,
        place: 'Lokeren, België',
        accommodationName: 'Casa Rosalie/Mie',
        websiteUrl: 'https://casa-rosalie.com',
        image: CaseRosalie,
        description:
            'Welkom bij Casa Mie en Casa Rosalie, twee charmante vakantiehuizen naast elkaar gelegen in Lokeren. Ideaal voor gezinnen, vriendengroepen en expats die tijdens hun verblijf in de regio op zoek zijn naar een comfortabele en gezellige accommodatie.',
        category: { title: 'Goed Management', href: '#' },
        host: {
            name: 'Annemie',
            imageUrl: Annemie,
            description: 'Hallo, ik ben Annemie, 48 jaar oud, en met grote trots ben ik de eigenaar van Casa Rosalie en Casa Mie. Deze gezellige accommodaties zijn voortgekomen uit een gerenoveerde boerderij waar mijn vader zijn jeugd heeft doorgebracht. Met veel liefde heeft hij zijn ouderlijk huis volledig verbouwd. Dus waar wacht je nog op? Pak je koffers, laat je zorgen achter en kom naar Casa Rosalie of Casa Mie!',
        },
    },
    {
        id: 5,
        place: 'Mol, België',
        accommodationName: 'Zilverdauw',
        websiteUrl: 'https://zilverdauw.com',
        image: zilverdauw,
        description:
            'Op zoek naar ontspanning met familie, familieleden of vrienden? Ooit willen fietsen door de Kempen of spelen met de kinderen bij het water? Dromen van lange wandelingen met je hond in het prachtige landschap? Dan is Zilverdauw zeker iets voor jou! Bekijk dit gezellige vakantiehuis voor 6 personen aan het water. Plan tal van activiteiten in het recreatiepark en de prachtige ruime omgeving. Kijk uit naar quality time samen, het creëren van mooie herinneringen en vooral... genieten!',
        category: { title: 'Goed Management', href: '#' },
        host: {
            name: 'Darline',
            imageUrl: Darline,
            description: "Welkom, wij zijn Gerda en Maurits, en samen met onze 4 tieners en onze viervoeter Ragnar willen we anderen de kans geven om te genieten van een verblijf aan het water bij Zilverdauw. Bij aankomst bij Zilverdauw krijgen we meteen dat 'vakantiegevoel' en beginnen we meteen in slow motion te leven. Een momentje tot rust komen kan zo verfrissend zijn ;) Wil je dit ook ervaren? We nodigen je van harte uit om de Kempen te ontdekken met familie of vrienden en de herinneringen te creëren die je later koestert. Met liefde, Gerda en Maurits.",
        },
    },
    // More posts...
]

export const hostsDE = [
    {
        id: 1,
        place: 'Gent, Belgien',
        accommodationName: 'The City Ghent',
        websiteUrl: 'https://thecityghent.com/home',
        image: thecityghent,
        description:
            'The City Ghent ist ein Apartment im Herzen von Gent. Das Apartment wird von Tourismus Flandern anerkannt und folgt verantwortungsvollen Gastfreundschaftsprinzipien, einschließlich der Verwendung eines offiziellen Reinigungsdienstes, Mehrwertsteuer-Verwaltung, Energieeffizienz und Abfallmanagement.',
        host: {
            name: 'Anja',
            job: 'Gründerin ResiRent',
            imageUrl: Anja,
            description: "Treffen Sie Anja, die Gastgeberin von The City Ghent. Obwohl sie und ihre Familie 2016 in die Schweiz umzogen, bleibt Anjas Herz fest in Gent verankert, und sie kehrt regelmäßig in ihre geliebte Stadt zurück. Mit ihrer tiefen Zuneigung für Gent und seine charmanten Orte teilt sie ihre Lieblingsgeheimtipps im 'Vorschlag'-Bereich ihrer Webseite. Darüber hinaus ist sie auch Mitbegründerin von ResiRent, was ihren Gastfreundschaftspraktiken eine zusätzliche nachhaltige Note verleiht.",
        },
    },
    {
        id: 2,
        place: 'Schladming, Österreich',
        accommodationName: 'Bergzauber',
        websiteUrl: 'https://bergzauber.eu/home',
        image: ConnyHuis,
        description:
            'Das Apartment, in dem 4 Personen übernachten können, bietet einen Blick auf die Berge: eine verzaubernde Aussicht! Alles ist brandneu und sehr gemütlich eingerichtet. Die Terrasse lädt zum langen Verweilen mit Blick auf die Berge ein.',
        host: {
            name: 'Conny van Barneveld',
            job: 'Gründerin ResiRent',
            imageUrl: Conny,
            description: "Wir haben Conny kennengelernt, als sie mit ihrer Familie in unserer Nachbarschaft gebaut hat. Conny lebt seit 2005 in Österreich und teilt ihr Haus mit ihren Söhnen und ihrem Ehemann. Sie ist mittlerweile halb Niederländerin, halb Österreicherin. Sie arbeitet als Physiotherapeutin in verschiedenen Hotels. Wie jeder in der Nachbarschaft vermietet sie einen Teil ihres Hauses als Ferienwohnung. Sie empfängt ihre Gäste mit unglaublicher Gastfreundschaft und kennt die Region in- und auswendig. Inzwischen haben sie auch das Obergeschoss eingerichtet und vermieten es nun ebenfalls als Ferienwohnung.",
        },
    },
    {
        id: 3,
        place: 'Schladming, Österreich',
        accommodationName: 'Haus Clarysse',
        websiteUrl: 'https://hausclarysse.com',
        image: hausclarysse,
        description:
            "Entspannen Sie sich und finden Sie Ruhe im Haus Clarysse, gelegen auf einem Plateau, auf dem die Sonne immer zu scheinen scheint, mit einem herrlichen Blick auf die Berge. Schauen Sie sich die Fotos auf unserer Webseite an, lassen Sie sich überzeugen und bedenken Sie, dass viele Gäste bereits geschrieben haben, dass das Haus und die Umgebung noch besser sind als die Fotos zeigen können. Genießen Sie mit Ihrer Familie, Ihren Freunden (maximal 10 Personen) einen wunderbaren Ort in den Bergen.",
        host: {
            name: 'Anja',
            job: 'Gründerin ResiRent',
            imageUrl: Anja,
            description: "Treffen Sie Anja, die Gastgeberin von The City Ghent. Obwohl sie und ihre Familie 2016 in die Schweiz umzogen, bleibt Anjas Herz fest in Gent verankert, und sie kehrt regelmäßig in ihre geliebte Stadt zurück. Mit ihrer tiefen Zuneigung für Gent und seine charmanten Orte teilt sie ihre Lieblingsgeheimtipps im 'Vorschlag'-Bereich ihrer Webseite. Darüber hinaus ist sie auch Mitbegründerin von ResiRent, was ihren Gastfreundschaftspraktiken eine zusätzliche nachhaltige Note verleiht.",
        },

    },
    {
        id: 4,
        place: 'Lokeren, Belgien',
        accommodationName: 'Casa Rosalie/Mie',
        websiteUrl: 'https://casa-rosalie.com',
        image: CaseRosalie,
        description:
            'Willkommen in Casa Mie und Casa Rosalie, zwei charmanten Ferienhäusern, die nebeneinander in Lokeren liegen. Ideal für Familien, Freundesgruppen und Expats, die während ihres Aufenthalts in der Region eine komfortable und gemütliche Unterkunft suchen.',
        category: { title: 'Gutes Management', href: '#' },
        host: {
            name: 'Annemie',
            imageUrl: Annemie,
            description: 'Hallo, ich bin Annemie, 48 Jahre alt und mit großem Stolz bin ich die Besitzerin von Casa Rosalie und Casa Mie. Diese gemütlichen Unterkünfte sind aus einem renovierten Bauernhaus entstanden, in dem mein Vater seine Jugend verbracht hat. Mit viel Liebe hat er sein Elternhaus komplett umgestaltet. Also, worauf warten Sie noch? Packen Sie Ihre Koffer, lassen Sie Ihre Sorgen hinter sich und kommen Sie nach Casa Rosalie oder Casa Mie!',
        },
    },
    {
        id: 5,
        place: 'Mol, Belgien',
        accommodationName: 'Zilverdauw',
        websiteUrl: 'https://zilverdauw.com',
        image: zilverdauw,
        description:
            'Auf der Suche nach Entspannung mit Familie, Verwandten oder Freunden? Wollten Sie schon immer durch die Kempen radeln oder mit den Kindern am Wasser spielen? Träumen Sie von langen Spaziergängen mit Ihrem Hund in der schönen Landschaft? Dann ist Zilverdauw definitiv das Richtige für Sie! Schauen Sie sich dieses gemütliche Ferienhaus für 6 Personen am Wasser an. Planen Sie zahlreiche Aktivitäten im Erholungspark und der wunderschönen, großzügigen Umgebung. Freuen Sie sich darauf, Zeit miteinander zu verbringen, schöne Erinnerungen zu schaffen und vor allem... zu genießen!',
        category: { title: 'Gutes Management', href: '#' },
        host: {
            name: 'Darline',
            imageUrl: Darline,
            description: "Willkommen, wir sind Gerda und Maurits, und zusammen mit unseren 4 Teenagern und unserem Vierbeiner Ragnar möchten wir anderen die Möglichkeit geben, einen Aufenthalt am Wasser bei Zilverdauw zu genießen. Bei der Ankunft bei Zilverdauw bekommen wir sofort dieses 'Urlaubsgefühl' und beginnen sofort in Zeitlupe zu leben. Ein Moment zum Entspannen kann so erfrischend sein ;) Möchten Sie das auch erleben? Wir laden Sie herzlich ein, die Kempen mit Familie oder Freunden zu entdecken und die Erinnerungen zu schaffen, die Sie später schätzen werden. Mit Liebe, Gerda und Maurits.",
        },
    },
    // More posts...
]