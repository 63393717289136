import React, { useState } from 'react';
import { Modal, Button, Group, Text } from '@mantine/core';
import { closeModal } from '@mantine/modals';

const ClosedModal = ({ isOpen, onClose, onAccept, onDecline, bookingInfo }) => {
    const [showDeclineConfirmation, setShowDeclineConfirmation] = useState(false);

    if (!bookingInfo) return null;

    const handleDeclineClick = () => {
        setShowDeclineConfirmation(true);
    };

    const handleConfirmDecline = () => {
        setShowDeclineConfirmation(false);
        onDecline();
    };

    const handleCancelDecline = () => {
        setShowDeclineConfirmation(false);
    };

    return (
        <>
            <Modal
                opened={isOpen}
                onClose={onClose}
                title="Booking Confirmation"
            >
                <Text>Do you want to change the closed setting? </Text>
                <Text size="sm">Start date: {bookingInfo.start}</Text>
                <Text size="sm">End date: {bookingInfo.end}</Text>
                <Text size="sm">Label: {bookingInfo.label}</Text>
                <Text size="sm">Status: {bookingInfo.booked}</Text>
                <Group position="center" mt="md">
                    <Button onClick={onClose}>No</Button>
                    <Button color="red" onClick={handleDeclineClick}>Yes</Button>
                </Group>

            </Modal>

            <Modal
                opened={showDeclineConfirmation}
                onClose={handleCancelDecline}
                title="Confirm Decline"
            >
                <Text>Do you really want to make it available again?</Text>
                <Group position="center" mt="md">
                    <Button color="red" onClick={handleConfirmDecline}>
                        Yes, remove the closed setting
                    </Button>
                    <Button variant="default" onClick={handleCancelDecline}>
                        No, Keep the closed setting
                    </Button>
                </Group>
            </Modal>
        </>
    );
};

export default ClosedModal;
