import {
    TextInput,
    PasswordInput,
    Checkbox,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
    Image,
    Box,
} from '@mantine/core';
import logo from '../../assets/Long_logo_dark.svg';
import { useEffect, useState, useRef } from 'react';
import axios from '../../api/axios.js'
import useAuth from '../../hooks/useAuth';

const USER_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const RESET_URL = '/password';

export default function ForgotPassword() {

    const { persist, setPersist } = useAuth();

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [validMatchPwd, setValidMatchPwd] = useState(false);

    useEffect(() => {
        // Check if password meets complexity criteria
        const hasValidLength = matchPwd.length >= 8;
        const hasUppercase = /[A-Z]/.test(matchPwd);
        const hasSpecialChar = /[\W_]/.test(matchPwd);
        const hasNumber = /\d/.test(matchPwd);

        // Set validMatchPwd based on complexity criteria
        setValidMatchPwd(hasValidLength && hasUppercase && hasSpecialChar && hasNumber);
    }, [matchPwd]);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    // useEffect(() => {
    //   userRef.current.focus();
    // }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    const togglePersist = () => {
        setPersist(prevState => !prevState);
    }

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const v1 = USER_REGEX.test(user);
        if (!v1) {
            setErrMsg("Invalid Entry");
            return;
        }
        const response = await axios.post(RESET_URL,
            JSON.stringify({ user }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        console.log(response.data)
        console.log(JSON.stringify(response));
        setSuccess(true);
    }

    return (
        <>
            {success ? (
                <Container size={420} my={40}>
                    <Title ta="center">
                        Request successful! We have sent the password reset link to your Email address.
                    </Title>
                    <Text c="dimmed" size="sm" ta="center" mt={5}>
                        Go to the login page{' '}
                        <Anchor size="sm" component="a" href="/login">
                            Login
                        </Anchor>
                    </Text>
                </Container>
            ) : (
                <Container size={420} my={40}>
                    {/* <Image width={100} height={100} src={logo} align="center" alt="ResiRent logo" /> */}
                    <Title ta="center">
                        Password Reset
                    </Title>
                    <Text c="dimmed" size="sm" ta="center" mt={5}>
                        Back to{' '}
                        <Anchor size="sm" component="a" href="/login">
                            Login
                        </Anchor>
                    </Text>

                    <form onSubmit={handleSubmit}>
                        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                            <TextInput
                                label="Email"
                                placeholder="name@name.com"
                                required
                                value={user}
                                onChange={(e) => setUser(e.target.value)} />


                            <Button type="submit" variant="outline" color="resirent" fullWidth mt="lg" disabled={!validName ? true : false}>
                                Request Password Change
                            </Button>

                        </Paper>

                    </form>
                </Container >
            )}
        </>
    );
}