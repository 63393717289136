import { createStyles, Text, Container, ActionIcon, Group, rem, Image } from '@mantine/core';
import React from 'react'
import logo from '../../assets/Long_logo_light@4x.png';
import { enFooter, nlFooter, deFooter, socialMediaLinks } from '../../languages/translations';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({

    footer: {
        color: 'white',
        backgroundColor: theme.fn.variant({ variant: 'filled', color: 'resirent' }).background,
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white
            }`,
    },

    logo: {
        maxWidth: rem(200),

        [theme.fn.smallerThan('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    description: {
        marginTop: rem(5),

        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xs,
            textAlign: 'center',
        },
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    groups: {
        display: 'flex',
        flexWrap: 'wrap',

        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    wrapper: {
        width: rem(160),
    },

    link: {
        display: 'block',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.white,
        fontSize: theme.fontSizes.sm,
        paddingTop: rem(3),
        paddingBottom: rem(3),

        '&:hover': {
            textDecoration: 'underline',
        },
    },

    title: {
        fontSize: theme.fontSizes.lg,
        fontWeight: 700,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        marginBottom: `calc(${theme.spacing.xs} / 2)`,
        color: theme.colorScheme === 'dark' ? theme.white : theme.white,
    },

    afterFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing.xl,
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.white
            }`,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    social: {
        color: theme.white,
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xs,
        },
    },
}));

const Footer = () => {

    const { classes } = useStyles();
    const { i18n } = useTranslation();
    const language = i18n.language;

    const data = language === "en" ? enFooter : language === "nl" ? nlFooter : deFooter;

    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Text
                key={index}
                className={classes.link}
                component="a"
                href={link.link}
            >
                {link.label}
            </Text>
        ));

        return (
            <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    });

    const icons = socialMediaLinks.map((item) => (
        <ActionIcon size="xl" component="a" href={item.href} variant="outline" key={item.title}>
            <item.Icon size="1.4rem" stroke={1.5} color="white" />
        </ActionIcon>
    ));

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <Image width={100} height={30} src={logo} alt="ResiRent logo" />
                    <Text size="xs" color="white" className={classes.description}>
                        Conscious hosting for thriving tourism
                    </Text>
                </div>
                <div className={classes.groups}>{groups}</div>
            </Container>

            <Container className={classes.afterFooter}>
                <Text color="white" size="sm">
                    © 2023 ResiRent. All rights reserved.
                </Text>

                {/* <Group spacing={0} className={classes.social} position="right" noWrap>
                    {icons}
                </Group> */}
            </Container>
        </footer>
    );
}

export default Footer
