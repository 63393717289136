import {
    createStyles,
    Image,
    Center,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    rem,
    Box,
    SimpleGrid,
} from '@mantine/core';
import image from '../../assets/homepage/image.png';

const translations = {
    '/': {
        resiWebsiteTitle: 'Use ResiWeb to create your own website with the same ease as any standard booking platform, complete with your unique domain name. Discover features like Google Maps integration, activity suggestions, FAQs, and more.',
        resiWebsiteDescription: 'A simple tool to build a professional website for your STR (Short Term Rental) property.',
        resiWebsiteListItem1: 'As convenient as standard booking platforms that do not require more skills than you need to be listed on a standard booking platform. But this is your own website without any transactional commission fees to be paid.',
        resiWebsiteListItem2: 'The general template of the website is customized for small accommodations. We have used more than 10 years of rental experience to develop the template. We make use of sustainable principles such as green hosting and take community embeddedness as an overarching principle.'
    },
    '/de': {
        resiWebsiteTitle: "Verwenden Sie ResiWeb, um Ihre eigene Website genauso einfach wie bei jeder Standard-Buchungsplattform mit Ihrem einzigartigen Domainnamen zu erstellen. Mit Funktionen wie Google Maps, Aktivitätsempfehlungen, FAQs und vielem mehr.",
        resiWebsiteDescription: 'Ein einfacher Weg, Ihre eigene Website für Ihre Unterkunft zu erstellen.',
        resiWebsiteListItem1: 'So bequem wie herkömmliche Buchungsplattformen, die nicht mehr Fähigkeiten erfordern als Sie benötigen, um auf einer herkömmlichen Buchungsplattform gelistet zu sein. Aber dies ist Ihre eigene Website ohne Transaktionsgebühren, die gezahlt werden müssen.',
        resiWebsiteListItem2: 'Die allgemeine Vorlage der Website ist für kleine Unterkünfte maßgeschneidert. Wir haben mehr als 10 Jahre Erfahrung im Bereich Vermietung genutzt, um die Vorlage zu entwickeln. Wir setzen nachhaltige Prinzipien wie grünes Hosting ein und nehmen die Einbettung in die Gemeinschaft als übergreifendes Prinzip.'
    },
    '/nl': {
        resiWebsiteTitle: 'Gebruik ResiWeb om jouw eigen website te creëren met hetzelfde gemak als bij elk standaard boekingsplatform, compleet met jouw unieke domeinnaam. Ontdek functies zoals Google Maps-integratie, activiteitssuggesties, veelgestelde vragen en nog veel meer.',
        resiWebsiteDescription: 'Een eenvoudige tool om een professionele website te bouwen voor jouw vakantiewoning.',
        resiWebsiteListItem1: 'Net zo handig als standaard boekingsplatforms die niet meer vaardigheden vereisen dan u nodig heeft om op een standaard boekingsplatform vermeld te worden. Maar dit is uw eigen website zonder transactiekosten die betaald moeten worden.',
        resiWebsiteListItem2: 'Het algemene sjabloon van de website is aangepast voor kleine accommodaties. We hebben meer dan 10 jaar verhuurervaring gebruikt om het sjabloon te ontwikkelen. We maken gebruik van duurzame principes zoals groene hosting en beschouwen betrokkenheid bij de gemeenschap als een overkoepelend principe.'
    }
};


const useStyles = createStyles((theme) => ({
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 4)`,
        paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    },
    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
        marginLeft: rem(20),
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        alignItems: 'center',
        width: '80%',
    },

    content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,

        [theme.fn.smallerThan('md')]: {
            maxWidth: '100%',
            marginRight: 0,
        },
    },

    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '100%',
    },

    highlight: {
        position: 'relative',
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
    },

    
}));

const feature = {
    href: {
        '/': '/products/resirent-website',
        '/nl': '/nl/producten/resirent-website',
        '/de': '/de/produkte/resirent-website',
    },
    buttonText: {
        '/': 'Learn more',
        '/nl': 'Lees meer',
        '/de': 'Erfahren Sie mehr',
    },
    href2: {
        '/': '/contact',
        '/nl': '/nl/contact',
        '/de': '/de/kontakt',
    },
    buttonText2: {
        '/': 'Contact us',
        '/nl': 'Neem contact op',
        '/de': 'Kontaktiere uns',
    }
}

// Hero section with a video to showcase the product

export default function Hero() {
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const { classes } = useStyles();
    return (

        <Container size="lg" py={100}>
            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 1 }]}>
                {/* Left side with text */}
                <Box>
                <Title order={1} mt="md" className={classes.title}>
                        {translations[languagePrefix].resiWebsiteDescription}
                    </Title>
                    <Text order={4} mt="md" className={classes.title} >
                        {translations[languagePrefix].resiWebsiteTitle}
                    </Text>

                    <Box mt="md">

                    <Button align="center" mb="md" radius="xl" component="a" href={feature.href[languagePrefix]} mt="md" size="md" className={classes.control}>
                {feature.buttonText[languagePrefix]}
            </Button>
            <Button align="center" mb="md" radius="xl" component="a" href={feature.href2[languagePrefix]} mt="ld" size="md" className={classes.controlSecond}>
                {feature.buttonText2[languagePrefix]}
            </Button>
                    </Box>
                    {/* Add more text or components here as needed */}
                </Box>
                {/* Right side with video when you click on it, it opens youtube */}
                <Center>
    <a href="https://www.youtube.com/embed/cWx7FPUVlOw?&autoplay=1" style={{ display: 'flex', justifyContent: 'center' }}>
        <Image src={image} alt="ResiWeb"  radius="lg" style={{ width: '80%', height: 'auto' }} />
    </a>
</Center>


                
                {/* <Box>
                    <iframe
                        src="https://www.youtube.com/embed/cWx7FPUVlOw?&autoplay=1"
                        title="the developer typing game"
                        width="554"
                        height="416"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                </Box> */}
            </SimpleGrid>
        </Container>


    );
}