// ================= Imports ================== //

import {
    IconCalendar,
    IconWebhook,
    IconBook,
    IconBrandLinkedin,
    IconBrandFacebook,
    IconBrandInstagram,
    IconHomePlus,
} from '@tabler/icons-react';


// ================= Translations for the website ================== //

export const languagesList = [
    { name: 'English', href: '/', short: 'en' },
    { name: 'Nederlands', href: 'nl/', short: 'nl' },
    { name: 'Duits', href: 'de/', short: 'de' },
]

// Navigatiebalk
export const nlNavItems = {
    home: {
        title: "Home",
        ref: "/nl"
    },
    products: {
        title: "Producten",
        ref: "/nl/producten/resirent-website",
        subtitle: "Leer meer",
        subsubtitle: "Ontwikkel je eigen identiteit op de markt met een eenvoudige websitebouwer",
    },
    blog: {
        title: "Blog",
        ref: "/nl/blog",
    },
    hosts: {
        title: "Portfolio",
        ref: "/nl/gastgevers",
    },
    pricing: {
        title: "Prijzen",
        ref: "/nl/prijzen",
    },
    login: {
        title: "Aanmelden",
        ref: "/coming-soon",
    },
    register: {
        title: "Registreer",
        ref: "/coming-soon",
    },
    team: {
        title: "Team",
        ref: "/nl/team",
    },
    philosophy: {
        title: "Filosofie",
        ref: "/nl/filosofie",
    },
    stickers: {
        title: "Stickers",
        ref: "/nl/producten/resirent-stickers",
    },
    partner: {
        title: "Partners",
        ref: "/nl/partners",
    },
    dashboard: {
        ref: "/dashboard"
    },
    consulting: {
        title: "Advies",
        ref: "/nl/producten/advies",
    },
    formRegister: {
        title: "FormRegister",
        ref: "/nl/resiweb/aanmelden",
    },
};

// Navbar
export const enNavItems = {
    home: {
        title: "Home",
        ref: "/"
    },
    products: {
        title: "Products",
        ref: "/products/resirent-website",
        subtitle: "Get started",
        subsubtitle: "Develop your own identity in the marketplace with an easy to use website builder",
    },
    blog: {
        title: "Blog",
        ref: "/blog",
    },
    hosts: {
        title: "Portfolio",
        ref: "/hosts",
    },
    pricing: {
        title: "Prices",
        ref: "/prices",
    },
    login: {
        title: "Login",
        ref: "/login",
    },
    register: {
        title: "Register",
        ref: "/coming-soon",
    },
    team: {
        title: "Team",
        ref: "/team",
    },
    philosophy: {
        title: "Team",
        ref: "/philosophy",
    },
    stickers: {
        title: "Stickers",
        ref: "/products/resirent-stickers",
    },
    partner: {
        title: "Partners",
        ref: "/partners",
    },
    dashboard: {
        ref: "/dashboard"
    },
    consulting: {
        title: "Consulting",
        ref: "/products/consulting",
    },
    formRegister: {
        title: "FormRegister",
        ref: "/resiweb/signup",
    },
}

// Navbar Duits
export const deNavItems = {
    home: {
        title: "Startseite",
        ref: "/de"
    },
    products: {
        title: "Produkte",
        ref: "/de/produkte/resirent-website",
        subtitle: "Loslegen",
        subsubtitle: "Entwickeln Sie Ihre eigene Identität auf dem Markt mit einem benutzerfreundlichen Website-Builder",
    },
    blog: {
        title: "Blog",
        ref: "/de/blog",
    },
    hosts: {
        title: "Portfolio",
        ref: "/de/gastgeber",
    },
    pricing: {
        title: "Preise",
        ref: "/de/preise",
    },
    login: {
        title: "Anmelden",
        ref: "/login",
    },
    register: {
        title: "Registrieren",
        ref: "/de/registrieren",
    },
    team: {
        title: "Team",
        ref: "/de/team",
    },
    philosophy: {
        title: "Philosophie",
        ref: "/de/philosophie",
    },
    stickers: {
        title: "Aufkleber",
        ref: "/de/produkte/resirent-stickers",
    },
    partner: {
        title: "Partner",
        ref: "/de/partner",
    },
    dashboard: {
        ref: "/dashboard"
    },
    consulting: {
        title: "Beratung",
        ref: "/de/produkte/beratung",
    },
    formRegister: {
        title: "FormRegister",
        ref: "/de/resiweb/anmeldung",
    },
}

export const nlProducts = [
    {
        icon: IconWebhook,
        title: 'Website',
        description: 'Heb een eigen, authentieke website',
        ref: "/nl/producten/resirent-website",
    },
    {
        icon: IconCalendar,
        title: 'Calendar plug-in',
        description: 'Een eenvoudige calendar plug-in voor jouw eigen website',
        ref: "/nl/producten/resirent-calendar",
    },
    {
        icon: IconBook,
        title: 'Stickers',
        description: 'Zet de standaard met onze eenvoudige en handige accessoires en lees onze blog voor toffe tips!',
        ref: "/nl/producten/resirent-stickers",
    },
    {
        icon: IconHomePlus,
        title: 'Advies',
        description: 'Advies op maat voor jouw accommodatie',
        ref: "/nl/producten/advies",
    },
];

export const enProducts = [
    {
        icon: IconWebhook,
        title: 'Website',
        description: 'Own your own authentic website',
        ref: "/products/resirent-website",
    },
    {
        icon: IconCalendar,
        title: 'Calendar plug-in',
        description: 'An easy calendar and booking plug-in for your website',
        ref: "/products/resirent-calendar",
    },
    {
        icon: IconBook,
        title: 'Stickers',
        description: 'Enhance your accommodation with some ResiRent stickers!',
        ref: "/products/resirent-stickers",
    },
    {
        icon: IconHomePlus,
        title: 'Consulting',
        description: 'Personalized consulting for your accommodation',
        ref: "/products/consulting",
    },
];

export const deProducts = [
    {
        icon: IconWebhook,
        title: 'Website',
        description: 'Besitzen Sie Ihre eigene authentische Website',
        ref: "/de/produkte/resirent-website",
    },
    {
        icon: IconCalendar,
        title: 'Calendar plug-in',
        description: 'Ein einfaches Kalender- und Buchungs-Plug-in für Ihre Website',
        ref: "/de/produkte/resirent-calendar",
    },
    {
        icon: IconBook,
        title: 'Stickers',
        description: 'Verbessern Sie Ihre Unterkunft mit einigen ResiRent-Aufklebern!',
        ref: "/de/produkte/resirent-stickers",
    },
    {
        icon: IconHomePlus,
        title: 'Beratung',
        description: 'Persönliche Beratung für Ihre Unterkunft',
        ref: "/de/produkte/beratung",
    },
];

// ================= Footer ================== //
export const nlFooter = [
    {
        title: 'Over ons',
        links: [
            { label: 'Contact', link: '/nl/contact' },
            { label: 'ResiRents philosofie', link: '/nl/filosofie' },
            { label: 'Ons verhaal', link: '/nl/blog/1' },
            { label: 'Het team', link: '/nl/team' },
        ],
    },
    {
        title: 'Onze producten',
        links: [
            { label: 'Website', link: '/nl/producten/resirent-website' },
            { label: 'Stickers', link: '/nl/producten/resirent-stickers' },
            { label: 'Calendar', link: '/nl/producten/resirent-calendar' },
        ],
    },
    {
        title: 'Community',
        links: [
            { label: 'Blog', link: "/nl/blog" },
            // { label: 'Carrière', link: '/coming-soon' },
            { label: 'Partners', link: '/nl/partners' },
        ],
    },
    // ... and so on for other groups
];

export const enFooter = [
    {
        title: 'About',
        links: [
            { label: 'Contact', link: '/contact' },
            { label: 'ResiRents philosophy', link: '/philosophy' },
            { label: 'Our story', link: '/blog/1' },
            { label: 'The team', link: '/team' },
        ],
    },
    {
        title: 'Products',
        links: [
            { label: 'Website', link: '/products/resirent-website' },
            { label: 'Stickers', link: '/products/resirent-stickers' },
            { label: 'Calendar', link: '/products/resirent-calendar' },
        ],
    },
    {
        title: 'Community',
        links: [
            { label: 'Blog', link: '/blog' },
            // { label: 'Careers', link: '/coming-soon' },
            { label: 'Partners', link: '/partners' },
        ],
    },
    // ... and so on for other groups
];

export const deFooter = [
    {
        title: 'Über uns',
        links: [
            { label: 'Kontakt', link: '/de/kontakt' },
            { label: 'ResiRents Philosophie', link: '/de/philosophie' },
            { label: 'Unsere Geschichte', link: '/de/blog/1' },
            { label: 'Das Team', link: '/de/team' },
        ],
    },
    {
        title: 'Produkte',
        links: [
            { label: 'Website', link: '/de/produkte/resirent-website' },
            { label: 'Stickers', link: '/de/produkte/resirent-stickers' },
            { label: 'Calendar', link: '/de/produkte/resirent-calendar' },
        ],

    },
    {
        title: 'Community',
        links: [
            { label: 'Blog', link: '/de/blog' },
            // { label: 'Karriere', link: '/coming-soon' },
            { label: 'Partner', link: '/de/partner' },
        ],
    },
    // ... and so on for other groups
];

export const socialMediaLinks = [
    {
        title: "Facebook",
        href: "https://www.google.com/search?q=cute+kittens&oq=cute+kittens&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIHCAIQABiABDIHCAMQABiABDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDIHCAcQABiABDIHCAgQABiABDIHCAkQABiABNIBCDYyMDZqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8",
        Icon: IconBrandFacebook,
    },
    {
        title: "LinkedIn",
        href: "https://www.google.com/search?q=cute+kittens&oq=cute+kittens&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIHCAIQABiABDIHCAMQABiABDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDIHCAcQABiABDIHCAgQABiABDIHCAkQABiABNIBCDYyMDZqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8",
        Icon: IconBrandLinkedin,
    },
    {
        title: "Instagram",
        href: "https://www.google.com/search?q=cute+kittens&oq=cute+kittens&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIHCAIQABiABDIHCAMQABiABDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDIHCAcQABiABDIHCAgQABiABDIHCAkQABiABNIBCDYyMDZqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8",
        Icon: IconBrandInstagram,
    },
];