import {
    createStyles,
    Header,
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    ThemeIcon,
    Divider,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
    Image,
    Container
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    IconChevronDown,
} from '@tabler/icons-react';
import logo from '../../assets/Long_logo_light@4x.png';
import { enProducts, nlProducts, enNavItems, nlNavItems, deNavItems, deProducts } from '../../languages/translations';
import { useTranslation } from "react-i18next";
import useAuth from '../../hooks/useAuth';
import useLogout from '../../hooks/useLogout';

const useStyles = createStyles((theme) => ({

    header: {
        backgroundColor: theme.colors.resirent[0],
        color: 'white',
    },

    link: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        color: theme.white,
        fontWeight: 500,
        fontSize: theme.fontSizes.md,

        [theme.fn.smallerThan('sm')]: {
            height: rem(42),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.resirent : theme.colors.resirent,
        }),
    },

    linkDrawer: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        color: theme.colorScheme.resirent,
        fontWeight: 500,
        fontSize: theme.fontSizes.md,

        [theme.fn.smallerThan('sm')]: {
            height: rem(42),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.resirent : theme.colors.gray[1],
        }),
    },

    subLink: {
        width: '100%',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        }),

        '&:active': theme.activeStyles,
    },

    dropdownFooter: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        margin: `calc(${theme.spacing.md} * -1)`,
        marginTop: theme.spacing.sm,
        padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
            }`,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },
}));

export function NavBar() {
    const { auth } = useAuth();
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const { classes, theme } = useStyles();
    const { i18n, t } = useTranslation();

    const languagePrefix = i18n.language;

    const navItems = languagePrefix === 'nl' ? nlNavItems : languagePrefix === 'de' ? deNavItems : enNavItems;
    const products = languagePrefix === 'nl' ? nlProducts : languagePrefix === 'de' ? deProducts : enProducts;

    const links = products.map((item) => (
        <UnstyledButton component="a" href={item.ref} className={classes.subLink} key={item.title}>
            <Group noWrap align="flex-start">
                <ThemeIcon size={34} variant="default" radius="md">
                    <item.icon size={rem(22)} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                </ThemeIcon>
                <div>
                    <Text size="sm" fw={500}>
                        {item.title}
                    </Text>
                    <Text size="xs" color="dimmed">
                        {item.description}
                    </Text>
                </div>
            </Group>
        </UnstyledButton>
    ));

    return (
        <Box>
            <Header height={60} className={classes.header} px="md">
                <Group position="apart" sx={{ height: '100%' }}>
                    <a href={navItems.home.ref}>  <Image width={100} height={30} src={logo} alt="ResiRent logo" /></a>
                    <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
                        <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                            <HoverCard.Target>
                                <a href="/" className={classes.link}>
                                    <Center inline>
                                        <Box component="span" mr={5}>
                                            {navItems.products.title}
                                        </Box>
                                        <IconChevronDown size={16} color={theme.white} />
                                    </Center>
                                </a>
                            </HoverCard.Target>
                            <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
                                <Group position="apart" px="md">
                                    <Text fw={500}>{navItems.products.title}</Text>
                                </Group>
                                <Divider
                                    my="sm"
                                    mx="-md"
                                    color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                                />

                                <SimpleGrid cols={2} spacing={0}>
                                    {links}
                                </SimpleGrid>

                                <div className={classes.dropdownFooter}>
                                    <Group position="apart">
                                        <div>
                                            <Text size="xs" color="dimmed">
                                                {navItems.products.subsubtitle}
                                            </Text>
                                        </div>
                                        <Button color="resirent" variant="outline" component='a' href={navItems.products.ref}>{navItems.products.subtitle}</Button>
                                    </Group>
                                </div>
                            </HoverCard.Dropdown>
                        </HoverCard>
                        <a href={navItems.blog.ref} className={classes.link}>
                            {navItems.blog.title}
                        </a>
                        <a href={navItems.pricing.ref} className={classes.link}>
                            {navItems.pricing.title}
                        </a>
                        <a href={navItems.hosts.ref} className={classes.link}>
                            {navItems.hosts.title}
                        </a>
                    </Group>

                    <Group className={classes.hiddenMobile}>
                        {
                            !(auth?.role) ?
                                <Button radius="lg" component="a" color="resirentPink" variant="white" className={classes.control} href={navItems.dashboard.ref}>{t("login.title")} </Button>
                                :
                                <Button radius="lg" onClick={useLogout} component="a" color="resirentPink" variant="white" className={classes.control} href={navItems.login.ref}>{t("logout.title")} </Button>
                        }
                    </Group>

                    <Burger color={theme.white} opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
                </Group>
            </Header>

            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                title="Navigation"
                className={classes.hiddenDesktop}
                zIndex={1000000}
            >
                <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
                    <Divider my="sm" color={theme.colorScheme.resirent} />
                    <UnstyledButton className={classes.linkDrawer} onClick={toggleLinks}>
                        <Center inline>
                            <Box component="span" mr={5}>
                                {navItems.products.title}
                            </Box>
                            <IconChevronDown size={16} color={theme.black} />
                        </Center>
                    </UnstyledButton>
                    <Container><Collapse in={linksOpened}>{links}</Collapse></Container>

                    <a href={navItems.blog.ref} className={classes.linkDrawer}>
                        {navItems.blog.title}
                    </a>
                    <a href={navItems.pricing.ref} className={classes.linkDrawer}>
                        {navItems.pricing.title}
                    </a>
                    <a href={navItems.hosts.ref} className={classes.linkDrawer}>
                        {navItems.hosts.title}
                    </a>
                    <Divider my="sm" color={theme.colorScheme.resirent} />

                    <Group position="center" grow pb="xl" px="md">
                        {
                            !(auth?.role) ?
                                <Button radius="lg" component="a" color="resirentPink" variant="white" className={classes.control} href={navItems.dashboard.ref}> {t("login.title")}</Button>
                                :
                                <Button radius="lg" onClick={useLogout} component="a" color="resirentPink" variant="white" className={classes.control} href={navItems.login.ref}>{t("logout.title")}</Button>
                        }
                        {/* <Button component="a" color="resirentPink" href={navItems.register.ref} >{navItems.register.title}</Button> */}
                    </Group>
                </ScrollArea>
            </Drawer>

        </Box>
    );
}