import { createStyles, Accordion, Group, rem, Container, Title, Box } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    item: {
        fontSize: theme.fontSizes.sm,
        backgroundColor: theme.colorScheme === 'dark' ? "#FFF5F9" : "#FFF5F9",
    },
}));

const placeholder =
    'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.';

const mockdata = [
    {
        question: {
            '/': 'Is the ResiRent content only meant for Small hosts?',
            '/nl': 'Is de inhoud van ResiRent alleen bedoeld voor kleine gastheren?',
            '/de': 'Ist der Inhalt von ResiRent nur für kleine Gastgeber gedacht?',
        },
        answer: {
            '/': 'Certainly not! The ResiRent tips and blog posts can be enjoyed by everyone, including hosts and tourists. In fact, we encourage collaboration between tourists and hosts.',
            '/nl': 'Absoluut niet! De tips en blogberichten van ResiRent zijn bedoeld voor iedereen, inclusief gastheren en toeristen. Sterker nog, we moedigen samenwerking tussen toeristen en gastheren aan.',
            '/de': 'Auf keinen Fall! Die Tipps und Blogbeiträge von ResiRent sind für alle zugänglich, einschließlich Gastgebern und Touristen. Tatsächlich ermutigen wir die Zusammenarbeit zwischen Touristen und Gastgebern.'
        },
    },
    {
        question: {
            '/': 'What are tips for anyone wanting to start their own holiday rental?',
            '/nl': 'Wat zijn tips voor iemand die zijn eigen vakantieverblijf wil beginnen?',
            '/de': 'Welche Tipps haben Sie für jemanden, der seine eigene Ferienunterkunft starten möchte?',
        },
        answer: {
            '/': 'For those looking to start a vacation rental, there is a well-known saying: "Think before you begin." Managing a holiday stay brings so many beautiful moments to your day. It gives meaning: welcoming guests, meeting new people, setting up your home, promoting your own environment - just a few examples. And it also brings in some extra cash. But we are all aware of the proliferation of options offered by online platforms. Regions and cities are imposing restrictions on platforms like Airbnb. So step one: check with your local government if this is even allowed. If you are considering renting out a flat, it is wise to check with the syndicate if there are any restrictions. Hopefully, there are not major obstacles, and the journey can begin! We are here to guide you in setting up and sustainably managing your accommodation. Also, consider that GenZ, our future renters, enjoy staying in a flat or house but place a great deal of importance on sustainability - and rightfully so!',
            '/nl': "Voor wie een vakantiewoning wil starten, is er een bekend gezegde: 'Bedenk eer je begint.' Het beheren van een vakantieverblijf brengt zoveel prachtige momenten in je dag. Het geeft betekenis: gasten verwelkomen, nieuwe mensen leren kennen, je woning inrichten, jouw eigen omgeving promoten - slechts een paar voorbeelden. En het levert ook nog eens een extra zakcentje op. Maar we zijn ons allemaal bewust van de wildgroei aan mogelijkheden die online platforms ons boden. Regio's en steden leggen beperkingen op aan platforms zoals Airbnb. Dus stap één: controleer bij je lokale overheid of dit überhaupt is toegestaan. Bij een eventuele flat die je wilt verhuren, is het verstandig om bij de syndicus na te gaan of er beperkingen gelden. Hopelijk zijn er geen grote obstakels en kan de reis beginnen! We staan klaar om je te begeleiden bij het opzetten en duurzaam beheren van je accommodatie. Bedenk ook dat GenZ, onze toekomstige huurders, graag verblijven in een flat of huis, maar enorm veel belang hechten aan duurzaamheid - en terecht!",
            '/de': "Um Ihre eigene Ferienunterkunft zu starten, ist es wichtig, zu lernen und die Dinge nach und nach anzugehen, um sich nicht zu überfordern. Beginnen Sie mit dem Design Ihres Ferienhauses; wählen Sie hochwertige Möbel und Ihr bevorzugtes Farbschema. Sie können Tools wie 'colorpalet' oder 'Canva' verwenden, um die perfekten Farben für Ihr Ferienhaus zu finden. Denken Sie als Nächstes an Ihre Finanzen, die Reinigung Ihres Hauses und den Aufbau einer Online-Präsenz. Sie können ResiRent's Website verwenden, um Ihre Ferienunterkunft zu präsentieren und die volle Kontrolle über Buchungen zu haben. Sie können auch Plattformen wie Booking.com nutzen, um sich anfängliche Sichtbarkeit für Ihr Unternehmen zu verschaffen. Für weitere Tipps können Sie unsere Blogbeiträge erkunden (weitere werden folgen).",
        },
    },
    {
        question: {
            '/': 'Does Anja have any certificates in sustainable tourism?',
            '/nl': 'Heeft Anja certificaten op het gebied van duurzaam toerisme?',
            '/de': 'Hat Anja Zertifikate im Bereich nachhaltiger Tourismus?',
        },
        answer: {
            '/': 'Yes, she does! Anja, a small host herself, is committed to sustainable tourism practices and improving her hosting services. She has obtained multiple certificates in sustainable tourism.',
            '/nl': 'Ja, dat heeft ze! Anja, zelf een kleine gastvrouw, zet zich in voor duurzame toerismeprojecten en het verbeteren van haar gastvrije diensten. Ze heeft meerdere certificaten behaald op het gebied van duurzaam toerisme.',
            '/de': 'Ja, das hat sie! Anja, selbst eine kleine Gastgeberin, setzt sich für nachhaltige Tourismuspraktiken ein und verbessert ihre Gastgeberdienste. Sie hat mehrere Zertifikate im Bereich nachhaltiger Tourismus erworben.',
        },
    },
    {
        question: {
            '/': 'Can I join the ResiRent team?',
            '/nl': 'Kan ik me aansluiten bij het ResiRent-team?',
            '/de': 'Kann ich dem ResiRent-Team beitreten?',
        },
        answer: {
            '/': 'Absolutely! We are actively seeking enthusiastic individuals to join our team.',
            '/nl': 'Absoluut! We zijn actief op zoek naar enthousiaste mensen om ons team te versterken.',
            '/de': 'Natürlich! Wir suchen aktiv nach begeisterten Personen, die unserem Team beitreten möchten.',
        },
    },
    {
        question: {
            '/': 'How Can I Contact Support?',
            '/nl': 'Hoe kan ik contact opnemen met de klantenservice?',
            '/de': 'Wie kann ich den Support kontaktieren?',
        },
        answer: {
            '/': 'Please visit our resirent.org/contact.',
            '/nl': 'Bezoek onze resirent.org/nl/contact.',
            '/de': 'Bitte besuchen Sie unsere resirent.org/de/contact.',
        },
    },
    {
        question: {
            '/': 'Do I Need to Create an Account?',
            '/nl': 'Moet ik een account aanmaken?',
            '/de': 'Muss ich ein Konto erstellen?',
        },
        answer: {
            '/': 'Not necessarily. All the content on the blog is accessible to everyone! Additionally, you can purchase accessories for your holiday home from our online shop. However, for our website and calendar services, you will need to create an account. Your account will have a unique ID, providing access to your personalized website and calendar. The dashboard also offers an overview of your subscriptions.',
            '/nl': 'Niet per se. Alle inhoud van de blog is toegankelijk voor iedereen! Bovendien kunt u accessoires voor uw vakantieverblijf kopen in onze online winkel. Voor onze website- en kalenderservices moet u echter een account aanmaken. Uw account heeft een unieke ID waarmee u toegang heeft tot uw gepersonaliseerde website en kalender. Het dashboard biedt ook een overzicht van uw abonnementen.',
            '/de': 'Nicht unbedingt. Der gesamte Inhalt des Blogs ist für jeden zugänglich! Zusätzlich können Sie Zubehör für Ihr Ferienhaus in unserem Online-Shop kaufen. Für unsere Website- und Kalenderservices müssen Sie jedoch ein Konto erstellen. Ihr Konto verfügt über eine eindeutige ID, die Ihnen den Zugriff auf Ihre persönliche Website und Ihren Kalender ermöglicht. Das Dashboard bietet auch eine Übersicht über Ihre Abonnements.',
        },
    },
];


export function FAQ() {
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const { classes } = useStyles();
    return (
        <div className={classes.wrapper}>
            <Container size="lg">
                <Group position="center">
                    <Title mb="md" order={2} className={classes.title} ta="center">
                        Frequently Asked Questions
                    </Title>
                </Group>

                <Box m="md">
                    <Accordion defaultValue="" chevronPosition="right" variant="separated">
                        {mockdata.map((feature) => (
                            <Accordion.Item key={feature.question[languagePrefix]} value={feature.question[languagePrefix]} className={classes.item}>
                                <Accordion.Control> {feature.question[languagePrefix]}</Accordion.Control>
                                <Accordion.Panel>{feature.answer[languagePrefix]}</Accordion.Panel>
                            </Accordion.Item>
                        ))}
                    </Accordion>

                </Box>
            </Container>
        </div>
    );
}