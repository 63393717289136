import React, { useState, useEffect } from 'react';
import { Text, NumberInput, Checkbox, Textarea, Select, createStyles, rem, Group, Title, SimpleGrid, Card, Button, MultiSelect } from '@mantine/core';
import axios from '../../api/axios';
import CalendarPriceView from '../calendar/Calendar-pricing/CalendarPriceView';
import useAuth from '../../hooks/useAuth';

const WEBSITE_URL = '/websitejson';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
    },

    titleCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    descriptionCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
        fontWeight: 400,
    },

    base: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        // Blue neutral color
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[2],
    },

    low: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.green[2] : theme.colors.green[2],
    },

    high: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
    },

    gray: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[2],
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

}));

const CalendarSettings = () => {

    const { classes } = useStyles();
    const { auth } = useAuth();
    const username = auth?.resiWeb;
    const title = "calendar-settings";
    const art = "en";
    const [saved, setSaved] = useState(false);

    const [formData, setFormData] = useState({
        pricing: {
            basePrice: {
                amount: 0,
                maxPeoplePrice: 0,
                extraCostPerPerson: 0,
                weekendExtraCost: 0,
            },
            highSeason: {
                amount: 0,
                maxPeoplePrice: 0,
                extraCostPerPerson: 0,
                weekendExtraCost: 0,
            },
            lowSeason: {
                amount: 0,
                maxPeoplePrice: 0,
                extraCostPerPerson: 0,
                weekendExtraCost: 0,
            },
            cleaningFee: {
                included: false,
                amount: 0,
            },
            cityTax: {
                included: false,
                amount: 0,
            },
            dogFee: {
                included: false,
                amount: 0,
            },
            breakfastFee: {
                included: false,
                amount: 0,
            },
            discountMonthly: {
                included: false,
                amount: 0,
            },
            discountWeek: {
                included: false,
                amount: 0,
            },
            parking: {
                included: false,
                amount: 0,
            }
        },
        minNights: 1,
        checkInDays: [{ value: 7, label: 'All' }],
        checkOutcheckInDaysMin: 0,
        maxGuest: 10,
        furthestBookableDate: 2,
        cityTaxAge: 0,
        welcomeSentence: '',
        cancellationPolicy: '',
        houseRules: '',
        currencyUsed: '€',  // Updated to hold the currency symbol
    });

    // fetch the data from the server
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art, title },
                });
                if (response.data.entry.content != '"default text"') {
                    setFormData(JSON.parse(response.data.entry.content));
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [username, art, title]);


    const handleInputChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handlePricingChange = (category, field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            pricing: {
                ...prevData.pricing,
                [category]: {
                    ...prevData.pricing[category],
                    [field]: value,
                },
            },
        }));
    };

    const handleCheckboxChange = (event, category) => {
        const { checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            pricing: {
                ...prevData.pricing,
                [category
                ]: {
                    ...prevData.pricing[category],
                    included: checked,
                    amount: checked ? prevData.pricing[category].amount : 0,
                },
            },
        }));
    };

    const saveFormData = async (event) => {
        // You can now send this formData to your server or perform other actions.
        try {
            await axios.put(WEBSITE_URL, {
                username,
                art,
                title,
                content: formData,
            });
            setSaved(true);
            // reset save after 5 sec.
            setTimeout(() => {
                setSaved(false);
            }, 5000);
        } catch (err) {
            console.log(err);
        }
        // Confirmation text
    };

    return (
        <div>
            <Group position="center">
                <Title order={2} className={classes.title} my="sm" display={'block'} ta="center">
                    Edit Property parameters
                </Title>
            </Group>

            <Title order={4} className={classes.title} my="sm" display={'block'} ta="center">
                Set your price schemes
            </Title>

            <SimpleGrid pt="md" mr="md" cols={3} breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                <Card p="md" radius="md" className={classes.base}>
                    <Group position="center">
                        <Title order={4} className={classes.title} my="sm" display={'block'} ta="center">
                            Base Price
                        </Title>
                    </Group>

                    <NumberInput
                        label="Base Price"
                        id="basePrice"
                        type="number"
                        value={formData.pricing.basePrice.amount}
                        onChange={(value) => handlePricingChange('basePrice', 'amount', value)}
                    />

                    <NumberInput
                        label="Max People for Base Price"
                        id="maxPeopleBasePrice"
                        value={formData.pricing.basePrice.maxPeoplePrice}
                        onChange={(value) => handlePricingChange('basePrice', 'maxPeoplePrice', value)}
                    />
                    <NumberInput
                        label="Extra Cost Per Person"
                        id="extraCostPerPerson"
                        type='number'
                        value={formData.pricing.basePrice.extraCostPerPerson}
                        onChange={(value) => handlePricingChange('basePrice', 'extraCostPerPerson', value)}
                    />
                    <NumberInput
                        label="Extra Cost in Weekends (Friday night / Saturday night)"
                        id="extraCostPerPerson"
                        type='number'
                        value={formData.pricing.basePrice.weekendExtraCost}
                        onChange={(value) => handlePricingChange('basePrice', 'weekendExtraCost', value)}
                    />
                </Card>
                <Card p="md" radius="md" className={classes.high}>
                    <Group position="center">
                        <Title order={4} className={classes.title} my="sm" display={'block'} ta="center">
                            High Season
                        </Title>
                    </Group>
                    <NumberInput
                        label="High Season"
                        id="highSeason"
                        type="number"
                        value={formData.pricing.highSeason.amount}
                        onChange={(value) => handlePricingChange('highSeason', 'amount', value)}
                    />

                    <NumberInput
                        label="Max People for High Price"
                        id="maxPeopleBasePrice"
                        value={formData.pricing.highSeason.maxPeoplePrice}
                        onChange={(value) => handlePricingChange('highSeason', 'maxPeoplePrice', value)}
                    />
                    <NumberInput
                        label="Extra Cost Per Person"
                        id="extraCostPerPerson"
                        type='number'
                        value={formData.pricing.highSeason.extraCostPerPerson}
                        onChange={(value) => handlePricingChange('highSeason', 'extraCostPerPerson', value)}
                    />
                    <NumberInput
                        label="Extra Cost in Weekends (Friday night / Saturday night)"
                        id="extraCostPerPerson"
                        type='number'
                        value={formData.pricing.highSeason.weekendExtraCost}
                        onChange={(value) => handlePricingChange('highSeason', 'weekendExtraCost', value)}
                    />
                </Card>
                <Card p="md" radius="md" className={classes.low}>
                    <Group position="center">
                        <Title order={4} className={classes.title} my="sm" display={'block'} ta="center">
                            Reduced Season
                        </Title>
                    </Group>
                    <NumberInput
                        label="Low Price"
                        id="lowPrice"
                        type="number"
                        value={formData.pricing.lowSeason.amount}
                        onChange={(value) => handlePricingChange('lowSeason', 'amount', value)}
                    />

                    <NumberInput
                        label="Max People for low Price"
                        id="maxPeoplePrice"
                        value={formData.pricing.lowSeason.maxPeoplePrice}
                        onChange={(value) => handlePricingChange('lowSeason', 'maxPeoplePrice', value)}
                    />
                    <NumberInput
                        label="Extra Cost Per Person"
                        id="extraCostPerPerson"
                        type='number'
                        value={formData.pricing.lowSeason.extraCostPerPerson}
                        onChange={(value) => handlePricingChange('lowSeason', 'extraCostPerPerson', value)}
                    />
                    <NumberInput
                        label="Extra Cost in Weekends (Friday night / Saturday night)"
                        id="extraCostPerPerson"
                        type='number'
                        value={formData.pricing.lowSeason.weekendExtraCost}
                        onChange={(value) => handlePricingChange('lowSeason', 'weekendExtraCost', value)}
                    />
                </Card>
            </SimpleGrid>

            <Button align="center" mb="md" radius="xl" onClick={saveFormData} mt="md" size="md" className={classes.controlSecond}>
                Save
            </Button>
            {saved &&
                <Text size="sm" weight={500} color="red">
                    Saved successfully!
                </Text>
            }

            <Title order={4} className={classes.title} my="sm" display={'block'} ta="center">
                Define additional costs
            </Title>

            <Card p="md" radius="md" mr="md" className={classes.gray}>
                <Checkbox
                    id="cleaningFeeIncluded"
                    label="Cleaning Fee not included?"
                    checked={formData.pricing.cleaningFee.included}
                    onChange={(event) => handleCheckboxChange(event, 'cleaningFee')}
                />

                {formData.pricing.cleaningFee.included ? (
                    <NumberInput
                        mt="md"
                        label="Cleaning Fee Amount"
                        id="cleaningFeeAmount"
                        value={formData.pricing.cleaningFee.amount}
                        onChange={(value) => handlePricingChange('cleaningFee', 'amount', value)}
                    />
                ) : null}
            </Card>

            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                <Checkbox
                    id="cityTaxIncluded"
                    label="City Tax not Included?"
                    checked={formData.pricing.cityTax.included}
                    onChange={(event) => handleCheckboxChange(event, 'cityTax')}
                />

                {formData.pricing.cityTax.included ? (
                    <>
                        <NumberInput
                            mt="md"
                            label="Minimum age for city tax"
                            id="cityTaxAge"
                            value={formData.cityTaxAge}
                            onChange={(value) => handleInputChange('cityTaxAge', value)}
                        />
                        <NumberInput
                            mt="md"
                            label="City tax Fee Amount per person"
                            id="cityTaxFeeAmount"
                            value={formData.pricing.cityTax.amount}
                            onChange={(value) => handlePricingChange('cityTax', 'amount', value)}
                        />
                    </>
                ) : null}
            </Card>

            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                <Checkbox
                    id="dogFeeIncluded"
                    label="Dogs allowed? (extra fee)"
                    checked={formData.pricing.dogFee.included}
                    onChange={(event) => handleCheckboxChange(event, 'dogFee')}
                />

                {formData.pricing.dogFee.included ? (
                    <NumberInput
                        mt="md"
                        label="Dog Fee Amount"
                        id="dogFeeAmount"
                        value={formData.pricing.dogFee.amount}
                        onChange={(value) => handlePricingChange('dogFee', 'amount', value)}
                    />
                ) : null}
            </Card>
            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                <Checkbox
                    id="parkingIncluded"
                    label="Parking available? (extra fee)"
                    checked={formData.pricing.parking.included}
                    onChange={(event) => handleCheckboxChange(event, 'parking')}
                />
                {
                    formData.pricing.parking.included ? (
                        <NumberInput
                            mt="md"
                            label="Parking Fee Amount"
                            id="parkingFeeAmount"
                            value={formData.pricing.parking.amount}
                            onChange={(value) => handlePricingChange('parking', 'amount', value)}
                        />
                    ) : null
                }
            </Card>
            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                <Checkbox
                    id="breakfastFeeIncluded"
                    label="Breakfast available? (extra fee)"
                    checked={formData.pricing.breakfastFee.included}
                    onChange={(event) => handleCheckboxChange(event, 'breakfastFee')}
                />

                {formData.pricing.breakfastFee.included ? (
                    <NumberInput
                        mt="md"
                        label="Breakfast Fee Amount"
                        id="breakfastFeeAmount"
                        value={formData.pricing.breakfastFee.amount}
                        onChange={(value) => handlePricingChange('breakfastFee', 'amount', value)}
                    />
                ) : null}
            </Card>
            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                <Checkbox
                    id="discountMonthlyIncluded"
                    label="Discount Weekly Available?"
                    checked={formData.pricing.discountWeek.included}
                    onChange={(event) => handleCheckboxChange(event, 'discountWeek')}
                />

                {formData.pricing.discountWeek.included ? (
                    <NumberInput
                        mt="md"
                        label="Discount Week Amount (in percentage)"
                        id="discountWeekAmount"
                        value={formData.pricing.discountWeek.amount}
                        onChange={(value) => handlePricingChange('discountWeek', 'amount', value)}
                    />
                ) : null}
            </Card>

            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                <Checkbox
                    id="discountMonthlyIncluded"
                    label="Discount Monthly available?"
                    checked={formData.pricing.discountMonthly.included}
                    onChange={(event) => handleCheckboxChange(event, 'discountMonthly')}
                />

                {formData.pricing.discountMonthly.included ? (
                    <NumberInput
                        mt="md"
                        label="Discount Monthly Amount (in percentage)"
                        id="discountMonthlyAmount"
                        value={formData.pricing.discountMonthly.amount}
                        onChange={(value) => handlePricingChange('discountMonthly', 'amount', value)}
                    />
                ) : null}
            </Card>
            <Button align="center" mb="md" radius="xl" onClick={saveFormData} mt="md" size="md" className={classes.controlSecond}>
                Save
            </Button>

            {saved &&
                <Text size="sm" weight={500} color="red">
                    Saved successfully!
                </Text>
            }
            <Title order={4} className={classes.title} my="sm" display={'block'} ta="center">
                Check-in details
            </Title>

            <NumberInput
                mt="md"
                label="Minimum nights of stay"
                id="MinimumNightStay"
                value={formData.minNights}
                onChange={(value) => handleInputChange('minNights', value)}
            />

            <MultiSelect
                label="Apply prices to days in the selected range"
                placeholder="All week"
                data={[
                    { value: 7, label: 'All week' },
                    { value: 1, label: 'Monday' },
                    { value: 2, label: 'Tuesday' },
                    { value: 3, label: 'Wednesday' },
                    { value: 4, label: 'Thursday' },
                    { value: 5, label: 'Friday' },
                    { value: 6, label: 'Saturday' },
                    { value: 0, label: 'Sunday' },
                ]}
                onChange={(e) => handleInputChange('checkInDays', e)}
                hidePickedOptions
            />

            <NumberInput
                mt="md"
                label="Minimum days between check-out and check-in"
                id="checkOutcheckInDaysMin"
                value={formData.checkOutcheckInDaysMin}
                onChange={(value) => handleInputChange('checkOutcheckInDaysMin', value)}
            />

            <NumberInput
                mt="md"
                label="Maximum amount of guests"
                id="maxGuest"
                value={formData.maxGuest}
                onChange={(value) => handleInputChange('maxGuest', value)}
            />

<NumberInput
                mt="md"
                label="The furthest bookable date in advance in years"
                id="furthestBookableDate"
                value={formData.furthestBookableDate}
                onChange={(value) => handleInputChange('furthestBookableDate', value)}
            />

<Select
            label="Currency"
            placeholder="Currency used:"
            data={[
                { value: '€', label: '€' },
                { value: 'CHF', label: 'CHF' },
                { value: '£', label: '£' },
                { value: '$', label: '$' },
                { value: '₽', label: '₽' },
                { value: '₺', label: '₺' },
                { value: '₹', label: '₹' },
            ]}
            value={formData.currencyUsed}
            onChange={(value) => handleInputChange('currencyUsed', value)}
        />
            <Button align="center" mb="md" radius="xl" onClick={saveFormData} mt="md" size="md" className={classes.controlSecond}>
                Save
            </Button>

            {saved &&
                <Text size="sm" weight={500} color="red">
                    Saved successfully!
                </Text>
            }
            <Title order={4} className={classes.title} my="sm" display={'block'} ta="center">
                Welcome message
            </Title>

            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                <label htmlFor="cancellationPolicy">Welcome paragraph:</label>
                <Textarea
                    id="welcomeSentence"
                    autosize
                    minRows={2}
                    defaultValue={formData.welcomeSentence}
                    onChange={(event) => handleInputChange('welcomeSentence', event.currentTarget.value)}
                />
            </Card>

            <Button align="center" mb="md" radius="xl" onClick={saveFormData} mt="md" size="md" className={classes.controlSecond}>
                Save
            </Button>

            {saved &&
                <Text size="sm" weight={500} color="red">
                    Saved successfully!
                </Text>
            }

            <Title order={4} className={classes.title} my="sm" display={'block'} ta="center">
                Property rules and guidelines
            </Title>

            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                <label htmlFor="cancellationPolicy">Cancellation Policy:</label>
                <Textarea
                    id="cancellationPolicy"
                    autosize
                    minRows={2}
                    defaultValue={formData.cancellationPolicy}
                    onChange={(event) => handleInputChange('cancellationPolicy', event.currentTarget.value)}
                />

                {/* House Rules */}
                <label mt="md" htmlFor="houseRules">House Rules:</label>
                <Textarea
                    id="houseRules"
                    autosize
                    minRows={2}
                    defaultValue={formData.houseRules}
                    onChange={(event) => handleInputChange('houseRules', event.currentTarget.value)}
                />

            </Card>
            <Button align="center" mb="md" radius="xl" onClick={saveFormData} mt="md" size="md" className={classes.controlSecond}>
                Save
            </Button>

            {/* Confirmation text */}
            {saved &&
                <Text size="sm" weight={500} color="red">
                    Saved successfully!
                </Text>
            }
            <Title order={4} className={classes.title} my="sm" display={'block'} ta="center">
                Calendar overview and price settings
            </Title>

            <Card p="md" mt="md" radius="md" mr="md" className={classes.gray}>
                {CalendarPriceView(formData)}
            </Card>
        </div>
    );
};

export default CalendarSettings;
