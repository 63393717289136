import React from 'react';
import { createStyles, Button, BackgroundImage, Center, Title, Box, Container, Text } from '@mantine/core';
import image1 from '../../assets/homepage/SAM_2631.jpg';
import image6 from '../../assets/homepage/IMG_4891.jpg';
import image4 from '../../assets/homepage/ApartmentTheCity_s10_HD.jpg';
import image7 from '../../assets/homepage/IMG_2349.jpg';
import image5 from '../../assets/homepage/IMG_2913.jpeg';
import image2 from '../../assets/homepage/024.jpg';
import image3 from '../../assets/homepage/soap.jpg';
import image8 from '../../assets/homepage/034.jpg';
import image9 from '../../assets/homepage/thecitysofa.jpg';

const header = {
    '/': {
        'title': 'Optimize your short-term rental income',
        'subtitle': 'digital tools, advice and products for holiday accommodations',
        'workshop': 'Join our newsletter',
        'workshopButton': "Sign up",
    },
    '/nl': {
        'title': 'Optimaliseer jouw korte termijn verhuur',
        'subtitle': 'website bouwer, advies en producten voor vakantie accommodaties',
        'workshop': 'Meld je aan voor onze nieuwsbrief',
        'workshopButton': "Meld je aan"
    },
    '/de': {
        'title': 'Förderung verantwortungsvoller Vermietungspraktiken',
        'subtitle': 'Bewusstes Hosting für blühenden Tourismus',
        'workshop': 'Melden Sie sich für unseren Newsletter an',
        'workshopButton': "Anmelden",
    },
};

const useStyles = createStyles((theme) => ({

    container: {
        backgroundColor: theme.fn.rgba(theme.colors.resirent[8], 0.6),
        color: 'white',
        padding: '20px',
        paddingTop: '150px',
        paddingBottom: '150px',

    },

    container2: {
        color: 'white',
        padding: '20px',
        paddingTop: '150px',
        paddingBottom: '150px',
        paddingLeft: '150px',
        paddingRight: '150px',
        [theme.fn.smallerThan('sm')]: {
            paddingLeft: 0,  // Remove paddingLeft for small screens
            paddingRight: 0, // Remove paddingRight for small screens
            paddingTop: '50px',
            paddingBottom: '50px',
        },
    },

}));


export default function ImageSlider() {
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const [currentImage, setCurrentImage] = React.useState(0);
    const images = [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
    ];

    const imageButton = [
        {
            name: 'The City Ghent - Interior design is our passion',
            link: 'https://thecityghent.com/home',
        },
        {
            name: 'Haus Clarysse - The kitchen is the heart of the home',
            link: 'https://hausclarysse.com/home',
        },
        {
            name: 'Haus Clarysse - A confortable stay starts with a cosy home ',
            link: 'https://hausclarysse.com/home',
        },
        {
            name: 'The City Ghent - Vegan friendly shampoo and soap',
            link: 'https://thecityghent.com/home',
        },

        {
            name: 'Haus Clarysse - Beautiful view over the mountains',
            link: 'https://hausclarysse.com/home',
        },

        {
            name: 'The City Ghent - Details make perfection',
            link: 'https://thecityghent.com/home',
        },

        {
            name: 'The City Ghent - Playing with colors',
            link: 'https://thecityghent.com/home',
        },

        {
            name: 'Haus Clarysse - Decoration makes the difference',
            link: 'https://hausclarysse.com/home',
        },
    ];

    const { classes } = useStyles();

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage(currentImage => (currentImage + 1) % images.length);
        }, 20000);
        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <Box>
            <BackgroundImage
                src={images[currentImage]}
                alt="Landscape"
            >
                <Container className={classes.container2}>
                    <Center p="md">
                        <Text className={classes.container} p="md">
                            <Title order={3} weight={600} color="white">
                                {header[languagePrefix].title}
                            </Title>
                            <Title order={1} weight={900} color="white" mt="md">
                                {header[languagePrefix].subtitle}
                            </Title>
                            {/* <Title order={1} weight={500} color="white" mt="xl">
                                {header[languagePrefix].workshop}
                            </Title>
                            <Button color="resirentPink" component="a" href="http://eepurl.com/isztuc" mt="md" size="md" className={classes.control}>
                                {header[languagePrefix].workshopButton}
                            </Button> */}
                        </Text>
                    </Center>
                </Container>
                {/* <Box align="right">
                    <Text component='a' href={imageButton[currentImage].link} order={1} weight={400} color="dimmed" mt="md">
                        {imageButton[currentImage].name}
                    </Text>
                </Box> */}
            </BackgroundImage>
        </Box >
    )
} 