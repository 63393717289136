import React, { useState } from 'react';
import { Text, Box, SimpleGrid } from '@mantine/core';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    halfStartBooked: {
        background: `linear-gradient(to left, ${theme.colors.red[2]} 50%, ${theme.colors.gray[3]} 50%)`,
        border: `1px solid ${theme.colors.gray[0]}`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
        },
    },
    halfEndBooked: {
        background: `linear-gradient(to right, ${theme.colors.red[2]} 50%, ${theme.colors.gray[3]} 50%)`,
        border: `1px solid ${theme.colors.gray[0]}`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
        },
    },
    halfStartInProcess: {
        background: `linear-gradient(to left, ${theme.colors.green[2]} 50%, ${theme.colors.gray[3]} 50%)`,
        border: `1px solid ${theme.colors.gray[0]}`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
        },
    },
    halfEndInProcess: {
        background: `linear-gradient(to right, ${theme.colors.green[2]} 50%, ${theme.colors.gray[3]} 50%)`,
        border: `1px solid ${theme.colors.gray[0]}`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
        },
    },
    halfStartClosed: {
        background: `linear-gradient(to right, ${theme.colors.gray[3]} 50%, ${theme.colors.gray[3]} 50%)`,
        border: `1px solid ${theme.colors.gray[0]}`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
        },
    },
    halfEndClosed: {
        background: `linear-gradient(to left, ${theme.colors.gray[3]} 50%, ${theme.colors.gray[3]} 50%)`,
        border: `1px solid ${theme.colors.gray[0]}`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
        },
    },
    paper: {
        m: 'md',
        radius: 'md',
        align: 'center',
    },
    box: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[2],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[5] : theme.colors.blue[5],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    boxWeekday: {
        backgroundColor: theme.colors.resirent[0],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.pink[7] : theme.colors.pink[7],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    boxEmpty: {
        backgroundColor: theme.colors.white,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    highSeason: {
        backgroundColor: theme.colors.red[2],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[5] : theme.colors.red[5],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    lowSeason: {
        backgroundColor: theme.colors.green[2],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.green[5] : theme.colors.green[5],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    boxSelected: {
        backgroundColor: theme.colors.yellow[5],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.colors.yellow[5],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    checkOut: {
        position: 'absolute',
        top: 2,
        left: 2,
        color: theme.colors.blue[7],
        fontSize: '1.5rem', // Increase the size
        fontWeight: 700, // Increase the weight
    },
    checkIn: {
        position: 'absolute',
        top: 2,
        right: 2,
        color: theme.colors.red[7],
        fontSize: '2rem', // Increase the size
        fontWeight: 700, // Increase the weight
    },
}));

export const CalendarPriceViewHost = (inputForm, date, dateStates, selectedRange, setSelectedRange, itemDisplay, handleAccept, handleDecline, handleCancel, handleStop) => {

    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const { classes } = useStyles();
    const year = date.getFullYear();
    const month = date.getMonth();

    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startingDay = (firstDay.getDay() + 6) % 7;

    const calendar = [];

    const headerRow = (
        <>
            {daysOfWeek.map((day) => (
                <Box className={classes.boxWeekday} key={day}>
                    <Text color="white" size="xs" weight={500} align="center">
                        {day}
                    </Text>
                </Box>
            ))}
        </>
    );
    calendar.push(headerRow);

    let currentRow = [];
    for (let i = 0; i < startingDay; i++) {
        currentRow.push(
            <Box className={classes.boxEmpty} key={`empty-${i}`} />
        );
    }

    const isSelected = (dateKey) => {
        if (selectedRange.start && selectedRange.end) {
            const startDate = new Date(selectedRange.start);
            const endDate = new Date(selectedRange.end);
            const currentDate = new Date(dateKey);
            return currentDate >= startDate && currentDate <= endDate;
        }
        return false;
    };

    const isSelectable = (dateKey) => {
        const dateStateInfo = Object.values(dateStates).find(range =>
            new Date(range.start) <= new Date(dateKey) && new Date(range.end) >= new Date(dateKey)
        );

        if (dateStateInfo) {
            const { start, end, booked } = dateStateInfo;
            const isBoundary = dateKey === start || dateKey === end;

            if (booked === 'booked' && !isBoundary) {
                return false;
            }
        }
        return true;
    };

    const handleDateClick = (dateKey) => {
        if (selectedRange.start === null && selectedRange.end === null) {
            setSelectedRange({ start: dateKey, end: null });
        } else if (selectedRange.start != null && selectedRange.end === null) {
            if (new Date(dateKey) < new Date(selectedRange.start)) {
                setSelectedRange({ start: dateKey, end: selectedRange.start });
            } else {
                setSelectedRange({ ...selectedRange, end: dateKey });
            }
        } else {
            setSelectedRange({ start: dateKey, end: null });
        }
    };

    for (let day = 1; day <= daysInMonth; day++) {
        const dateKey = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        const dateStateInfo = Object.values(dateStates).find(range =>
            new Date(range.start) <= new Date(dateKey) && new Date(range.end) >= new Date(dateKey)
        );

        let boxClass = classes.box;
        let isClickable = true;

        if (isSelected(dateKey)) {
            boxClass = classes.boxSelected;
        } else if (dateStateInfo) {
            const { price } = dateStateInfo;
            if (price === 'high') {
                boxClass = classes.highSeason;
            } else if (price === 'low') {
                boxClass = classes.lowSeason;
            } else {
                boxClass = classes.box;
            }
        }

        // if (dateStateInfo) {
        //     const { price } = dateStateInfo;
        //     if (isSelected(dateKey)) {
        //         boxClass = classes.boxSelected;
        //     } else if (price === 'high') {
        //         boxClass = classes.highSeason;
        //     } else if (price === 'low') {
        //         boxClass = classes.lowSeason;
        //     } else {
        //         boxClass = classes.box;
        //     }
        // }

        currentRow.push(
            <Box className={boxClass} onClick={() => isClickable && handleDateClick(dateKey)} key={dateKey} position="relative">
                <SimpleGrid columns={2} spacing="xs" >
                    {day}
                </SimpleGrid>
                {dateStateInfo ?
                    <Text size="xs" weight={500} align='center'>
                        {dateStateInfo.price}
                    </Text>
                    :
                    <Text size="xs" weight={500} align='center'>
                        base
                    </Text>
                }
                {/* <Text size="xs" weight={500} align='center'>
                            {dateStateInfo.label}
                  </Text>  */}
            </Box>
        );

        if (currentRow.length === 7) {
            calendar.push(
                <React.Fragment key={`week-${day}`}>
                    {currentRow}
                </React.Fragment>
            );
            currentRow = [];
        }
    }

    while (currentRow.length < 7) {
        currentRow.push(
            <Box className={classes.boxEmpty} key={`empty-${currentRow.length}`} />
        );
    }

    calendar.push(
        <React.Fragment key="last-row">
            {currentRow}
        </React.Fragment>
    );

    return (
        <>
            {calendar}
        </>
    );
};

export default CalendarPriceViewHost;
