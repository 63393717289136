import { Box, NavLink, Anchor, rem, createStyles, Image, Text } from '@mantine/core';
import {
    IconSettings,
    IconReceipt2,
    IconLogout,
    IconCalendar,
    IconHome,
    IconCalendarBolt,
    IconCalendarClock,
} from '@tabler/icons-react';
import logo from '../../assets/Long_logo_light@4x.png';
import useLogout from '../../hooks/useLogout';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const data = [
    { link: '/dashboard/profile', label: 'Home', icon: IconHome },
    { link: '/dashboard/calendar-settings', label: 'Calendar settings', icon: IconCalendar },
    { link: '/dashboard/calendar-view', label: 'Calendar manager', icon: IconCalendarBolt },
    { link: "/dashboard/calendar-cleaning", label: "Cleaning", icon: IconCalendarClock },
    // { link: '/dashboard/contact', label: 'Contact', icon: IconPhone },
    // { link: '/dashboard/subscriptions', label: 'Subscriptions', icon: IconReceipt2 },
    // { link: '/dashboard/settings', label: 'Settings', icon: IconSettings },
];

const useStyles = createStyles((theme) => ({

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirent[8],
        },
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
        color: theme.white,
        width: '50%'
    },

    navbar: {
        height: rem(4500),
        // width: rem(300),
        padding: "md",
        color: theme.white,
        backgroundColor: theme.fn.variant({ variant: 'filled', color: 'resirent' }).background,
    },

    footer: {
        paddingTop: `calc(${theme.spacing.xl} * 3)`,
        //borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white}`,
    },

    link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: 'var(--mantine-font-size-sm)',
        color: 'var(--mantine-color-white)',
        padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-sm)',
        borderRadius: 'var(--mantine-radius-sm)',
        fontWeight: 500,

        '&:hover': {
            backgroundColor: 'var(--mantine-color-blue-7)',
            color: 'var(--mantine-color-white)',

            '& .linkIcon': {
                color: 'var(--mantine-color-white)',
            },
        },

        '[data-active]': {
            '&, &:hover': {
                boxShadow: 'var(--mantine-shadow-sm)',
                backgroundColor: 'var(--mantine-color-white)',
                color: 'var(--mantine-color-blue-filled)',

                '& .linkIcon': {
                    color: 'var(--mantine-color-blue-filled)',
                },
            },
        },
    },

    linkIcon: {
        color: 'var(--mantine-color-blue-1)',
        marginRight: 'var(--mantine-spacing-sm)',
        width: '25px',
        height: '25px',
    },

    navlink: {
        '&:hover': {
            backgroundColor: theme.colors.resirent[8],
            color: theme.fn.variant({ variant: 'filled', color: 'resirent' }).background,
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        // textAlign: 'center', // Center-align the contents
    },
}));

const Navbar = () => {
    const { classes, theme } = useStyles();
    const { t } = useTranslation();
    const Logout = useLogout();
    const navigate = useNavigate();

    const links = data.map((item) => (
        <NavLink style={{ color: theme.white }} className={classes.navlink} key={item.label} label={item.label} component="a" href={item.link} icon={<item.icon size={rem(20)} stroke={2} color={theme.fn.rgba(theme.white, 1)} />} />
    ));

    const signOut = async () => {
        await Logout();
        navigate(t("links.home"));
    }

    return (
        <Box className={classes.navbar}>

            <Box p="md">
                <a href={t("links.home")}> <Image src={logo} width={150} /></a>
            </Box>

            <Box p="md" textAlign="center">
                <Text size="xl" weight={700} color={theme.white} uppercase>
                    {t("dashboard")}
                </Text>
            </Box>

            <Box p="md">{links}</Box>

            <Box p="md" textAlign="center">
                <Text size="xl" weight={700} color={theme.white} uppercase>
                    <Anchor color="white" fullWidth onClick={signOut} icon={<IconLogout size={rem(20)} stroke={2} color={theme.fn.rgba(theme.white, 1)} />} >
                        <IconLogout size={rem(20)} stroke={2} color={theme.fn.rgba(theme.white, 1)} />
                        Log out
                    </Anchor>
                </Text>
            </Box>
        </Box >
    );
};

export default Navbar;
