import {
    createStyles,
    Image,
    Container,
    Title,
    Text,
    Button,
    SimpleGrid,
    rem,
} from '@mantine/core';
import image from '../../assets/informative/ComingSoon.png';

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(80),
        paddingBottom: rem(80),
    },

    title: {
        fontWeight: 900,
        fontSize: rem(34),
        marginBottom: theme.spacing.md,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(32),
        },
    },

    control: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },

    mobileImage: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    desktopImage: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },
}));

export default function DIY() {
    const { classes } = useStyles();

    return (
        <Container size="lg" py={100} align="center">
            <Title className={classes.title}>DIY</Title>
            <Text color="dimmed" size="lg" mb="md">
                Please fill out this form and we will come back to you as soon as possible.
            </Text>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe0Bb2spIlMD5TBgdNGvJH-b-m8j341ZruRkXTiFozy6IGHxA/viewform?embedded=true" width="640" height="1715" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </Container>
    );
}