import React from 'react'
import { NavBar } from './NavBar'
import { TopNavBar } from './TopNavBar'

const FullNavBar = () => {
    return (
        <div>
            <TopNavBar />
            <NavBar />
        </div>
    )
}

export default FullNavBar