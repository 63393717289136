import {
  createStyles,
  Group,
  Title,
  Container,
  rem,
  Image,
  Box,
  Center,
  Text,
  Grid,
  Flex,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { postsEn, postsNL } from '../../languages/blogPages';
import image from '../../assets/philosophy/frontPage.jpg';
import climate from '../../assets/philosophy/climate.png';
import climateGoal from '../../assets/philosophy/climateGoal.png';
import economy from '../../assets/philosophy/economy.png';
import economyGoal from '../../assets/philosophy/EconomyGoal.png';
import health from '../../assets/philosophy/health.png';
import HealthGoal from '../../assets/philosophy/HealthGoal.png';
import partnership from '../../assets/philosophy/partnership.png';
import parthershipGoal from '../../assets/philosophy/partnershipGoal.png';
import community from '../../assets/philosophy/community.png';
import communityGoal from '../../assets/philosophy/CommunityGoal.png';
import gstc from '../../assets/philosophy/GSTC.png';

const useStyles = createStyles((theme) => ({
  title: {
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(24),
      maxWidth: '100%',
      display: 'block',
    },
    // maxwidth 2/3 of the screen
    margin: 'auto',
  },

  description: {
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(16),
      maxWidth: '100%',
      display: 'block',
    },
    // maxwidth 2/3 of the screen
    margin: 'auto',
    fontWeight: 400,
  },

  control: {
    '&:hover': {
      border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
      backgroundColor: theme.white,
      color: theme.colors.resirentPink[5],
    },

    '&:active': {
      variant: 'filled',
      color: 'resirentPink',
    },

    textAlign: 'center', // Center-align the contents
  },

  card: {
    border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: theme.shadows.md,
    },
    textAlign: 'center', // Center-align the contents
  },
}));

export default function Philosophy() {
  const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
  const { hovered: hoveredClimate, ref: refClimate } = useHover();
  const { hovered: hoveredEconomy, ref: refEconomy } = useHover();
  const { hovered: hoveredHealth, ref: refHealth } = useHover();
  const { hovered: hoveredCommunity, ref: refCommunity } = useHover();
  const { hovered: hoveredPartnership, ref: refPartnership } = useHover();
  const posts = languagePrefix === '/nl' ? postsNL : languagePrefix === '/de' ? postsEn : postsEn;

  const { classes, theme } = useStyles()

  const content = {
    '/': {
      'Title': 'Our Philosophy',
      'MainTitle': "ResiRent's Vision on Sustainable Hosting",
      'mainDescription': 'ResiRent is dedicated to striking a harmonious balance between professionalism, environmental consciousness, and genuine respect for local communities. Our commitment extends beyond providing digital tools, including website templates and customized booking systems, to offering tangible solutions such as bespoke stickers that assist your guests in responsible waste sorting.',
      'subDescription': "In 2015, 150 world leaders collaborated to formulate an ambitious sustainable development agenda, resulting in seventeen clearly defined goals. These goals target specific areas and were designed for implementation by countries, regions, schools, and businesses. Every entity is encouraged to assess its sustainability standing and incorporate measures to enhance commitment. While major industries have made strides, it's essential for smaller accommodations to join this movement. At ResiRent, we've categorized the sustainable development goals into high, medium, and low impact. For hosts of small accommodations, five goals are particularly relevant, detailed in our distinctive label.",
      'goal17': 'GOAL 17: PARTNERSHIP FOR THE GOALS',
      'des17': "At ResiRent, we actively collaborate with local and regional tourism offices to gain a deep understanding of the challenges faced by holiday rental and tourism industries. For instance, we seek their feedback and guidance in the development of our website builder. This involves understanding crucial criteria that should be displayed on a small host's website and navigating legal considerations. In addition to our collaboration with tourism offices, we engage with innovative start-ups that provide solutions to support small hosts. One notable example is Dexxter, a Belgian start-up led by three young entrepreneurs, offering a user-friendly solution for VAT administration. Furthermore, we establish partnerships with individuals working in the field and representatives from the regions where our accommodations are located. This collaborative approach allows us to not only comprehend but also implement their specific needs, ensuring a more tailored and effective response to the challenges faced by our community.",
      'des8': 'As a small host, you wield a substantial economic influence on the regions or cities where you operate. This impact spans from ensuring the well-being of your staff through to actively partnering with local businesses. Drawing on our own experience as small hosts with over ten years in the field, we share valuable insights, including tips and tricks. For example, we offer posts on establishing fair salaries and reasonable time requirements for your cleaning staff. Additionally, we provide advice on how to forge collaborations with local businesses to authentically design your holiday home. Our goal is to empower small hosts with the knowledge and expertise needed to contribute positively to their local economies and communities.',
      'goal8': 'GOAL 8: DECENT WORK AND ECONOMIC GROWTH',
      'goal3': 'GOAL 3: GOOD HEALTH',
      'des3': "Tourism, at its heart, is all about creating moments of well-being and embracing the inherent right to travel (Article 13 of The Universal Declaration of Human Rights). Here at ResiRent, we hold a deep belief that small hosts play a pivotal role in crafting an experience where guests not only find a place to stay but truly feel warmly welcomed and at ease. The commitment of small hosts to well-being goes beyond providing a comfortable stay; it's about fostering genuine connections. We actively encourage small hosts to engage with their guests, sharing insights and helpful tips to ensure their safety and enhance their overall experience. Whether it's venturing into tour skiing without a guide, exploring a hiking trail without proper preparation, or simply enjoying a responsible drink, it is important to openly communicate potential risks! And when it comes to the safety of our little travelers, small hosts should take extra measures, such as providing a first aid kit. Because at ResiRent we believe that, your well-being is not just a commitment; it's a warm and sincere invitation to a home away from home.",
      'des13': 'Small hosting and environmental sustainability go hand in hand! Small hosts often exhibit more efficient energy use compared to larger players such as hotels and resorts. Additionally, our houses are well-insulated, resulting in excellent EPC ratings. Furthermore, many small hosts pay attention to the details: refilling shampoo bottles, managing waste effectively, and encouraging guests to use public transport, among other practices. ResiRent offers solutions such as stickers for easy waste management and gentle reminders to turn off lights, allowing hosts to take these small steps even further. Another advantage is that people tend to stay longer in a small rental. This is a crucial factor in reducing carbon emissions related to transportation.',
      'goal13': 'GOAL 13: CLIMATE ACTION',
      'des11': 'Many regions and cities thrive on tourism, as visitors seek to experience local cuisine, support regional businesses, and immerse themselves in the culture of the area. Unfortunately, there is a downside to this phenomenon. Mass tourism can place significant strain on local communities, especially when tourists only frequent the top ten destinations found on Google. At ResiRent, we are committed to collaborating with local communities to understand their needs. Through our efforts, we have identified over-crowded spaces as a primary concern. As a solution, we encourage hosts to showcase the hidden gems of their region or city, guiding their guests to these lesser-known treasures and steering clear of overly congested areas.',
      'goal11': 'GOAL 11: SUSTAINABLE CITIES AND COMMUNITIES',
    },
    '/nl': {
      'Title': 'Onze Filosofie',
      'MainTitle': "ResiRent's Visie op Duurzaam Gastheerschap",
      'mainDescription': 'ResiRent streeft naar een harmonieuze balans tussen professionaliteit, milieubewustzijn en oprechte waardering voor lokale gemeenschappen. Onze toewijding strekt zich uit tot ver voorbij digitale tools, zoals website-templates en aangepaste boekingssystemen, tot tastbare oplossingen zoals op maat gemaakte stickers die uw gasten helpen bij verantwoord afval sorteren.',
      'subDescription': "In 2015 werkten 150 wereldleiders samen aan een ambitieuze duurzame ontwikkelingsagenda, resulterend in zeventien duidelijk gedefinieerde doelen. Deze doelen richten zich op specifieke gebieden en zijn ontworpen voor implementatie door landen, regio's, scholen en bedrijven. Elk entiteit wordt aangemoedigd om zijn duurzaamheidspositie te beoordelen en maatregelen te nemen om betrokkenheid te vergroten. Hoewel grote industrieën vooruitgang hebben geboekt, is het essentieel dat kleinere accommodaties zich bij deze beweging aansluiten. Bij ResiRent hebben we de duurzame ontwikkelingsdoelen ingedeeld in hoog, medium en laag impact. Vijf doelen zijn bijzonder relevant voor hosts van kleine accommodaties, gedetailleerd in onze kenmerkende label.",
      'goal17': 'DOEL 17: SAMENWERKING VOOR DE DOELEN',
      'des17': "Bij ResiRent werken we actief samen met lokale en regionale toerismebureaus om een diepgaand begrip te krijgen van de uitdagingen waarmee vakantieverhuur en toerisme worden geconfronteerd. Zo vragen we om hun feedback en begeleiding bij de ontwikkeling van onze websitebouwer. Dit omvat het begrijpen van cruciale criteria die op de website van een kleine host moeten worden weergegeven en het navigeren door juridische overwegingen. Naast onze samenwerking met toerismebureaus werken we samen met innovatieve start-ups die oplossingen bieden om kleine hosts te ondersteunen. Een opmerkelijk voorbeeld is Dexxter, een Belgische start-up geleid door drie jonge ondernemers, die een gebruiksvriendelijke oplossing biedt voor de btw-administratie. Bovendien gaan we partnerschappen aan met mensen die in het veld werken en vertegenwoordigers van de regio's waar onze accommodaties zich bevinden. Deze samenwerkingsaanpak stelt ons in staat om niet alleen hun behoeften te begrijpen, maar ook te implementeren, waardoor een meer op maat gemaakte en effectieve respons mogelijk is op de uitdagingen waarmee onze gemeenschap wordt geconfronteerd.",
      'des8': "Als kleine host heb je een aanzienlijke economische invloed op de regio's of steden waarin je actief bent. Deze impact strekt zich uit van het welzijn van je personeel tot actieve samenwerking met lokale bedrijven. Puttend uit onze eigen ervaring als kleine hosts met meer dan tien jaar in het vak, delen we waardevolle inzichten, inclusief tips en trucs. Bijvoorbeeld, we bieden advies over het vaststellen van eerlijke salarissen en redelijke tijdsvereisten voor je schoonmaakpersoneel. Daarnaast geven we advies over hoe samen te werken met lokale bedrijven om op een authentieke manier je vakantiehuis in te richten. Ons doel is om kleine hosts te empoweren met de kennis en expertise die nodig is om op een positieve manier bij te dragen aan hun lokale economieën en gemeenschappen.",
      'goal8': 'DOEL 8: DECENTRALE WERKGELEGENHEID EN ECONOMISCHE GROEI',
      'goal3': 'DOEL 3: GOEDE GEZONDHEID',
      'des3': "Toerisme draait in essentie om het creëren van momenten van welzijn en het omarmen van het inherente recht om te reizen (Artikel 13 van de Universele Verklaring van de Rechten van de Mens). Hier bij ResiRent hebben we diep geloof dat kleine hosts een cruciale rol spelen bij het creëren van een ervaring waar gasten niet alleen een plek vinden om te verblijven, maar zich ook oprecht welkom en op hun gemak voelen. De toewijding van kleine hosts aan welzijn gaat verder dan het bieden van een comfortabel verblijf; het gaat om het bevorderen van oprechte verbindingen. We moedigen kleine hosts actief aan om in contact te komen met hun gasten, inzichten en nuttige tips te delen om hun veiligheid te waarborgen en hun algehele ervaring te verbeteren. Of het nu gaat om het wagen aan toerskiën zonder gids, het verkennen van een wandelpad zonder de juiste voorbereiding, of gewoon genieten van een verantwoord drankje, het is belangrijk om potentiële risico's openlijk te communiceren! En als het gaat om de veiligheid van onze kleine reizigers, zouden kleine hosts extra maatregelen moeten nemen, zoals het verstrekken van een EHBO-kit. Omdat wij bij ResiRent geloven dat jouw welzijn niet slechts een belofte is; het is een warme en oprechte uitnodiging naar een thuis weg van huis.",
      'des13': "Kleinschalig gastheerschap en milieuvriendelijkheid gaan hand in hand! Kleine hosts vertonen vaak een efficiënter energiegebruik in vergelijking met grotere spelers zoals hotels en resorts. Bovendien zijn onze huizen goed geïsoleerd, resulterend in uitstekende EPC-scores. Veel kleine hosts besteden ook aandacht aan de details: het bijvullen van shampoo-flessen, effectief afvalbeheer, en het aanmoedigen van gasten om gebruik te maken van het openbaar vervoer, en nog veel meer! ResiRent biedt oplossingen zoals stickers voor eenvoudig afvalbeheer en vriendelijke herinneringen om het licht uit te doen, om deze kleine stappen nog verder te brengen. Een ander voordeel is dat mensen vaak langer verblijven in een kleine verhuur. Dit is een cruciale factor bij het verminderen van de uitstoot van koolstof als gevolg van transport.",
      'goal13': 'DOEL 13: KLIMAATACTIE',
      'des11': "Veel regio's en steden gedijen op toerisme, omdat bezoekers lokale gerechten willen proeven, regionale bedrijven willen ondersteunen en zich willen onderdompelen in de cultuur van het gebied. Helaas is er een keerzijde aan dit fenomeen. Massatoerisme kan aanzienlijke druk uitoefenen op lokale gemeenschappen, vooral wanneer toeristen alleen de top tien bestemmingen opzoeken op Google. Bij ResiRent zijn we toegewijd om samen te werken met lokale gemeenschappen om hun behoeften te begrijpen. Door onze inspanningen hebben we overvolle ruimtes geïdentificeerd als een belangrijk aandachtspunt. Als oplossing moedigen we hosts aan om de verborgen parels van hun regio of stad te laten zien, hun gasten te begeleiden naar deze minder bekende schatten en drukke gebieden te vermijden.",
      'goal11': 'DOEL 11: DUURZAME STEDEN EN GEMEENSCHAPPEN',
    },
    '/de': {
      'groupTitle': 'VERANTWORTLICHE KLEINE GASTGEBER',
      'mainTitle': 'Für Wen Sind Wir Da?',
      'description': "Verantwortung",
      'text': 'Wir sind fest davon überzeugt, dass verantwortliches Gastgeben der Grundpfeiler für nachhaltigen Tourismus ist. Unsere Lösungen basieren auf Nachhaltigkeit, und unser Bekenntnis zu diesem Prinzip zeigt sich in jedem Aspekt unseres Service. Unsere Website enthält zum Beispiel eine dedizierte Vorschlagsseite. Diese Seite führt Touristen zu deinen versteckten Schätzen, reduziert die Überlastung von überfüllten Touristenattraktionen und trägt zur Blüte deiner Stadt oder Region bei.',
      'description2': 'Für kleine Gastgeber',
      'text2': 'Unsere Dienstleistungen sind darauf ausgerichtet, die Bedürfnisse von Gastgebern zu erfüllen, ganz gleich, ob du eine gemütliche Wohnung unter deinem Haus, ein geliebtes Bergdomizil für deine Familie oder ein charmantes Studio im Herzen deiner Lieblingsstadt hast. Wir engagieren uns für die Unterstützung von kleinen Gastgebern und bieten unsere Dienste für diejenigen mit bis zu drei Unterkünften an.',
      'description3': 'Vielfältige Hosting-Optionen',
      'text3': 'Hosting kann in vielen Formen auftreten. Ob du ein Boot, eine Berghütte oder etwas völlig Einzigartiges besitzt, wir sind hier, um Gastgeber aller Art zu unterstützen. Unser Ziel ist es, jeden Gastgeber auf seine eigene Art und Weise erfolgreich zu machen.',
    },
  };

  return (
    <Box>
      <Image
        src={image}
        alt="our philosophy"
        height={400}
      ></Image>
      <Container size="lg" py={100} align="center">

        <Group position="center">
          <Title order={4} weight={100} align="center" color="resirentPink">
            {content[languagePrefix].Title}
          </Title>
        </Group>

        <Title order={2} className={classes.title} ta="center" mt="sm">
          {content[languagePrefix].MainTitle}
        </Title>

        <Text className={classes.description} my="lg">
          {content[languagePrefix].mainDescription}
        </Text>

        <Text className={classes.description} my="lg">
          {content[languagePrefix].subDescription}
        </Text>

        <Image m="md" src={gstc} align="center" alt="GSTC" width="80%" />
      </Container>

      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          Hello
        </Grid.Col>
      </Grid>

      <Box my="lg">
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          align={{ base: 'center', sm: 'initial' }}
          justify={{ base: 'center', sm: 'space-between' }}
          m="md"
        >
          <Center bg="resirent">
            <Image
              ref={refCommunity}
              m="xl"
              width={150}
              src={hoveredCommunity ? community : communityGoal}
              alt="Community goal"
            />
          </Center>
          <Box m="xl">
            <Title order={4} weight={300} className={classes.title} color="resirentPink">
              {content[languagePrefix].goal11}
            </Title>

            <Text className={classes.description} my="lg">
              {content[languagePrefix].des11}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box my="lg">
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          align={{ base: 'center', sm: 'initial' }}
          justify={{ base: 'center', sm: 'space-between' }}
          m="md"
        >
          <Center bg="resirent">
            <Image
              ref={refPartnership}
              m="xl"
              width={150}
              src={hoveredPartnership ? partnership : parthershipGoal}
              alt="Partnership goal"
            />
          </Center>
          <Box m="xl">
            <Title order={4} weight={300} className={classes.title} color="resirentPink">
              {content[languagePrefix].goal17}
            </Title>

            <Text className={classes.description} my="lg">
              {content[languagePrefix].des17}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box my="lg">
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          align={{ base: 'center', sm: 'initial' }}
          justify={{ base: 'center', sm: 'space-between' }}
          m="md"
        >
          <Center bg="resirent">
            <Image
              ref={refClimate}
              m="xl"
              width={150}
              src={hoveredClimate ? climateGoal : climate}
              alt="Climate goal"
            />
          </Center>
          <Box m="xl">
            <Title order={4} weight={300} className={classes.title} color="resirentPink">
              {content[languagePrefix].goal13}
            </Title>

            <Text className={classes.description} my="lg">
              {content[languagePrefix].des13}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box my="lg">
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          align={{ base: 'center', sm: 'initial' }}
          justify={{ base: 'center', sm: 'space-between' }}
          m="md"
        >
          <Center bg="resirent">
            <Image
              ref={refEconomy}
              m="xl"
              width={150}
              src={hoveredEconomy ? economy : economyGoal}
              alt="Economy goal"
            />
          </Center>
          <Box m="xl">
            <Title order={4} weight={300} className={classes.title} color="resirentPink">
              {content[languagePrefix].goal8}
            </Title>

            <Text className={classes.description} my="lg">
              {content[languagePrefix].des8}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box my="lg">
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          align={{ base: 'center', sm: 'initial' }}
          justify={{ base: 'center', sm: 'space-between' }}
          m="md"
        >
          <Center bg="resirent">
            <Image
              ref={refHealth}
              m="xl"
              width={150}
              src={hoveredHealth ? health : HealthGoal}
              alt="Health goal"
            />
          </Center>
          <Box m="xl">
            <Title order={4} weight={300} className={classes.title} color="resirentPink">
              {content[languagePrefix].goal3}
            </Title>

            <Text className={classes.description} my="lg">
              {content[languagePrefix].des3}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box >
  );
}
