import React from 'react'
import stickers_batteries from '../../assets/stickers/ResiStickerBatteries.jpg'
import stickers_bottles from '../../assets/stickers/ResiStickerBottles.jpg'
import stickers_bottlesPlastic from '../../assets/stickers/ResiStickerBottlesPlastic.jpg'
import stickers_capsules from '../../assets/stickers/ResiStickerCapsules.jpg'
import stickers_cardbord from '../../assets/stickers/ResiStickerCardbord.jpg'
import stickers_curtains from '../../assets/stickers/ResiStickerCurtains.jpg'
import stickers_stickerfoil from '../../assets/stickers/ResiStickerFoil.jpg'
import stickers_light from '../../assets/stickers/ResiStickerLight.jpg'
import stickers_nondrinkable from '../../assets/stickers/ResiStickerNonDrinkable.jpg'
import stickers_paper from '../../assets/stickers/ResiStickerPaper.jpg'
import stickers_PE from '../../assets/stickers/ResiStickerPE.jpg'
import stickers_plastic from '../../assets/stickers/ResiStickerPlastic.jpg'
import stickers_potable from '../../assets/stickers/ResiStickerPotable.jpg'
import stickers_residual from '../../assets/stickers/ResiStickerResidual.jpg'
import stickers_compost from '../../assets/stickers/ResiStickerCompostable.jpg'
import { createStyles, Title, Image, Container, SimpleGrid, Button, rem, Group, Text } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        width: '50%',
        marginTop: rem(60),
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    controlNew: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirent[8],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirent[8],
        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function ResiAccessoires({ language }) {

    const { classes, theme } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    const images = [
        {
            src: stickers_compost,
            alt: 'Placeholder image',
            title: 'Compostable',
            description: 'Anything digestible fits this label. In most countries it comes down to fruit, vegetables and garden. Sometimes these products can be thrown in the compostable bin with a bio-degradable sack, but in some countries the sack is not allowed.',
        },
        {
            src: stickers_plastic,
            alt: 'Placeholder image',
            title: 'Plastic all',
            description: 'Includes the following product categories, which are typically disposed of in different ways and require different recycling procedures.',
        },
        {
            src: stickers_batteries,
            alt: 'Placeholder image',
            title: 'Batteries',
            description: 'A sticker to be used on a small paper basket for empty batteries to be recycled.',
        },
        {
            src: stickers_bottles,
            alt: 'Placeholder image',
            title: 'Glass',
            description: 'Glass is the product we are most familiar with to dispose of. We only made one sticker for glass, to avoid too much detail. Often, glass must be disposed by color in a collection point.',
        },
        {
            src: stickers_PE,
            alt: 'Placeholder image',
            title: 'PE&HD',
            description: 'bottles of shampoo, detergent, ...',
        },
        {
            src: stickers_bottlesPlastic,
            alt: 'Placeholder image',
            title: 'PET',
            description: 'only beverage bottles, because we bring them back to the store',
        },
        {
            src: stickers_capsules,
            alt: 'Placeholder image',
            title: 'Capsules',
            description: 'if you have a coffee machine with capsules such as a Nespresso machine, you can use this sticker. Be aware that in many cases, the companies that produce this coffee take back the capsules and even have sustainability programs (e.g., Nespresso).',
        },
        {
            src: stickers_curtains,
            alt: 'Placeholder image',
            title: 'Be careful with the cutains!',
            description: 'on several occasions we experienced guests opening the window without moving the curtains beforehand. Unfortunately, this led to damage and we had to either have the curtain repaired or even buy a new one.',
        },
        {
            src: stickers_light,
            alt: 'Placeholder image',
            title: 'Light out',
            description: 'We find it important to make a small sticker that kindly asks guests to turn off the lights.',
        },
        {
            src: stickers_nondrinkable,
            alt: 'Placeholder image',
            title: 'Non-potable water',
            description: 'Not suitable for drinking but may still be used for other purposes',
        },
        {
            src: stickers_paper,
            alt: 'Placeholder image',
            title: 'Paper',
            description: 'Be sure to dispose of any plastic wrap first.',
        },
        {
            src: stickers_stickerfoil,
            alt: 'Placeholder image',
            title: 'Foil',
            description: 'No PET and NO-HD, yoghurt cups, pre-prepared food packages, etc.',
        },
        {
            src: stickers_cardbord,
            alt: 'Placeholder image',
            title: 'Cardboard',
            description: 'Cardboard is a growing and huge problem. One often has not enough with one bin for cardboard. Separate the cardboard from the paper though. Both products are recycled differently.',
        },
        {
            src: stickers_potable,
            alt: 'Placeholder image',
            title: 'Potable water',
            description: 'Often water is drinkable, so you do not need to buy bottles!',
        },
        {
            src: stickers_residual,
            alt: 'Placeholder image',
            title: 'Residual',
            description: 'for residual waste, we took a humorous icon that looks a bit hopeless because there are no options left.',
        },
    ];

    const sticker = {
        'subtitle': 'Recycling made easy!',
        'title': 'ResiRent Stickers',
        'paragraph': 'Welcome to ResiRent Stickers, the perfect way to support our start-up funding campaign while promoting responsible rental practices! Our stickers are a simple and affordable way to make a difference. By purchasing our stickers, you are not only contributing to our campaign, but you are also taking the first step towards responsible rental. Our campaign is aimed at creating a rental platform that provides a transparent and trustworthy experience for both tenants and landlords. We believe that by promoting responsible rental practices, we can make a positive impact on the environment and society as a whole. With March 13, 2023, being International Recycling Day, it is the perfect time to show your support for our cause. By getting our stickers, you can proudly display your commitment to responsible rental and help us reach our funding goals.',
        'button': 'Get the stickers',
        'href': "https://resirent.myshopify.com/products/resistickers",
        'paragraph2': 'We spent a lot of time deciding how to wire up the actual content of the stickers. We looked at different countries and their waste management policies, tried to combine our experiences with guests and as guests, and crafted the stickers to be sustainable in every way. To learn more about the specific purposes of each sticker, simply hover over them!',
    }

    const content = {
        '/': {
            'groupTitle': 'ResiRent Stickers',
            'mainTitle': 'Eco-Friendly Stickers: Effortless Recycling with Positive Impact',
            'description': "Many guests may not feel motivated to recycle while on vacation, but the implementation of a simple sticker solution can make the process much easier and more accessible for everyone. With clearly labeled bins and helpful reminders, recycling can become a seamless and effortless part of any holiday stay.",
            'description2': "We spent a lot of time deciding how to wire up the actual content of the stickers. We looked at different countries and their waste management policies and tried to combine our experiences with guests and as guests, and crafted the stickers to be sustainable in every way. It was a pleasure to see that our hard work paid off and that the guests were very enthusiastic about it! The magic of the stickers comes from the illustrations that give a positive and friendly hint, and from the small touch that the stickers give that contributes to our guests' positive impressions of our accommodation as a place that shares their values and seeks to make a positive impact.",
            'hrefText': "Have a look at our blog post about the waste management",
            'href': '/blog/2',
        },
        '/nl': {
            'groupTitle': 'ResiRent Stickers',
            'mainTitle': 'Milieuvriendelijke Stickers: Probleemloos Recyclen met Positieve Impact',
            'description': "Veel gasten voelen zich mogelijk niet gemotiveerd om te recyclen tijdens hun vakantie, maar de implementatie van een eenvoudige stickeroplossing kan het proces veel eenvoudiger en toegankelijker maken voor iedereen. Met duidelijk gelabelde bakken en handige herinneringen kan recyclen een naadloos en moeiteloos onderdeel worden van elk vakantieverblijf.",
            'description2': "We hebben veel tijd besteed aan het bedenken van de inhoud van de stickers. We hebben gekeken naar verschillende landen en hun afvalbeheerbeleid en hebben geprobeerd onze ervaringen als gasten te combineren en de stickers op een duurzame manier te maken. Het was een genoegen om te zien dat ons harde werk vruchten heeft afgeworpen en dat de gasten er zeer enthousiast over waren! De magie van de stickers komt voort uit de illustraties die een positieve en vriendelijke hint geven, en uit het kleine detail dat de stickers bijdragen aan de positieve indrukken van onze accommodatie als een plek die dezelfde waarden deelt en streeft naar een positieve impact.",
            'hrefText': "Bekijk onze blogpost over afvalbeheer",
            'href': '/blog/2',
        },
        '/de': {
            'groupTitle': 'ResiRent Stickers',
            'mainTitle': 'Umweltfreundliche Aufkleber: Müheloses Recycling mit Positiver Wirkung',
            'description': "Viele Gäste fühlen sich möglicherweise nicht motiviert, während ihres Urlaubs zu recyceln, aber die Implementierung einer einfachen Aufkleberlösung kann den Prozess für alle viel einfacher und zugänglicher machen. Mit klar gekennzeichneten Behältern und hilfreichen Erinnerungen kann Recycling zu einem nahtlosen und mühelosen Bestandteil jedes Urlaubsaufenthalts werden.",
            'description2': "Wir haben viel Zeit damit verbracht, zu entscheiden, wie der eigentliche Inhalt der Aufkleber gestaltet werden soll. Wir haben uns verschiedene Länder und ihre Abfallwirtschaftspolitiken angesehen, unsere Erfahrungen als Gäste und Gastgeber kombiniert und die Aufkleber auf nachhaltige Weise gestaltet. Es war eine Freude zu sehen, dass unsere harte Arbeit Früchte getragen hat und die Gäste sehr begeistert davon waren! Die Magie der Aufkleber kommt von den Illustrationen, die einen positiven und freundlichen Hinweis geben, und von der kleinen Note, die die Aufkleber dazu beitragen, dass unsere Gäste einen positiven Eindruck von unserer Unterkunft als einem Ort erhalten, der ihre Werte teilt und eine positive Wirkung erzielen möchte.",
            'hrefText': "Schauen Sie sich unseren Blogbeitrag zum Thema Abfallwirtschaft an",
            'href': '/blog/2',
        },
    };

    return (
        <>
            <Container size="lg" py={100} align="center">
                <Group position="center">
                    <Title order={4} weight={300} align="center" color="resirentPink">
                        {content[languagePrefix].groupTitle}
                    </Title>
                </Group>

                <Title order={2} className={classes.title} ta="center" mt="sm">
                    {content[languagePrefix].mainTitle}
                </Title>

                <Text className={classes.description} ta="center" my="lg">
                    {content[languagePrefix].description}
                </Text>

                <Text className={classes.description} ta="center" my="lg">
                    {content[languagePrefix].description2}
                </Text>

                <Button align="center" mb="md" color="resirentPink" radius="xl" component="a" href="https://resirent.myshopify.com/products/resistickers" mt="md" size="md" className={classes.control}>
                    Get the stickers
                </Button>
                <Button align="center" m="md" radius="xl" component="a" href={content[languagePrefix].href} mt="md" size="md" className={classes.controlNew}>
                    Read the blog post
                </Button>

                <SimpleGrid cols={4} spacing={20} mt="md">
                    <Image src={images[0].src} alt={images[0].alt} title={images[0].title} description={images[0].description} />
                    <Image src={images[1].src} alt={images[1].alt} title={images[1].title} description={images[1].description} />
                    <Image src={images[2].src} alt={images[2].alt} title={images[2].title} description={images[2].description} />
                    <Image src={images[3].src} alt={images[3].alt} title={images[3].title} description={images[3].description} />
                    <Image src={images[4].src} alt={images[4].alt} title={images[4].title} description={images[4].description} />
                    <Image src={images[5].src} alt={images[5].alt} title={images[5].title} description={images[5].description} />
                    <Image src={images[6].src} alt={images[6].alt} title={images[6].title} description={images[6].description} />
                    <Image src={images[7].src} alt={images[7].alt} title={images[7].title} description={images[7].description} />
                    <Image src={images[8].src} alt={images[8].alt} title={images[8].title} description={images[8].description} />
                    <Image src={images[9].src} alt={images[9].alt} title={images[9].title} description={images[9].description} />
                    <Image src={images[10].src} alt={images[10].alt} title={images[10].title} description={images[10].description} />
                    <Image src={images[11].src} alt={images[11].alt} title={images[11].title} description={images[11].description} />
                    <Image src={images[12].src} alt={images[12].alt} title={images[12].title} description={images[12].description} />
                    <Image src={images[13].src} alt={images[13].alt} title={images[13].title} description={images[13].description} />
                    <Image src={images[14].src} alt={images[14].alt} title={images[14].title} description={images[14].description} />
                </SimpleGrid>
            </Container>

        </>
    );
}
