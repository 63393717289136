import React from 'react';
import Calendar from './Calendar'
import { Container } from '@mantine/core'

const CalendarExample = () => {

    return (
        <>
            <Container p="md" align="center">
                <Calendar/>
            </Container>
        </>
    )

}

export default CalendarExample