import React from 'react'
import image from '../../../assets/products/Colors.png';
import image2 from '../../../assets/products/talen.png';
import image3 from '../../../assets/products/resirent.org.png';
import { createStyles, rem, Title, Box, Container, Text, Image, Paper, SimpleGrid } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        alignItems: 'center',
        width: '80%',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function MainProductThird({ language }) {

    const { classes } = useStyles();

    const content = {
        '/': {
            'groupTitle': 'GREEN HOSTING',
            'mainTitle': 'Responsible hosting starts with a green hosting provider',
            'description': 'We are using green hosting services, minimizing our environmental footprint.',
        },
        '/nl': {
            'groupTitle': 'GROENE HOSTING',
            'mainTitle': 'Verantwoord hosten begint bij een groene hostingprovider',
            'description': 'Wij maken gebruik van groene hostingdiensten en minimaliseren onze ecologische voetafdruk.',
        },
        '/de': {
            'groupTitle': 'GRÜNES HOSTING',
            'mainTitle': 'Verantwortungsvolles Hosting beginnt bei einem grünen Hosting-Anbieter',
            'description': 'Wir nutzen grüne Hosting-Dienste und minimieren unseren ökologischen Fußabdruck.',
        },
    };

    const content2 = {
        '/': {
            'groupTitle': 'MULTILINGUAL SUPPORT',
            'mainTitle': 'Reach a wider audience with less effort',
            'description': 'Easily add multiple languages to cater to a diverse audience.',
        },
        '/nl': {
            'groupTitle': 'MEERTALIGE ONDERSTEUNING',
            'mainTitle': 'Bereik een breder publiek met minder moeite',
            'description': 'Voeg eenvoudig meerdere talen toe om een divers publiek te bereiken.',
        },
        '/de': {
            'groupTitle': 'MEHRSPRACHEN-UNTERSTÜTZUNG',
            'mainTitle': 'Erreichen Sie ein breiteres Publikum mit weniger Aufwand',
            'description': 'Fügen Sie problemlos mehrere Sprachen hinzu, um ein vielfältiges Publikum anzusprechen.',
        },
    };

    const content3 = {
        '/': {
            'groupTitle': 'PRIVATE POLICY',
            'mainTitle': 'Protect Yourself Legally with a Privacy Policy',
            'description': 'Benefit from built-in Terms and Conditions to ensure your legal protection.',
        },
        '/nl': {
            'groupTitle': 'PRIVACYBELEID',
            'mainTitle': 'Bescherm uzelf juridisch met een privacybeleid',
            'description': 'Profiteer van ingebouwde algemene voorwaarden om uw juridische bescherming te waarborgen.',
        },
        '/de': {
            'groupTitle': 'DATENSCHUTZRICHTLINIE',
            'mainTitle': 'Schützen Sie sich rechtlich mit einer Datenschutzrichtlinie',
            'description': 'Nutzen Sie integrierte Allgemeine Geschäftsbedingungen, um Ihre rechtliche Absicherung zu gewährleisten.',
        },
    };

    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box>
            <Container size="lg" pt={100}>
                <SimpleGrid cols={2} breakpoints={[{ maxWidth: 768, cols: 1 }, { maxWidth: 1200, cols: 2 }]} spacing={10}>

                    <Box>
                        <Title order={4} weight={300} className={classes.title} color="resirentPink">
                            {content[languagePrefix].groupTitle}
                        </Title>

                        <Title order={2} className={classes.title} my="sm" display={'block'}>
                            {content[languagePrefix].mainTitle}
                        </Title>

                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].description}
                        </Text>
                    </Box>

                    <Paper shadow="xl" pb="md" pt="md" radius="xl" withBorder className={classes.paperProduct} >
                        <Image src={image3} alt="Website Editing" />
                    </Paper>

                </SimpleGrid>
            </Container>
            <Container size="lg" pt={100}>
                <SimpleGrid cols={2} breakpoints={[{ maxWidth: 768, cols: 1 }, { maxWidth: 1200, cols: 2 }]} spacing={10}>

                    <Paper shadow="xl" pb="md" pt="md" radius="xl" withBorder className={classes.paperProduct} >
                        <Image src={image2} alt="Website Editing" />
                    </Paper>

                    <Box>
                        <Title order={4} weight={300} className={classes.title} color="resirentPink">
                            {content2[languagePrefix].groupTitle}
                        </Title>

                        <Title order={2} className={classes.title} my="sm" display={'block'}>
                            {content2[languagePrefix].mainTitle}
                        </Title>

                        <Text className={classes.description} my="lg">
                            {content2[languagePrefix].description}
                        </Text>
                    </Box>

                </SimpleGrid>
            </Container>
            <Container size="lg" pt={100} pb={100}>
                <Box textAlign="center">
                    <Title order={4} weight={300} className={classes.title} color="resirentPink" align="center">
                        {content3[languagePrefix].groupTitle}
                    </Title>

                    <Title order={2} className={classes.title} my="sm" align="center">
                        {content3[languagePrefix].mainTitle}
                    </Title>

                    <Text className={classes.description} my="lg" align="center">
                        {content3[languagePrefix].description}
                    </Text>
                </Box>
            </Container>

        </Box >
    )
}
