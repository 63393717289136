import React, { useState } from 'react';
import axios from '../../api/axios';
import {
    Button,
    Group,
    TextInput,
    Box,
    Title,
    Checkbox,
    Container,
    Text,
    Paper,
    Anchor,
} from '@mantine/core';

const WEBSITE_URL = '/websitejson';

export default function Demo() {
    const art = 'newsletter';
    const title = 'workshop';

    const [username, setUsername] = useState('');
    const [checkBox, setCheckBox] = useState(false);

    // JSON input
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [straat, setStraat] = useState('');
    const [stad, setStad] = useState('');
    const [postcode, setPostcode] = useState('');
    const [nummer, setNummer] = useState('');
    const [nameAccommodation, setNameAccommodation] = useState('');
    const [typeAccommodation, setTypeAccommodation] = useState('');
    const [email, setEmail] = useState('');
    const [domainNameAvailable, setDomainNameAvailable] = useState('');
    const [success, setSuccess] = useState(false);

    const [domainNameBox, setDomainNameBox] = useState(false);

    const handleAddClick = async (event) => {
        event.preventDefault();

        // Perform manual validation here
        if (!email || !/.+@.+\..+/.test(email)) {
            console.log('Invalid email address');
            return;
        }

        // Validation passed, proceed with form submission
        const newReview = {
            id: Date.now(),
            name: name,
            lastName: lastName,
            straat: straat,
            stad: stad,
            postcode: postcode,
            nummer: nummer,
            nameAccommodation: nameAccommodation,
            typeAccommodation: typeAccommodation,
            email: email,
            domainNameAvailable: domainNameAvailable,
        };

        setUsername(email);
        try {
            await axios.put(WEBSITE_URL, {
                username,
                art,
                title,
                content: [newReview],
            });
            setSuccess(true);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            {success ? (
                <Container size={420} my={40}>
                    <Title ta="center">Je bent aangemeld voor de workshop!</Title>
                    <Text c="dimmed" size="sm" ta="center" mt={5}>
                        Wil je al wat meer weten over de inhoud van de websitebouwer, neem dan zeker een kijkje op deze pagina:{' '}
                        <Anchor size="sm" component="a" href="/nl/producten/resirent-website">
                            ResiWebsite
                        </Anchor>
                    </Text>
                </Container>
            ) : (
                <Container>
                    <Box mt="lg" mb="xl">
                        <Title ta="center" mt="lg" mb="lg">
                            Aanmelden workshop
                        </Title>
                        <Text ta="center" mt="lg" mb="lg">
                            Vul onderstaand formulier in om je aan te melden voor de workshop. We gebruiken deze informatie om je op de hoogte te houden van de workshop en om ons goed voor te bereiden.
                        </Text>
                        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                            <Title mb="md" order={3} ta="left">Persoonlijke informatie</Title>
                            <TextInput
                                type="text"
                                id="title"
                                value={lastName}
                                onChange={(event) => setLastName(event.target.value)}
                                label="Naam"
                                placeholder="Naam"
                                required
                            />
                            <TextInput
                                type="text"
                                id="voornaam"
                                onChange={(event) => setName(event.target.value)}
                                value={name}
                                label="Voornaam"
                                placeholder="Voornaam"
                                required
                            />
                            <TextInput
                                type="text"
                                id="email"
                                value={email}
                                onChange={(event) => {
                                    const newEmail = event.target.value;
                                    setEmail(newEmail);
                                    setUsername(newEmail);
                                }}
                                label="Email"
                                placeholder="name@name.com"
                                required
                            />

                            <Title mb="md" mt="lg" order={3} ta="left">Informatie over jouw accommodatie</Title>
                            <TextInput
                                type="text"
                                id="straat"
                                value={straat}
                                onChange={(event) => setStraat(event.target.value)}
                                label="Straat"
                                placeholder="Straat"
                                required
                            />
                            <TextInput
                                type="text"
                                id="stad"
                                value={stad}
                                onChange={(event) => setStad(event.target.value)}
                                label="Stad"
                                placeholder="Stad"
                            />
                            <TextInput
                                type="text"
                                id="postcode"
                                value={postcode}
                                onChange={(event) => setPostcode(event.target.value)}
                                label="Postcode"
                                placeholder="Postcode"
                            />
                            <TextInput
                                type="text"
                                id="nummer"
                                value={nummer}
                                onChange={(event) => setNummer(event.target.value)}
                                label="Nummer"
                                placeholder="Nummer"
                                required
                            />
                            <TextInput
                                type="text"
                                id="nameAccommodation"
                                value={nameAccommodation}
                                onChange={(event) => setNameAccommodation(event.target.value)}
                                label="Naam accommodatie"
                                placeholder="Naam accommodatie"
                                required
                            />
                            <TextInput
                                type="text"
                                id="typeAccommodation"
                                value={typeAccommodation}
                                onChange={(event) => setTypeAccommodation(event.target.value)}
                                label="Type accommodatie"
                                placeholder="Type accommodatie"
                                required
                            />
                            {/* <Checkbox
                                mt="md"
                                mb="md"
                                value={domainNameBox}
                                onChange={(event) => setDomainNameBox(!domainNameBox)}
                                label="Heb je al een domeinnaam?"
                                required
                            />
                            {domainNameBox ? (
                                <TextInput
                                    type="text"
                                    id="domainNameAvailable"
                                    value={domainNameAvailable}
                                    onChange={(event) => setDomainNameAvailable(event.target.value)}
                                    label="Wat is jouw domeinnaam?"
                                    placeholder="www.mydomain.com"
                                    required
                                />
                            ) : (
                                <TextInput
                                    type="text"
                                    id="domainNameAvailable"
                                    value={domainNameAvailable}
                                    onChange={(event) => setDomainNameAvailable(event.target.value)}
                                    label="Heb je al een voorkeur voor een domeinnaam?"
                                    placeholder="www.mydomain.com"
                                />
                            )} */}
                            {/* <Checkbox
                                mt="md"
                                value={checkBox}
                                onChange={(event) => setCheckBox(!checkBox)}
                                label="Ik accepteer de algemene voorwaarden."
                                required
                            /> */}
                            <Group justify="flex-end" mt="md">
                                <Button
                                    type="submit"
                                    variant="outline"
                                    color="resirent"
                                    fullWidth
                                    mt="lg"
                                    disabled={!email || !/.+@.+\..+/.test(email)}
                                    onClick={handleAddClick}
                                >
                                    Meld me aan
                                </Button>
                            </Group>
                        </Paper>
                    </Box>
                </Container>
            )}
        </>
    );
}
