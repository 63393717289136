import React from 'react'
import image from '../../../assets/homepage/Calendar.png';
import { createStyles, Group, rem, Button, Title, Box, Container, Text, Image, Paper, List } from '@mantine/core';
import { IconHome } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        width: '50%',
        marginTop: rem(60),
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function ResiCalendarHeader({ language }) {

    const { classes, theme } = useStyles();
    const { t } = useTranslation();

    const content = {
        '/': {
            'groupTitle': 'CALENDAR',
            'mainTitle': 'Master Your Bookings: Elevate Your Hospitality with Our Innovative Calendar Plug-In',
            'description': "We're excited to share the latest developments in our calendar plug-in, designed to enhance your booking experience. As we continue to refine and expand our features, your feedback is invaluable. We look forward to providing you with a versatile and user-friendly tool for your booking needs.",
        },
        '/nl': {
            'groupTitle': 'CALENDAR',
            'mainTitle': 'Beheer Uw Boekingen: Verrijk Uw Gastvrijheid met Onze Innovatieve Kalender Plug-In',
            'description': "We zijn enthousiast om de laatste ontwikkelingen in onze kalender plug-in te delen, ontworpen om uw boekingservaring te verbeteren. Terwijl we doorgaan met het verfijnen en uitbreiden van onze functies, is uw feedback van onschatbare waarde. We kijken ernaar uit om u een veelzijdig en gebruiksvriendelijk instrument te bieden voor uw boekingsbehoeften.",
        },
        '/de': {
            'groupTitle': 'CALENDAR',
            'mainTitle': 'Beherrschen Sie Ihre Buchungen: Steigern Sie Ihre Gastfreundschaft mit Unserem Innovativen Kalender-Plug-In',
            'description': "Wir freuen uns, die neuesten Entwicklungen in unserem Kalender-Plug-In zu teilen, das darauf abzielt, Ihre Buchungserfahrung zu verbessern. Während wir unsere Funktionen weiter verfeinern und erweitern, ist Ihr Feedback von unschätzbarem Wert. Wir freuen uns darauf, Ihnen ein vielseitiges und benutzerfreundliches Werkzeug für Ihre Buchungsanforderungen bereitzustellen.",
        },
    };

    const features = {
        '/': {
            'availabilityCheck': "Guests can easily check your availability at their convenience.",
            'flexiblePricing': "Set prices effortlessly based on seasons, specific days, or other criteria, and have it visually displayed on the calendar.",
            'reservationNotifications': "Guests can reserve a range of days, triggering an automatic email notification to keep you informed.",
            'seamlessIntegration': "Our plug-in seamlessly integrates with Booking.com and Airbnb, streamlining your operations across platforms.",
            'paymentIntegration': "Coming Soon: We are currently working on integrating a payment method, offering the convenience of direct bookings. However, we understand that preferences vary, and we believe in having conversations to tailor the experience according to your needs."
        },
        '/nl': {
            'availabilityCheck': "Gasten kunnen eenvoudig uw beschikbaarheid controleren op hun gemak.",
            'flexiblePricing': "Stel prijzen moeiteloos in op basis van seizoenen, specifieke dagen of andere criteria en laat ze visueel weergeven op de kalender.",
            'reservationNotifications': "Gasten kunnen een reeks dagen reserveren, waardoor automatische e-mailmeldingen worden geactiveerd om u op de hoogte te houden.",
            'seamlessIntegration': "Onze plug-in integreert naadloos met Booking.com en Airbnb, waardoor uw activiteiten op verschillende platforms worden gestroomlijnd.",
            'paymentIntegration': "Binnenkort beschikbaar: We werken momenteel aan de integratie van een betaalmethode, waardoor het gemak van directe boekingen mogelijk is. We begrijpen echter dat voorkeuren variëren en zijn ervan overtuigd dat persoonlijke gesprekken de ervaring kunnen afstemmen op uw behoeften."
        },
        '/de': {
            'availabilityCheck': "Gäste können ganz einfach Ihre Verfügbarkeit nach Belieben überprüfen.",
            'flexiblePricing': "Setzen Sie Preise mühelos je nach Saison, bestimmten Tagen oder anderen Kriterien fest und lassen Sie sie übersichtlich auf dem Kalender anzeigen.",
            'reservationNotifications': "Gäste können eine Reihe von Tagen reservieren, was automatische E-Mail-Benachrichtigungen auslöst, um Sie auf dem Laufenden zu halten.",
            'seamlessIntegration': "Unsere Plug-In integriert sich nahtlos mit Booking.com und Airbnb und optimiert so Ihre Abläufe über verschiedene Plattformen hinweg.",
            'paymentIntegration': "Kommt bald: Wir arbeiten derzeit an der Integration einer Zahlungsmethode, die die Bequemlichkeit direkter Buchungen bietet. Wir verstehen jedoch, dass Präferenzen variieren, und setzen auf persönliche Gespräche, um die Erfahrung gemäß Ihren Bedürfnissen anzupassen."
        },
    };

    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box>
            <Container size="lg" py={100} align="center">
                <Group position="center">
                    <Title order={4} weight={300} align="center" color="resirentPink">
                        {content[languagePrefix].groupTitle}
                    </Title>
                </Group>

                <Title order={2} className={classes.title} ta="center" mt="sm">
                    {content[languagePrefix].mainTitle}
                </Title>

                <Text className={classes.description} ta="center" my="lg">
                    {content[languagePrefix].description}
                </Text>
                <Button align="center" mb="md" color="resirentPink" radius="xl" component="a" href={t("resiweb.calendar")} mt="md" size="md" className={classes.control}>
                    {t("resiweb.title")}
                </Button>

                <Paper shadow="xl" radius="xl" withBorder className={classes.paperProduct}>
                    <Image radius="lg" src={image} alt="ResiRent" className={classes.imageProduct} />
                </Paper>
                <List
                    mt={30}
                    spacing="sm"
                    size="sm"
                    icon={
                        <IconHome size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                    }
                >
                    <Text className={classes.description} >
                        <List.Item>
                            {features[languagePrefix].availabilityCheck}

                        </List.Item>
                        <List.Item>
                            {features[languagePrefix].flexiblePricing}
                        </List.Item>
                        <List.Item>
                            {features[languagePrefix].reservationNotifications}
                        </List.Item>
                        <List.Item>
                            {features[languagePrefix].seamlessIntegration}
                        </List.Item>
                    </Text>
                </List>
            </Container>
            {/* <Box align="right">
                        <Text component='a' href={imageButton[currentImage].link} order={1} weight={400} color="dimmed" mt="md">
                            {imageButton[currentImage].name}
                        </Text>
                    </Box> */}
        </Box >
    )
}
