import React, { useState } from 'react';
import { Modal, Button, Group, Text } from '@mantine/core';

const ConfirmModal = ({ isOpen, onClose, onAccept, onDecline, bookingInfo }) => {
    const [showDeclineConfirmation, setShowDeclineConfirmation] = useState(false);

    if (!bookingInfo) return null;

    const handleDeclineClick = () => {
        setShowDeclineConfirmation(true);
    };

    const handleConfirmDecline = () => {
        setShowDeclineConfirmation(false);
        onDecline();
    };

    const handleCancelDecline = () => {
        setShowDeclineConfirmation(false);
    };

    return (
        <>
            <Modal
                opened={isOpen}
                onClose={onClose}
                title="Booking Confirmation"
            >
                <Text>Do you want to accept or decline this booking request?</Text>
                <Text size="sm">Check-in date: {bookingInfo.start}</Text>
            <Text size="sm">Check-out date: {bookingInfo.end}</Text>
            <Text size="sm">Label: {bookingInfo.label}</Text>
            <Text size="sm">Status: {bookingInfo.booked}</Text>
                <Group position="center" mt="md">
                    <Button onClick={onAccept}>Accept</Button>
                    <Button color="red" onClick={handleDeclineClick}>Decline</Button>
                </Group>

            </Modal>

            <Modal
                opened={showDeclineConfirmation}
                onClose={handleCancelDecline}
                title="Confirm Decline"
            >
                <Text>Do you really want to decline this booking request?</Text>
                <Group position="center" mt="md">
                    <Button color="red" onClick={handleConfirmDecline}>
                        Yes, Decline
                    </Button>
                    <Button variant="default" onClick={handleCancelDecline}>
                        No, Keep
                    </Button>
                </Group>
            </Modal>
        </>
    );
};

export default ConfirmModal;
