import { createStyles, Text, Title, TextInput, Button, Image, rem } from '@mantine/core';
import image from '../../assets/homepage/newsletter.svg';

const useStyles = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: `calc(${theme.spacing.xl} * 2)`,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column-reverse',
            padding: theme.spacing.xl,
        },
    },

    image: {
        maxWidth: '40%',

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },

    body: {
        paddingRight: `calc(${theme.spacing.xl} * 4)`,

        [theme.fn.smallerThan('sm')]: {
            paddingRight: 0,
            marginTop: theme.spacing.xl,
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        lineHeight: 1,
        marginBottom: theme.spacing.md,
    },

    controls: {
        display: 'flex',
        marginTop: theme.spacing.xl,
    },

    inputWrapper: {
        width: '100%',
        flex: '1',
    },

    input: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 0,
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },
}));


export default function Webinar() {
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const { classes } = useStyles();

    const header = {
        '/': {
            'title': 'Wait a minute...',
            'subtitle': 'Subscribe to our newsletter!',
            'workshop': ' Join the ResiRent community! The best way to receive interesting articles about responsible hosting and updates on new upcoming features! Our newsletters are only twice a month, but you can unsubscribe at any time. It is really easy!',
            'workshopButton': "Sign up",
        },
        '/nl': {
            'title': 'Webinar: boost jouw accommodatie met een eigen website!',
            'subtitle': '1 Februari van 19:30 tot 20:15 en 8 Februari van 19:30 tot 20:15',
            'workshop': 'Overweeg jij ook al een tijdje om een eigen website voor je vakantiewoning te hebben? Neem dan zeker deel aan onze webinar. Tijdens het webinar bespreken we onder andere de voordelen, het beheer en de functionaliteiten van onze websitebouwer voor vakantiewoningen.',
            'workshopButton': "Aanmelden",
        },
        '/de': {
            'title': 'Förderung verantwortungsvoller Vermietungspraktiken',
            'subtitle': 'Bewusstes Hosting für blühenden Tourismus',
            'workshop': 'Melden Sie sich für unseren Newsletter an',
            'workshopButton': "Anmelden",
        },
    };

    // Check whether you're on the dutch page otherwise return empty string
    return (
        languagePrefix === '/nl' ?
            <div className={classes.wrapper}>
                <div className={classes.body}>
                    <Title className={classes.title}>  {header[languagePrefix].title}</Title>
                    <Text fw={500} fz="lg" mb={5}>
                        {header[languagePrefix].subtitle}
                    </Text>
                    <Text fz="sm" color='white'>
                        {header[languagePrefix].workshop}
                    </Text>
                    <Button align="center" radius="xl" component="a" mt="md" size="md" className={classes.control} href="https://forms.gle/1rtgFdBNwsxdknr6A" >
                        {header[languagePrefix].workshopButton}
                    </Button>
                </div>
            </div>
            :
            <></>
    );
}