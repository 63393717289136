import blogTax from "../assets/blogPosts/tax/tax.jpg"
import blogRecycling from "../assets/blogPosts/Recycling/AustriaLinkedinResiRent.jpg"
import blogOurStory from "../assets/blogPosts/OurStory/living_room.jpg"
import Anja from "../assets/team/Anja_profile.jpeg"
import blogCleaning from "../assets/blogPosts/cleaning/ApartmentTheCity28-12-2023_s34_s.jpg";


// ============= BlogPost Written ============= //

export const postsEn = [
    {
        id: 1,
        title: 'Our story: From Humble Beginnings to a Sustainable and Innovative Company',
        href: '',
        image: blogOurStory,
        description:
            'What began as a humble short-term rental operation has since evolved into a company that places great importance on assessing the sustainability and financial health of its own properties. Nowadays, we are focused on developing innovative products that help hosts become equipped for the shared economy of the 21st century.',
        date: 'Sep 1, 2023',
        datetime: '2023-09-1',
        reading_time: '15 min',
        category: { title: 'Good Management', href: '#' },
        author: {
            name: 'Anja Bulteel',
            role: 'Founder',
            imageUrl: Anja,
            description: "Hello readers! My name is Anja, and I'm the author of this blog post. I'm the founder of ResiRent, and I'm passionate about sustainable tourism. I decided to write this post to share my knowledge and insights on sustainable managenement of a holiday rental”, and I hope you found it helpful! In my free time, I enjoy hiking with Jeff (my dog), and I'm always looking for ways to expand my knowledge and skills. Thank you for taking the time to read my post, and I hope you'll check out more of my content in the future!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    },
    {
        id: 2,
        title: 'Recycling, on vacation too, right! ResiRent makes it incredibly easy.',
        href: '/blog/recycling',
        image: blogRecycling,
        description:
            'Many guests may not feel motivated to recycle while on vacation, but the implementation of a simple sticker solution can make the process much easier and more accessible for everyone. With clearly labeled bins and helpful reminders, recycling can become a seamless and effortless part of any holiday stay.',
        date: 'Sep 16, 2023',
        datetime: '2020-09-16',
        category: { title: 'Environment', href: '#' },
        reading_time: '10 min',
        author: {
            name: 'Anja Bulteel',
            role: 'Founder',
            imageUrl: Anja,
            description: "Hello readers! My name is Anja, and I'm the author of this blog post. I'm the founder of ResiRent, and I'm passionate about sustainable tourism. I decided to write this post to share my knowledge and insights on sustainable managenement of a holiday rental”, and I hope you found it helpful! In my free time, I enjoy hiking with Jeff (my dog), and I'm always looking for ways to expand my knowledge and skills. Thank you for taking the time to read my post, and I hope you'll check out more of my content in the future!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    },
    {
        id: 3,
        title: "Mandatory BTW Declaration for Vacation Homes: The Benefits Outweigh the Hassle",
        href: '/blog/vat',
        image: blogTax,
        description:
            "When we first found out that we were obligated to pay VAT (Value Added Tax), our initial thought was 'more paperwork!' Now, as we are approaching our third declaration, we have experienced the benefits and want to share them with our fellow vacation home owners. It's not as bad as we feared, and the advantages are definitely worth it.",
        date: 'November 1, 2023',
        datetime: '2023-11-1',
        category: { title: 'Good Management', href: '#' },
        reading_time: '20 min',
        author: {
            name: 'Anja Bulteel',
            role: 'Founder',
            imageUrl: Anja,
            description: "Hello readers! My name is Anja, and I'm the author of this blog post. I'm the founder of ResiRent, and I'm passionate about sustainable tourism. I decided to write this post to share my knowledge and insights on sustainable managenement of a holiday rental”, and I hope you found it helpful! In my free time, I enjoy hiking with Jeff (my dog), and I'm always looking for ways to expand my knowledge and skills. Thank you for taking the time to read my post, and I hope you'll check out more of my content in the future!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    },
    {
        id: 4,
        title: "Organizing the final cleaning of your holiday home with quality and sustainability: A case study in Belgium.",
        href: '/blog/final-cleaning',
        image: blogCleaning,
        description: "The final cleaning of a holiday home is often a significant challenge. If you don't have fixed check-in or check-out days, organizing the final cleaning can be irregular. Sometimes customers arrive on Tuesdays, other times on different days, or there's a late booking. Moreover, expectations are high; guests attach great importance to the cleanliness of the accommodation. The cleaning team faces an underestimated task: not only does everything need to be cleaned, but it also needs to be done as in a hotel (a classic example is emptying the coffee machine or dishwasher), and everything must be put back in place so that the guest feels welcome. It's no surprise that finding suitable help is not an easy task.",
        date: 'May 9, 2024',
        datetime: '2024-03-17',
        category: { title: 'Good Management', href: '#' },
        reading_time: '15 min',
        author: {
            name: 'Anja Bulteel',
            role: 'Founder',
            imageUrl: Anja,
            description: "Hello readers! My name is Anja, and I'm the author of this blog post. I'm the founder of ResiRent, and I'm passionate about sustainable tourism. I decided to write this post to share my knowledge and insights on sustainable managenement of a holiday rental”, and I hope you found it helpful! In my free time, I enjoy hiking with Jeff (my dog), and I'm always looking for ways to expand my knowledge and skills. Thank you for taking the time to read my post, and I hope you'll check out more of my content in the future!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    },

    // More posts...
]

export const postsNL = [
    {
        id: 1,
        title: 'Ons verhaal: van een bescheiden begin tot een duurzaam en innovatief bedrijf',
        href: '/nl/blog/ons-verhaal',
        image: blogOurStory,
        description:
            'Wat begon als een bescheiden korte termijn verhuurbedrijf, is sindsdien uitgegroeid tot een bedrijf dat veel belang hecht aan het beoordelen van de duurzaamheid en financiële gezondheid van zijn eigen eigendommen. Tegenwoordig richten we ons op het ontwikkelen van innovatieve producten die verhuurders helpen om voorbereid te zijn op de gedeelde economie van de 21e eeuw.',
        date: 'September 2, 2023',
        datetime: '2023-9-02',
        reading_time: '15 min',
        category: { title: 'Goed beheer', href: '#' },
        author: {
            name: 'Anja Bulteel',
            role: 'Oprichter',
            imageUrl: Anja,
            description: "Hallo lezers! Mijn naam is Anja en ik ben de auteur van deze blogpost. Ik ben de oprichter van ResiRent en ik ben gepassioneerd over duurzaam toerisme. Ik besloot deze post te schrijven om mijn kennis en inzichten over duurzaam beheer van een vakantieverhuur met jullie te delen. Ik hoop dat je er iets aan hebt gevonden! In mijn vrije tijd geniet ik van wandelen met Jeff (mijn hond) en ik ben altijd op zoek naar manieren om mijn kennis en vaardigheden uit te breiden. Bedankt dat je de tijd hebt genomen om mijn post te lezen en ik hoop dat je in de toekomst meer van mijn content zult bekijken!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    },
    {
        id: 2,
        title: 'Recyclen, ook op vakantie toch! ResiRent maakt het ongelooflijk gemakkelijk.',
        href: '/nl/blog/recycleren',
        image: blogRecycling,
        description:
            'Veel gasten voelen zich misschien niet gemotiveerd om tijdens hun vakantie te recyclen, maar de implementatie van een eenvoudige stickeroplossing kan het proces veel gemakkelijker en toegankelijker maken voor iedereen. Met duidelijk gelabelde afvalbakken en handige herinneringen kan recycling een naadloos en moeiteloos onderdeel worden van elk vakantieverblijf.',
        date: 'September 16, 2023',
        datetime: '2020-09-16',
        category: { title: 'Klimaat', href: '#' },
        reading_time: '10 min',
        author: {
            name: 'Anja Bulteel',
            role: 'Oprichter',
            imageUrl: Anja,
            description: "Hallo lezers! Mijn naam is Anja en ik ben de auteur van deze blogpost. Ik ben de oprichter van ResiRent en ik ben gepassioneerd over duurzaam toerisme. Ik besloot deze post te schrijven om mijn kennis en inzichten over duurzaam beheer van een vakantieverhuur met jullie te delen. Ik hoop dat je er iets aan hebt gevonden! In mijn vrije tijd geniet ik van wandelen met Jeff (mijn hond) en ik ben altijd op zoek naar manieren om mijn kennis en vaardigheden uit te breiden. Bedankt dat je de tijd hebt genomen om mijn post te lezen en ik hoop dat je in de toekomst meer van mijn content zult bekijken!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    },
    {
        id: 3,
        title: "Verplichte BTW-aangifte voor vakantiewoningen: De voordelen zijn de administratie meer dan waard.",
        href: '/nl/blog/btw',
        image: blogTax,
        description: "BTW-aangifte is sinds 1 juli 2022 onder bepaalde voorwaarden verplicht voor uw vakantiewoning. Op 28 februari 2023 ontvingen we een teruggave van € 2482,42 van de btw-administratie. Ben je nieuwsgierig hoe dit mogelijk is? Lees dan verder.",
        date: 'November 1, 2023',
        datetime: '2020-03-16',
        category: { title: 'goed beheer', href: '#' },
        reading_time: '20 min',
        author: {
            name: 'Anja Bulteel',
            role: 'Oprichter',
            imageUrl: Anja,
            description: "Hallo lezers! Mijn naam is Anja en ik ben de auteur van deze blogpost. Ik ben de oprichter van ResiRent en ik ben gepassioneerd over duurzaam toerisme. Ik besloot deze post te schrijven om mijn kennis en inzichten over duurzaam beheer van een vakantieverhuur met jullie te delen. Ik hoop dat je er iets aan hebt gevonden! In mijn vrije tijd geniet ik van wandelen met Jeff (mijn hond) en ik ben altijd op zoek naar manieren om mijn kennis en vaardigheden uit te breiden. Bedankt dat je de tijd hebt genomen om mijn post te lezen en ik hoop dat je in de toekomst meer van mijn content zult bekijken!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    },
    {
        id: 4,
        title: "De eindschoonmaak van jouw vakantiewoning kwalitatief en duurzaam organiseren: Case study in België.",
        href: '/nl/blog/eindschoonmaak',
        image: blogCleaning,
        description:
            "De eindschoonmaak van een vakantiewoning vormt op talloze fronten een uitdaging. Met uitzondering van vaste check-in en check-out dagen, is de frequentie van deze taak zeer variabel. De verwachtingen zijn hooggespannen; het al dan niet schoon zijn van de accommodatie weegt zwaar mee in de beoordeling door gasten. Het team belast met de schoonmaak staat voor een immense taak: van huishoudelijk reinigen tot oog voor detail, en alles klaarzetten zoals in een hotelkamer. Het is dan ook geen verrassing dat het vinden van geschikte hulp geen eenvoudige opgave is!",
        date: 'May 9, 2024',
        datetime: '2024-03-17',
        category: { title: 'goed beheer', href: '#' },
        reading_time: '15 min',
        author: {
            name: 'Anja Bulteel',
            role: 'Oprichter',
            imageUrl: Anja,
            description: "Hallo lezers! Mijn naam is Anja en ik ben de auteur van deze blogpost. Ik ben de oprichter van ResiRent en ik ben gepassioneerd over duurzaam toerisme. Ik besloot deze post te schrijven om mijn kennis en inzichten over duurzaam beheer van een vakantieverhuur met jullie te delen. Ik hoop dat je er iets aan hebt gevonden! In mijn vrije tijd geniet ik van wandelen met Jeff (mijn hond) en ik ben altijd op zoek naar manieren om mijn kennis en vaardigheden uit te breiden. Bedankt dat je de tijd hebt genomen om mijn post te lezen en ik hoop dat je in de toekomst meer van mijn content zult bekijken!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    }
    // More posts...
]

export const postsDE = [
    {
        id: 1,
        title: 'Unsere Geschichte: Von bescheidenen Anfängen zu einem nachhaltigen und innovativen Unternehmen',
        href: '/de/blog/unsere-geschichte',
        image: blogOurStory,
        description:
            'Was als ein bescheidenes Unternehmen für Kurzzeitvermietungen begann, hat sich seitdem zu einem Unternehmen entwickelt, das großen Wert auf die Bewertung der Nachhaltigkeit und finanziellen Gesundheit seiner eigenen Immobilien legt. Heute konzentrieren wir uns auf die Entwicklung innovativer Produkte, die Vermietern helfen, sich auf die geteilte Wirtschaft des 21. Jahrhunderts vorzubereiten.',
        date: '2. September 2023',
        datetime: '2023-09-02',
        reading_time: '15 Min',
        category: { title: 'Gutes Management', href: '#' },
        author: {
            name: 'Anja Bulteel',
            role: 'Gründerin',
            imageUrl: Anja,
            description: "Hallo Leser! Mein Name ist Anja und ich bin die Autorin dieses Blogposts. Ich bin die Gründerin von ResiRent und bin leidenschaftlich an nachhaltigem Tourismus interessiert. Ich habe diesen Beitrag geschrieben, um mein Wissen und meine Einsichten über nachhaltiges Management von Ferienvermietungen mit Ihnen zu teilen. Ich hoffe, Sie finden es nützlich! In meiner Freizeit genieße ich Spaziergänge mit Jeff (meinem Hund) und bin immer auf der Suche, mein Wissen und meine Fähigkeiten zu erweitern. Danke, dass Sie sich die Zeit genommen haben, meinen Beitrag zu lesen, und ich hoffe, dass Sie in Zukunft mehr von meinem Inhalt sehen werden!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    },
    {
        id: 2,
        title: 'Recycling auch im Urlaub! ResiRent macht es unglaublich einfach.',
        href: '/de/blog/recycling',
        image: blogRecycling,
        description:
            'Viele Gäste fühlen sich vielleicht nicht motiviert, während ihres Urlaubs zu recyceln, aber die Implementierung einer einfachen Aufkleberlösung kann den Prozess viel einfacher und zugänglicher für alle machen. Mit klar gekennzeichneten Mülleimern und praktischen Erinnerungen kann Recycling ein nahtloser und müheloser Teil jedes Urlaubsaufenthalts werden.',
        date: '16. September 2023',
        datetime: '2023-09-16',
        category: { title: 'Klima', href: '#' },
        reading_time: '10 Min',
        author: {
            name: 'Anja Bulteel',
            role: 'Gründerin',
            imageUrl: Anja,
            description: "Hallo Leser! Mein Name ist Anja und ich bin die Autorin dieses Blogposts. Ich bin die Gründerin von ResiRent und bin leidenschaftlich an nachhaltigem Tourismus interessiert. Ich habe diesen Beitrag geschrieben, um mein Wissen und meine Einsichten über nachhaltiges Management von Ferienvermietungen mit Ihnen zu teilen. Ich hoffe, Sie finden es nützlich! In meiner Freizeit genieße ich Spaziergänge mit Jeff (meinem Hund) und bin immer auf der Suche, mein Wissen und meine Fähigkeiten zu erweitern. Danke, dass Sie sich die Zeit genommen haben, meinen Beitrag zu lesen, und ich hoffe, dass Sie in Zukunft mehr von meinem Inhalt sehen werden!",
            href: 'https://www.linkedin.com/in/anja-bulteel-05979aa4/?originalSubdomain=ch',
        },
    },
    // Weitere Beiträge...
]


