import {
    createStyles,
    Container,
    Title,
    Text,
    Anchor
} from '@mantine/core';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
    wrapper: {
        minHeight: 400,
        boxSizing: 'border-box',
        padding: `calc(${theme.spacing.xl} * 2.5)`,

        [theme.fn.smallerThan('sm')]: {
            padding: `calc(${theme.spacing.xl} * 1.5)`,
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        color: theme.colors.resirentPink[0],
        lineHeight: 1,
    },

    description: {
        color: theme.black,

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },

    form: {
        backgroundColor: theme.white,
        padding: theme.spacing.xl,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.lg,
        '&:hover': {
            boxShadow: theme.shadows.xl,
            transform: 'scale(1.01)',
        },
    },

    social: {
        color: theme.colorScheme.resirent,
    },

    input: {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[4],
        color: theme.black,

        '&::placeholder': {
            color: theme.colors.gray[5],
        },
    },

    inputLabel: {
        color: theme.black,
    },

    control: {
        backgroundColor: theme.colorScheme.resirentPink,
    },
}));

export default function FormRegisterResiCal() {
    const { classes } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '';
    const { t } = useTranslation();

    return (
        <Container size="lg" py={100} align="center">
            <Title className={classes.title}> {t("registrationResiWeb.title")}</Title>
            <Text className={classes.description} mt="sm" mb={30}>{t("registrationResiWeb.contact")}
                <Anchor align="center" mb="md" radius="xl" component="a" href={t("registrationResiWeb.href")} mt="md" size="md" className={classes.control}>
                    contact
                </Anchor>
            </Text>
            {
                languagePrefix === '/nl' ?
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfsymlXk6KeseYs84JWIo0SBGhXB5ISUR8BwFztLing-91Haw/viewform?embedded=true" width="640" height="1667" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    :
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe0Bb2spIlMD5TBgdNGvJH-b-m8j341ZruRkXTiFozy6IGHxA/viewform?embedded=true" width="640" height="2213" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            }
        </Container>
    );
}