import the_start from '../../../../assets/blogPosts/OurStory/TheVillage.jpg'
import covid from '../../../../assets/blogPosts/OurStory/covid.jpg'
import living_room from '../../../../assets/blogPosts/OurStory/living_room.jpg'
import resirent_slogan from '../../../../assets/blogPosts/OurStory/ResiRent_slogan.svg'

export const ourStoryEN = [
    {
        id: 'section-1',
        title: '1. An Opportunity in Disguise',
        content: "Our experience with short-term rentals started from a place of adversity. After years of successfully renting out our beautiful modern apartment through the normal rental market, we found ourselves in a difficult situation with a tenant who fell into rent arrears and caused significant damage to the property. It was a challenging time, and we were left feeling frustrated and disheartened. But as we picked up the pieces, we realized that this setback might be an opportunity in disguise.",
        imageSrc: the_start,
        altText: "The Village",
    },
    {
        id: 'section-2',
        title: '2. The Real Take-off',
        content: `
            At the time, the concept of the "sharing economy" was gaining traction.
            We had heard positive stories from friends who rented out their second properties
            while they were away on vacation, and they were absolutely thrilled with the experience.
            They talked about the connections they made with people from all over the world and how much
            fun they had managing their short-term rentals. Inspired by their stories, we decided to take the plunge ourselves,
            despite having no prior knowledge of the tourism industry. Our journey began with the investment in furniture,
            linens, pots, and pans, and other essentials. We listed our property on platforms such as Booking.com,
            Airbnb, and VRBO, and the bookings started rolling in. Encouraged by our initial success,
            we even decided to rent out our vacation home in Austria through the same channels.
          `,
    },
    {
        id: 'section-3',
        title: '3. Navigating the Challenges of Short-Term Rentals',
        content: (
            <div>
                <p>
                    As early adopters of the short-term rental market, we were excited to explore the potential of this emerging industry.
                    However, as we gained more experience, we also encountered some of the challenges that come with managing short-term rentals.
                    Here are some of the issues we encountered:
                </p>
                <div>
                    <p className="py-3 text-base font-semibold leading-7 text-[#AA4465]">
                        Micro niveau
                    </p>
                    <ul className="list-disc resirent-p list-inside text-justify">
                        <li>Negative Guest Reviews</li>
                        <li>Loose Check-in Procedures</li>
                        <li>Difficult Communication with Guests</li>
                        <li>Neighbor Complaints</li>
                        <li>Rising Energy Costs</li>
                        <li>Overall Price Increases</li>
                    </ul>
                    <div>
                        <p className="py-3 text-base font-semibold leading-7 text-[#AA4465] ">
                            Meso niveau
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Rising Real Estate Prices</li>
                            <li>Emergence of New Platforms</li>
                            <li>High Commission Fees</li>
                            <li>Legal Regulations and Uncertainty</li>
                            <li>Increased Competition</li>
                            <li>Negative Press and Image</li>
                        </ul>
                    </div>
                    <div>
                        <p className="py-3 text-base font-semibold leading-7 text-[#AA4465]">
                            Macro niveau
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Rising Real Estate Prices</li>
                            <li>Climate Change</li>
                            <li>Biodiversity Loss</li>
                            <li>Digitalization</li>
                            <li>Shortage of Human Resources</li>
                            <li>Inflation</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),
    },
    {
        id: 'section-4',
        title: '4. From Crisis to Opportunity: How COVID-19 Inspired us to Transition to a Sustainable Business Model',
        content: "In 2019, the 'Corona crisis' opened our eyes to the harsh reality of the tourism industry. Our accommodations were empty, and we received no support from the government. As hosts in Austria and Belgium, and living in Switzerland, we fell between two stools. However, the silence during this time gave us the chance to reflect on our business. As the old saying goes, in times of crisis, opportunities arise. It was during those quiet days that we were invited to participate in a 'sustainable tourism' course. The ten intensive weeks of the course inspired us to rethink how we managed our accommodations. We were determined to learn more about sustainability, so we enrolled in an additional course from the GSTC, which earned us an additional professional certificate in sustainable tourism. The GSTC, founded under the aegis of the UN, is a global organization promoting sustainable tourism. The COVID-19 crisis was a turning point for us, and we realized the importance of transitioning towards a more sustainable business model. We hope that our story inspires others to make similar changes and embrace a more sustainable future for tourism.",
        imageSrc: covid, // Add the image path if applicable
        altText: 'Image Covid', // Add the alt text if applicable
    },
    {
        id: 'section-5',
        title: '5. What is the significance of the Global Sustainable Tourism Council (GSTC)?',
        content: (
            <div>
                <p>
                    The GSTC plays an important role in promoting sustainable tourism worldwide. It has developed globally recognized standards
                    for sustainable tourism, which are used as a benchmark for tourism businesses to assess their sustainability performance.
                    They have identified four key categories for promoting sustainability within the tourism industry:
                </p>
                <ul className="list-disc resirent-p list-inside text-justify">
                    <li>
                        Effective management practices that ensure compliance with all relevant regulations and guidelines.
                    </li>
                    <li>
                        Support for the local community by maximizing the economic, social, and cultural benefits of tourism. This can be achieved by guiding guests towards local initiatives and businesses, and creating opportunities for cultural exchange.
                    </li>
                    <li>
                        Minimization of environmental impact through the responsible use of natural resources, waste management, and the reduction of noise and energy consumption.
                    </li>
                    <li>
                        Promotion of cultural heritage by showcasing local culture and traditions, and engaging guests in meaningful experiences that highlight the unique aspects of the destination.
                    </li>
                </ul>
            </div>
        ),
    },
    {
        id: 'section-6',
        title: '6. Responsible Rental: the Cornerstone of our Accommodations',
        content: "Using the insights that we obtained from the courses, we wanted to review and adapt our own accommodations according to the GSTC standards. Two of our accommodations, The City Ghent in Ghent and Haus Clarysse in Schladming, have been updated to meet sustainability criteria as much as possible. We also renovated our second son's room and transformed it into Studio Gocki, applying sustainable principles in the process. For us, sustainability is not just a trend, but a fundamental principle that we strive to incorporate into every aspect of our business.",
        imageSrc: living_room, // Add the image path if applicable
        altText: 'Living room', // Add the alt text if applicable
    },
    {
        id: 'section-7',
        title: '7. Responsible Rental: Only Benefits!',
        content: (
            <div id="section-7">
                <p>
                    As we strive to be a responsible host, we've made significant improvements in areas such as legal compliance, communication, and sustainability.
                    Our ongoing commitment to sustainability has led us to implement a range of innovative practices, from optimized work processes to professional cleaning services.
                    We soon realized that our efforts were worthwhile. By focusing on key areas like energy efficiency, communication, and sustainable rental practices, we have
                    not only improved our environmental impact and guest experience but also achieved numerous other benefits through Responsible Rental. Here are some of the key advantages:
                </p>
                <div>
                    <div>
                        <p>
                            Financial and legal
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Up-to-date with legal requirements</li>
                            <li>Improved reviews from guests</li>
                            <li>Increased number of reservations</li>
                            <li>Reduced energy bills</li>
                            <li>...</li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Management
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Streamlined work processes</li>
                            <li>Enhanced communication strategies for better collaboration</li>
                            <li>Implementation of sustainable rental practices wherever feasible</li>
                            <li>Professional cleaning services for guests</li>
                            <li>...</li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Marketing
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>No greenwashing</li>
                            <li>Differentiate us from competition</li>
                            <li>...</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),
    },

    {
        id: 'section-8',
        title: '8. A better experience for the guests',
        content: "We observed a rise in positive high-score reviews, as guests were delighted with the recent enhancements made to all our accommodations. By implementing sustainable standards, we became unique and authentic. Improved communication led to a kinder, yet more effective guest service, while clean and well-maintained accommodations enhanced the overall guest experience. Furthermore, we supported local initiatives and businesses by introducing them to our guests, creating a win-win situation where both the guests and the local economy could benefit greatly.",
        imageSrc: resirent_slogan, // Add the image path if applicable
        altText: 'ResiRent Slogan', // Add the alt text if applicable
    },

    {
        id: 'section-9',
        title: '9. The start of ResiRent',
        content: "In the end, we took the leap and founded ResiRent. ResiRent's goal is to develop solutions with and for hosts. We do this by helping hosts become more sustainable and financially independent from platforms. Our actions include supporting hosts in adopting sustainable practices, such as reducing energy and water consumption, minimizing waste and carbon footprint, and promoting local culture and nature conservation. We also assist hosts in building their own website with a sustainability focus, providing training and resources on sustainable revenue management and pricing. We will advocate for fair and transparent platform policies that support sustainable tourism and engage in dialogue with policymakers, industry associations, and consumers to promote sustainable practices. Finally, we will build a community of hosts committed to sustainability, providing peer learning, networking, and recognition for their efforts. By implementing these actions, hosts can contribute to a more sustainable tourism industry, attract eco-conscious travelers, and enhance their reputation and profitability.",
    }

    // Add more sections as needed
];

export const ourStoryNL = [
    {
        id: 'section-1',
        title: '1. Een tegenslag bleek een kans',
        content: "Onze ervaring met kortetermijnverhuur begon vanuit een plaats van tegenspoed. Na jarenlang succesvol ons mooie moderne appartement te hebben verhuurd via de reguliere huurmarkt, kwamen wij in een lastige situatie terecht met een huurder die in een huurachterstand raakte en flinke schade aanrichtte aan het pand. Het was een uitdagende tijd en we voelden ons gefrustreerd en ontmoedigd. Maar toen we de stukken oppakten, realiseerden we ons dat deze tegenslag eigenlijk kans zou kunnen zijn.",
        imageSrc: the_start,
        altText: "The Village",
    },
    {
        id: 'section-2',
        title: '2. De echte start',
        content: `
        In die tijd won het concept van de "deeleconomie" aan populariteit. We hadden positieve verhalen gehoord van vrienden die hun tweede eigendom verhuurden als ze zelf niet daar op vakantie waren, en ze waren absoluut enthousiast over de ervaring. Ze spraken over de connecties die ze hadden gemaakt met mensen van over de hele wereld en hoeveel plezier ze hadden met het beheren van hun korte termijn verhuur. Geïnspireerd door hun verhalen besloten we zelf de sprong te wagen, ondanks dat we geen voorkennis hadden van de toeristenindustrie. Onze reis begon met de investering in meubels, beddengoed, potten en pannen en andere benodigdheden. We plaatsten ons onroerend goed op platforms zoals Booking.com, Airbnb en VRBO en de boekingen begonnen binnen te stromen. Aangemoedigd door ons aanvankelijke succes besloten we zelfs om ons vakantiehuis in Oostenrijk via dezelfde kanalen te verhuren.
          `,
    },
    {
        id: 'section-3',
        title: '3. Navigeren door de uitdagingen van korte termijn verhuur',
        content: (
            <div>
                <p>
                    Als early adopters van de korte termijn verhuurmarkt waren we verheugd om het potentieel van deze opkomende industrie te verkennen. Naarmate we meer ervaring opdeden, kwamen we echter ook enkele uitdagingen tegen die gepaard gaan met het beheer van korte termijn verhuur. Hier zijn enkele van de problemen die we tegenkwamen:
                </p>
                <div>
                    <p className="py-3 text-base font-semibold leading-7 text-[#AA4465]">
                        Micro niveau
                    </p>
                    <ul className="list-disc resirent-p list-inside text-justify">
                        <li>Negatieve gastbeoordelingen</li>
                        <li>Incheckprocedures</li>
                        <li>Moeilijke communicatie met gasten</li>
                        <li>Klachten van buren</li>
                        <li>Stijgende energiekosten</li>
                        <li>Algemene prijsverhogingen</li>
                    </ul>
                    <div>
                        <p className="py-3 text-base font-semibold leading-7 text-[#AA4465] ">
                            Meso niveau
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Stijgende vastgoedprijzen</li>
                            <li>Opkomst van steeds meer nieuwe platforms</li>
                            <li>Hoge commissiekosten</li>
                            <li>Wettelijke voorschriften en onzekerheid</li>
                            <li>Toegenomen concurrentie</li>
                            <li>Negatieve pers en imago</li>
                        </ul>
                    </div>
                    <div>
                        <p className="py-3 text-base font-semibold leading-7 text-[#AA4465]">
                            Macro niveau
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Stijgende vastgoedprijzen</li>
                            <li>Klimaatverandering</li>
                            <li>Klimaatverandering</li>
                            <li>Digitalization</li>
                            <li>Gebrek aan personeel</li>
                            <li>Inflatie</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),
    },
    {
        id: 'section-4',
        title: '4. Van crisis naar kans: hoe COVID-19 ons inspireerde om over te stappen naar een duurzaam Bedrijfsmodel',
        content: "In 2019 opende de Corona-crisis onze ogen voor de harde realiteit van de toeristenindustrie. Onze accommodaties stonden leeg en we kregen geen steun van de overheid. Als gastheren in Oostenrijk en België, en wonend in Zwitserland, vielen we tussen wal en schip. De stilte gedurende deze tijd gaf ons echter de kans om na te denken over ons bedrijf. Zoals het oude gezegde luidt: in tijden van crisis ontstaan ​​kansen. Het was tijdens die rustige dagen dat we werden uitgenodigd om deel te nemen aan een cursus duurzaam toerisme. De tien intensieve weken van de cursus inspireerden ons om opnieuw na te denken over hoe we onze accommodaties beheerden. We waren vastbesloten om meer te leren over duurzaamheid, dus schreven we ons in voor een aanvullende cursus van de GSTC, waarmee we een aanvullend professioneel certificaat in duurzaam toerisme verdienden. De GSTC, opgericht onder de vleugels van de VN, is een wereldwijde organisatie die duurzaam toerisme promoot. De COVID-19-crisis was een keerpunt voor ons en we realiseerden ons het belang van de overgang naar een duurzamer bedrijfsmodel. We hopen dat ons verhaal anderen inspireert om soortgelijke veranderingen door te voeren en een duurzamere toekomst voor toerisme te omarmen.",
        imageSrc: covid, // Add the image path if applicable
        altText: 'Image Covid', // Add the alt text if applicable
    },
    {
        id: 'section-5',
        title: '5.  Wat is de betekenis van de Global Sustainable Tourism Council (GSTC)?',
        content: (
            <div>
                <p>
                    De GSTC speelt een belangrijke rol bij het promoten van duurzaam toerisme wereldwijd. Het heeft wereldwijd erkende normen voor duurzaam toerisme ontwikkeld, die worden gebruikt als maatstaf voor toeristische bedrijven om hun duurzaamheidsprestaties te beoordelen. Ze hebben vier hoofdcategorieën geïdentificeerd voor het bevorderen van duurzaamheid binnen de toeristenindustrie:
                </p>
                <ul className="list-disc resirent-p list-inside text-justify">
                    <li>Effectieve managementpraktijken die ervoor zorgen dat alle relevante voorschriften en richtlijnen worden nageleefd en dat de inrichting financieel gezond is.</li>
                    <li>Ondersteuning van de lokale gemeenschap door de economische, sociale en culturele voordelen van toerisme te maximaliseren. Dit kan door gasten wegwijs te maken in de richting van lokale initiatieven en bedrijven en door mogelijkheden te creëren voor culturele uitwisseling.</li>
                    <li>Minimalisering van de milieu-impact door verantwoord gebruik van natuurlijke hulpbronnen, afvalbeheer en vermindering van lawaai en energieverbruik.</li>
                    <li>Promotie van cultureel erfgoed door de lokale cultuur en tradities onder de aandacht te brengen en gasten te betrekken bij betekenisvolle ervaringen die de unieke aspecten van de bestemming benadrukken.</li>
                </ul>
            </div>
        ),
    },
    {
        id: 'section-6',
        title: '6. Verantwoord verhuren: de hoeksteen van onze accommodaties',
        content: "Met de inzichten die we uit de cursussen hebben verkregen, wilden we onze eigen accommodaties herzien en aanpassen volgens de GSTC-normen. Twee van onze accommodaties, The City Gent in Gent en Haus Clarysse in Schladming, zijn geüpdatet om zoveel mogelijk aan duurzaamheidscriteria te voldoen. Ook hebben we de kamer van onze tweede zoon gerenoveerd en omgetoverd tot Studio Gocki, waarbij we duurzame principes meteen hebben toegepast. Voor ons is duurzaamheid niet zomaar een trend, maar een fundamenteel principe dat we in elk aspect van ons bedrijf proberen te integreren.",
        imageSrc: living_room, // Add the image path if applicable
        altText: 'Living room', // Add the alt text if applicable
    },
    {
        id: 'section-7',
        title: '7. Verantwoord Huren: Alleen maar Voordelen!',
        content: (
            <div id="section-7">
                <p>
                    Omdat we ernaar streven een verantwoordelijke verhuurder te zijn, hebben we aanzienlijke verbeteringen aangebracht op gebieden als wettelijke naleving, communicatie en duurzaamheid. Onze voortdurende toewijding aan duurzaamheid heeft ertoe geleid dat we een reeks innovatieve werkwijzen hebben geïmplementeerd, van geoptimaliseerde werkprocessen tot professionele schoonmaakdiensten. Al snel beseften we dat onze inspanningen de moeite waard waren. Door ons te concentreren op belangrijke gebieden zoals energie-efficiëntie, communicatie en duurzame verhuurpraktijken, hebben we niet alleen onze impact op het milieu en de gastervaring verbeterd, maar hebben we ook tal van andere voordelen behaald via Responsable Rental. Hier zijn enkele van de belangrijkste voordelen:
                </p>
                <div>
                    <div>
                        <p>
                            Financieel en juridisch
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Up-to-date met wettelijke vereistens</li>
                            <li>Verbeterde beoordelingen van gasten</li>
                            <li>Verhoogd aantal reserveringen</li>
                            <li>Lagere energierekening</li>
                            <li>...</li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Beheer
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Gestroomlijnde werkprocessen</li>
                            <li>Verbeterde communicatiestrategieën voor betere samenwerking</li>
                            <li>Implementatie van duurzame verhuurpraktijken waar mogelijk</li>
                            <li>Professionele schoonmaakdiensten voor gasten</li>
                            <li>...</li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Marketing
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Geen greenwashing</li>
                            <li>Onderscheid ons van de concurrentie</li>
                            <li>...</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),
    },

    {
        id: 'section-8',
        title: '8. Een betere ervaring voor de gasten',
        content: "We zagen een stijging in positieve beoordelingen met hoge scores, omdat gasten erg blij waren met de recente verbeteringen die aan al onze accommodaties zijn aangebracht. Door duurzame normen te implementeren, werden we uniek en authentiek. Verbeterde communicatie leidde tot een vriendelijkere, maar effectievere gastenservice, terwijl schone en goed onderhouden accommodaties de algehele gastervaring verbeterden. Verder ondersteunden we lokale initiatieven en bedrijven door ze kennis te laten maken met onze gasten, waardoor een win-win situatie ontstond waar zowel de gasten als de lokale economie enorm van konden profiteren.",
        imageSrc: resirent_slogan, // Add the image path if applicable
        altText: 'ResiRent Slogan', // Add the alt text if applicable
    },

    {
        id: 'section-9',
        title: '9. De start van ResiRent',
        content: " Uiteindelijk hebben we de sprong gewaagd en ResiRent opgericht. Het doel van ResiRent is het ontwikkelen van oplossingen met en voor verhuurders. We doen dit door verhuurders te helpen duurzamer en financieel onafhankelijker te worden van platforms. Onze acties omvatten het ondersteunen van gastgevers bij het toepassen van duurzame praktijken, zoals het verminderen van energie- en waterverbruik, het minimaliseren van afval en ecologische voetafdruk, en het promoten van lokale cultuur en natuurbehoud. We helpen gastgevers ook bij het bouwen van hun eigen website met een focus op duurzaamheid, door training en middelen te bieden over duurzaam inkomstenbeheer en prijsstelling. We pleiten voor eerlijk en transparant platformbeleid dat duurzaam toerisme ondersteunt en gaan de dialoog aan met beleidsmakers, brancheorganisaties en consumenten om duurzame praktijken te promoten. Ten slotte zullen we een gemeenschap van gastgevers fopbouwen die zich inzetten voor duurzaamheid, peer learning, netwerken en erkenning voor hun inspanningen bieden. Door deze acties te implementeren, kunnen verhuurders bijdragen aan een duurzamere toeristenindustrie, milieubewuste reizigers aantrekken en hun reputatie en winstgevendheid verbeteren, waar zowel de gasten als de lokale economie enorm van kunnen profiteren.",
    }

    // Add more sections as needed
];

export const ourStoryDE = [
    {
        id: 'section-1',
        title: '1. Eine Chance in Verkleidung',
        content: "Unsere Erfahrung mit Kurzzeitvermietungen begann in einer Phase der Widrigkeiten. Nach Jahren des erfolgreichen Vermietens unserer modernen Wohnung auf dem normalen Mietmarkt fanden wir uns in einer schwierigen Situation mit einem Mieter wieder, der in Mietrückstand geriet und erheblichen Schaden an der Immobilie verursachte. Es war eine herausfordernde Zeit und wir fühlten uns frustriert und entmutigt. Aber als wir die Scherben aufsammelten, erkannten wir, dass dieses Rückschlag vielleicht eine versteckte Chance sein könnte.",
        imageSrc: the_start,
        altText: "Das Dorf",
    },
    {
        id: 'section-2',
        title: '2. Der eigentliche Durchbruch',
        content: `
            Zu dieser Zeit gewann das Konzept der "Sharing Economy" an Zugkraft.
            Wir hatten positive Geschichten von Freunden gehört, die ihre Zweitimmobilien
            vermieteten, während sie im Urlaub waren, und sie waren absolut begeistert von der Erfahrung.
            Sie sprachen über die Verbindungen, die sie mit Menschen aus aller Welt knüpften und wie viel
            Spaß es ihnen machte, ihre Kurzzeitvermietungen zu verwalten. Inspiriert von ihren Geschichten, entschieden wir uns, selbst den Sprung zu wagen,
            obwohl wir keine Vorkenntnisse in der Tourismusbranche hatten. Unsere Reise begann mit der Investition in Möbel,
            Bettwäsche, Töpfe, Pfannen und andere Notwendigkeiten. Wir listeten unsere Immobilie auf Plattformen wie Booking.com,
            Airbnb und VRBO und die Buchungen begannen zu fließen. Ermutigt durch unseren anfänglichen Erfolg,
            entschieden wir uns sogar, unser Ferienhaus in Österreich über die gleichen Kanäle zu vermieten.
          `,
    },
    {
        id: 'section-3',
        title: '3. Die Herausforderungen der Kurzzeitvermietung meistern',
        content: (
            <div>
                <p>
                    Als frühe Nutzer des Marktes für Kurzzeitvermietungen waren wir gespannt, das Potenzial dieser aufstrebenden Branche zu erkunden.
                    Doch mit wachsender Erfahrung stießen wir auch auf einige Herausforderungen, die mit der Verwaltung von Kurzzeitvermietungen einhergehen.
                    Hier sind einige der Probleme, auf die wir stießen:
                </p>
                <div>
                    <p className="py-3 text-base font-semibold leading-7 text-[#AA4465]">
                        Mikroebene
                    </p>
                    <ul className="list-disc resirent-p list-inside text-justify">
                        <li>Negative Gästebewertungen</li>
                        <li>Lockere Check-in-Verfahren</li>
                        <li>Schwierige Kommunikation mit Gästen</li>
                        <li>Beschwerden von Nachbarn</li>
                        <li>Steigende Energiekosten</li>
                        <li>Allgemeine Preiserhöhungen</li>
                    </ul>
                    <div>
                        <p className="py-3 text-base font-semibold leading-7 text-[#AA4465] ">
                            Mesoebene
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Steigende Immobilienpreise</li>
                            <li>Auftauchen neuer Plattformen</li>
                            <li>Hohe Kommissionsgebühren</li>
                            <li>Rechtliche Vorschriften und Unsicherheiten</li>
                            <li>Zunehmender Wettbewerb</li>
                            <li>Negative Presse und Image</li>
                        </ul>
                    </div>
                    <div>
                        <p className="py-3 text-base font-semibold leading-7 text-[#AA4465]">
                            Makroebene
                        </p>
                        <ul className="list-disc resirent-p list-inside text-justify">
                            <li>Steigende Immobilienpreise</li>
                            <li>Klimawandel</li>
                            <li>Verlust der Biodiversität</li>
                            <li>Digitalisierung</li>
                            <li>Mangel an Arbeitskräften</li>
                            <li>Inflation</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),
    },
    {
        id: 'section-4',
        title: '4. Von der Krise zur Chance: Wie COVID-19 uns dazu inspirierte, auf ein nachhaltiges Geschäftsmodell umzusteigen',
        content: "Im Jahr 2019 öffnete uns die 'Corona-Krise' die Augen für die harte Realität der Tourismusbranche. Unsere Unterkünfte standen leer, und wir erhielten keine Unterstützung von der Regierung. Als Gastgeber in Österreich und Belgien und wohnhaft in der Schweiz, fielen wir zwischen zwei Stühle. Jedoch gab uns die Stille in dieser Zeit die Chance, über unser Geschäft nachzudenken. Wie das alte Sprichwort sagt: In Krisenzeiten entstehen Chancen. Es war während dieser stillen Tage, dass wir eingeladen wurden, an einem Kurs über 'nachhaltigen Tourismus' teilzunehmen. Die zehn intensiven Wochen des Kurses inspirierten uns, unser Management unserer Unterkünfte neu zu überdenken. Wir waren entschlossen, mehr über Nachhaltigkeit zu lernen, also schrieben wir uns in einen weiteren Kurs beim GSTC ein, wodurch wir ein zusätzliches professionelles Zertifikat im nachhaltigen Tourismus erwarben. Der GSTC, gegründet unter der Schirmherrschaft der UN, ist eine globale Organisation zur Förderung nachhaltigen Tourismus. Die COVID-19-Krise war ein Wendepunkt für uns, und wir erkannten die Bedeutung des Übergangs zu einem nachhaltigeren Geschäftsmodell. Wir hoffen, dass unsere Geschichte andere dazu inspiriert, ähnliche Veränderungen vorzunehmen und eine nachhaltigere Zukunft für den Tourismus zu umarmen.",
        imageSrc: covid, // Fügen Sie den Bildpfad hinzu, falls zutreffend
        altText: 'Bild Covid', // Fügen Sie den Alt-Text hinzu, falls zutreffend
    },
    {
        id: 'section-5',
        title: '5. Was ist die Bedeutung des Global Sustainable Tourism Council (GSTC)?',
        content: (
            <div>
                <p>
                    Der GSTC spielt eine wichtige Rolle bei der Förderung nachhaltigen Tourismus weltweit. Er hat global anerkannte Standards
                    für nachhaltigen Tourismus entwickelt, die als Maßstab für Tourismusunternehmen zur Bewertung ihrer Nachhaltigkeitsleistung dienen.
                    Sie haben vier Schlüsselkategorien zur Förderung der Nachhaltigkeit in der Tourismusbranche identifiziert:
                </p>
                <ul className="list-disc resirent-p list-inside text-justify">
                    <li>
                        Effektive Managementpraktiken, die die Einhaltung aller relevanten Vorschriften und Richtlinien gewährleisten.
                    </li>
                    <li>
                        Unterstützung der lokalen Gemeinschaft durch Maximierung der wirtschaftlichen, sozialen und kulturellen Vorteile des Tourismus. Dies kann erreicht werden, indem Gäste zu lokalen Initiativen und Unternehmen geführt und Möglichkeiten für kulturellen Austausch geschaffen werden.
                    </li>
                    <li>
                        Minimierung der Umweltauswirkungen durch verantwortungsvollen Umgang mit natürlichen Ressourcen, Abfallmanagement und Reduzierung von Lärm und Energieverbrauch.
                    </li>
                    <li>
                        Förderung des kulturellen Erbes durch Präsentation lokaler Kultur und Traditionen und Einbeziehung der Gäste in bedeutungsvolle Erlebnisse, die die einzigartigen Aspekte des Reiseziels hervorheben.
                    </li>
                </ul>
            </div>
        ),
    },
    {
        "id": "section-6",
        "title": "6. Verantwortungsvolles Vermieten: Der Eckpfeiler unserer Unterkünfte",
        "content": "Mit den Erkenntnissen, die wir aus den Kursen gewonnen haben, wollten wir unsere eigenen Unterkünfte nach den GSTC-Standards überarbeiten und anpassen. Zwei unserer Unterkünfte, The City Gent in Gent und Haus Clarysse in Schladming, wurden aktualisiert, um den Nachhaltigkeitskriterien so weit wie möglich zu entsprechen. Wir haben auch das Zimmer unseres zweiten Sohnes renoviert und in Studio Gocki umgewandelt, wobei wir sofort nachhaltige Prinzipien anwandten. Für uns ist Nachhaltigkeit nicht nur ein Trend, sondern ein grundlegendes Prinzip, das wir in jeden Aspekt unseres Unternehmens zu integrieren versuchen.",
        "imageSrc": "living_room", // Fügen Sie den Bildpfad hinzu, falls zutreffend
        "altText": "Wohnzimmer" // Fügen Sie den Alternativtext hinzu, falls zutreffend
    },
    {
        "id": "section-7",
        "title": "7. Verantwortungsvolles Vermieten: Nur Vorteile!",
        "content": (
            <div>
                <p>
                    Da wir bestrebt sind, ein verantwortungsbewusster Vermieter zu sein, haben wir bedeutende Verbesserungen in Bereichen wie rechtliche Einhaltung, Kommunikation und Nachhaltigkeit vorgenommen.Unser anhaltendes Engagement für Nachhaltigkeit hat dazu geführt, dass wir eine Reihe innovativer Praktiken implementiert haben, von optimierten Arbeitsprozessen bis hin zu professionellen Reinigungsdiensten für Gäste.Bald erkannten wir, dass sich unsere Bemühungen lohnten.Indem wir uns auf Schlüsselbereiche wie Energieeffizienz, Kommunikation und nachhaltige Vermietungspraktiken konzentrierten, verbesserten wir nicht nur unsere Auswirkungen auf die Umwelt und das Gästeerlebnis, sondern erreichten auch zahlreiche andere Vorteile durch Verantwortungsvolles Vermieten.Hier sind einige der wichtigsten Vorteile:
                </p>
                <div>
                    <div>
                        <p>
                            Finanziell und rechtlich
                        </p>
                        <ul>
                            <li>Aktuell mit rechtlichen Anforderungen</li>
                            <li>Verbesserte Gästebewertungen</li>
                            <li>Erhöhte Anzahl an Buchungen</li>
                            <li>Niedrigere Energierechnungen</li>
                            <li>...</li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Verwaltung
                        </p>
                        <ul>
                            <li>Optimierte Arbeitsprozesse</li>
                            <li>Verbesserte Kommunikationsstrategien für bessere Zusammenarbeit</li>
                            <li>Implementierung von nachhaltigen Vermietungspraktiken, wo möglich</li>
                            <li>Professionelle Reinigungsdienste für Gäste</li>
                            <li>...</li>
                        </ul>
                    </div >
                    <div>
                        <p>
                            Marketing
                        </p>
                        <ul>
                            <li>Kein Greenwashing</li>
                            <li>Unterscheidung von der Konkurrenz</li>
                            <li>...</li>
                        </ul>
                    </div >
                </div >
            </div >
        ),
    },
    {
        "id": "section-8",
        "title": "8. Ein besseres Erlebnis für Gäste",
        "content": "Wir bemerkten einen Anstieg positiver Bewertungen mit hohen Punktzahlen, da die Gäste mit den jüngsten Verbesserungen an all unseren Unterkünften sehr zufrieden waren. Durch die Implementierung nachhaltiger Standards wurden wir einzigartig und authentisch. Verbesserte Kommunikation führte zu einem freundlicheren, aber effektiveren Gästeservice, während saubere und gut gepflegte Unterkünfte das Gesamterlebnis der Gäste verbesserten. Darüber hinaus unterstützten wir lokale Initiativen und Unternehmen, indem wir sie unseren Gästen vorstellten, was eine Win-Win-Situation schuf, von der sowohl die Gäste als auch die lokale Wirtschaft enorm profitierten.",
        "imageSrc": "resirent_slogan", // Fügen Sie den Bildpfad hinzu, falls zutreffend
        "altText": "ResiRent Slogan" // Fügen Sie den Alternativtext hinzu, falls zutreffend
    },
    {
        "id": "section-9",
        "title": "9. Der Start von ResiRent",
        "content": "Letztendlich wagten wir den Sprung und gründeten ResiRent. Das Ziel von ResiRent ist es, Lösungen mit und für Vermieter zu entwickeln. Dies erreichen wir, indem wir Vermieter dabei unterstützen, nachhaltiger und finanziell unabhängiger von Plattformen zu werden. Unsere Maßnahmen umfassen die Unterstützung von Gastgebern bei der Umsetzung nachhaltiger Praktiken, wie die Reduzierung des Energie- und Wasserverbrauchs, die Minimierung von Abfall und ökologischem Fußabdruck sowie die Förderung lokaler Kultur und Naturschutz. Wir helfen Gastgebern auch beim Aufbau ihrer eigenen Website mit einem Fokus auf Nachhaltigkeit, indem wir Schulungen und Ressourcen zum nachhaltigen Einkommensmanagement und zur Preisgestaltung bereitstellen. Wir setzen uns für ein faires und transparentes Plattform-Policy ein, das nachhaltigen Tourismus unterstützt, und treten in den Dialog mit politischen Entscheidungsträgern, Branchenorganisationen und Verbrauchern ein, um nachhaltige Praktiken zu fördern. Schließlich werden wir eine Gemeinschaft von Gastgebern aufbauen, die sich für Nachhaltigkeit einsetzen, und bieten Peer-Learning, Networking und Anerkennung für ihre Bemühungen an. Durch die Implementierung dieser Maßnahmen können Vermieter zu einer nachhaltigeren Tourismusindustrie beitragen, umweltbewusste Reisende anziehen und ihren Ruf und ihre Rentabilität verbessern, was sowohl den Gästen als auch der lokalen Wirtschaft enorm zugute kommt."
    }
    // Fügen Sie nach Bedarf weitere Abschnitte hinzu
]
