import React from 'react'
import { createStyles, Group, rem, Button, Title, Box, Container, Text, Image, Paper, SimpleGrid } from '@mantine/core';
import { Icon24Hours, IconDiamond, IconGlobe, IconHome, IconHomeEco, IconHomeHeart, IconMedal, IconPhone } from '@tabler/icons-react';
import { IconHomeStar } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        width: '80%',
        marginTop: rem(60),
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '100%',
        margin: 'auto',
        fontWeight: 400,
        textAlign: 'justify'
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function HomePage({ language }) {

    const { classes, theme } = useStyles()

    const content = {
        '/': {
            'groupTitle': 'RESPONSIBLE STR',
            'mainTitle': 'Who are we for?',
            'description': "Responsibility",
            'text': 'We firmly believe that responsible hosting is the cornerstone of sustainable tourism. Our solutions are rooted in sustainability, and our commitment to this principle is evident in every aspect of our service. For instance, our website includes a dedicated suggestions page. This page guides tourists to explore your hidden gems, reducing the strain on overcrowded tourist hotspots and contributing to the flourishing of your city or region.',
            'description2': 'For hosts',
            'text2': 'Our services are tailored to cater to the needs of holiday homes. Whether you manage a single property or multiple for yourself or others, we are here to support you.',
            'description3': 'Diverse Hosting Options',
            'text3': 'Hosting comes in many forms. Whether you own a boat, a mountain chalet, or something entirely unique, we are here to support hosts of all kinds. Our aim is to empower and enable every host to succeed in their own way.',
        },
        '/nl': {
            'groupTitle': 'VERANTWOORDELIJKE GASTGEVERS',
            'mainTitle': 'Voor zie zijn wij?',
            'description': "Bewuste gastgevers",
            'text': 'Wij geloven sterk dat bewust gastgeven de hoeksteen is van duurzaam toerisme. Onze oplossingen zijn geworteld in duurzaamheid, en ons engagement voor dit principe blijkt uit elk aspect van onze service. Zo bevat onze website bijvoorbeeld een speciale suggestiepagina. Deze pagina gidst toeristen om jouw verborgen pareltjes te ontdekken, vermindert de drukte op overvolle toeristische hotspots en draagt bij aan de bloei van jouw stad of regio.',
            'description2': 'Voor gastgevers',
            'text2': 'Onze diensten zijn afgestemd op de behoeften van vakantiewoningen, of je nu zelf één accommodatie beheert of meerdere voor jezelf of voor anderen.',
            'description3': 'Diverse Hosting Opties',
            'text3': 'Hosten kan in vele vormen voorkomen. Of je nu een boot, een berghut, of iets geheel unieks bezit, wij zijn er om hosts van allerlei aard te ondersteunen. Ons doel is om elke host in hun eigen manier te laten slagen.',
        },
        '/de': {
            'groupTitle': 'VERANTWORTLICHE GASTGEBER',
            'mainTitle': 'Für Wen Sind Wir Da?',
            'description': "Verantwortung",
            'text': 'Wir sind fest davon überzeugt, dass verantwortliches Gastgeben der Grundpfeiler für nachhaltigen Tourismus ist. Unsere Lösungen basieren auf Nachhaltigkeit, und unser Bekenntnis zu diesem Prinzip zeigt sich in jedem Aspekt unseres Service. Unsere Website enthält zum Beispiel eine dedizierte Vorschlagsseite. Diese Seite führt Touristen zu deinen versteckten Schätzen, reduziert die Überlastung von überfüllten Touristenattraktionen und trägt zur Blüte deiner Stadt oder Region bei.',
            'description2': 'Für Gastgeber',
            'text2': 'Unsere Dienstleistungen sind darauf ausgerichtet, die Bedürfnisse von Ferienwohnungen zu erfüllen. Egal, ob du eine einzelne Unterkunft oder mehrere für dich selbst oder andere verwaltest, wir sind hier, um dich zu unterstützen.',
            'description3': 'Vielfältige Hosting-Optionen',
            'text3': 'Hosting kann in vielen Formen auftreten. Ob du ein Boot, eine Berghütte oder etwas völlig Einzigartiges besitzt, wir sind hier, um Gastgeber aller Art zu unterstützen. Unser Ziel ist es, jeden Gastgeber auf seine eigene Art und Weise erfolgreich zu machen.',
        },
    };

    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box>
            <Container size="lg" py={100} align="center">
                <Group position="center">
                    <Title order={2} className={classes.title} my="sm" display={'block'}>
                        {content[languagePrefix].mainTitle}
                    </Title>
                </Group>
                <Group position="center">
                    <Title order={1} weight={300} align="center" color="resirentPink">
                        {content[languagePrefix].groupTitle}
                    </Title>
                </Group>

                <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 3 }, { maxWidth: 'xl', cols: 3 }]} spacing="md" mt="md">
                    <Paper radius="ld" shadow='md' p="md">
                        <IconHomeEco size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                        <Title order={4} ta="center" mt="sm">
                            {content[languagePrefix].description}
                        </Title>
                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].text}
                        </Text>
                    </Paper>
                    <Paper shadow='md' p="md">
                        <IconHomeHeart size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                        <Title order={4} ta="center" mt="sm">
                            {content[languagePrefix].description2}
                        </Title>
                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].text2}
                        </Text>
                    </Paper>
                    <Paper shadow='md' p="md">
                        <IconHomeStar size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                        <Title order={4} ta="center" mt="sm">
                            {content[languagePrefix].description3}
                        </Title>
                        <Text className={classes.description} my="lg">
                            {content[languagePrefix].text3}
                        </Text>
                    </Paper>
                </SimpleGrid>

            </Container>
            {/* <Box align="right">
                        <Text component='a' href={imageButton[currentImage].link} order={1} weight={400} color="dimmed" mt="md">
                            {imageButton[currentImage].name}
                        </Text>
                    </Box> */}
        </Box >
    )
}
