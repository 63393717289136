import {
    createStyles,
    Header,
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    Center,
    Box,
    rem,
} from '@mantine/core';
import { languagesList, enNavItems, nlNavItems, deNavItems } from '../../languages/translations';
import {
    IconLanguage,
} from '@tabler/icons-react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';

const useStyles = createStyles((theme) => ({

    link: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,

        [theme.fn.smallerThan('sm')]: {
            height: rem(42),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.dark[2],
        }),
    },

    subLink: {
        width: '100%',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,

        // ...theme.fn.hover({
        //     backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        // }),

        '&:active': theme.activeStyles,
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirent[8],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirent[8],
        textAlign: 'center', // Center-align the contents
    },

    dropdownFooter: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        margin: `calc(${theme.spacing.md} * -1)`,
        marginTop: theme.spacing.sm,
        padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
            }`,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },
    anchor: {
        color: theme.black,
    },
    button: {
        '&:hover': {
            backgroundColor: theme.white,
            color: theme.colors.resirent[8],
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
        },
        // border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirent[8],
        textAlign: 'center', // Center-align the contents
    },
}));

export function TopNavBar() {
    const { classes, theme } = useStyles();
    const { i18n, t } = useTranslation();
    const { auth } = useAuth();

    // Change the language using i18n or your preferred localization library
    const navigate = useNavigate();
    const [language, setLanguage] = useState("id");


    const handleChangeLanguage = (newLanguage) => {

        const currentPath = window.location.pathname;

        const language = currentPath.startsWith('/nl') ? 'nl' : currentPath.startsWith('/de') ? 'de' : '';
        const navItems = language === "nl" ? nlNavItems : language === "de" ? deNavItems : enNavItems;
        // Change the language using i18n or your preferred localization library
        i18n.changeLanguage(newLanguage);

        const currentKey = Object.keys(navItems).find(key => navItems[key].ref === currentPath);

        // If currentKey is undefined then continue without changing the URL
        if (currentKey === undefined) {
            return;
        }

        // Get the URL using getNavLinkHref() or any other logic
        const targetNavItems = newLanguage === "nl" ? nlNavItems : newLanguage === "de" ? deNavItems : enNavItems;
        const newPageUrl = targetNavItems[currentKey].ref;

        // Redirect to the new page
        navigate(newPageUrl);
    };

    const links = languagesList.map((item) => (
        <UnstyledButton className={classes.subLink} key={item.name}>
            <Group noWrap align="flex-start">
                <div>
                    <Text size="sm" fw={500}>
                        <Button
                            key={item.short}
                            component="a"
                            data-value={item.short}
                            onClick={(event) => {
                                const newLanguage = event.currentTarget.getAttribute('data-value');
                                handleChangeLanguage(newLanguage);
                            }}
                            className={classes.button}
                        >
                            {item.name}
                        </Button>
                    </Text>
                </div>
            </Group>
        </UnstyledButton >
    ));

    return (
        <Box >
            <Header height={60} className={classes.header} px="md">
                <Group position="apart" sx={{ height: '100%' }}>
                    <Group sx={{ height: '100%' }} spacing={0}>

                        <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                            <HoverCard.Target>
                                <Center inline>
                                    <Box component="span" mr={5}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <IconLanguage size={28} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                                            <Text size="sm" weight={500} ml={5}>
                                                {t("language")}
                                            </Text>
                                        </div>
                                    </Box>
                                </Center>
                            </HoverCard.Target>

                            <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
                                <SimpleGrid rows={2} spacing={0}>
                                    {links}
                                </SimpleGrid>
                            </HoverCard.Dropdown>
                        </HoverCard>
                    </Group>

                    {!(auth?.role) ?
                        <> </> : (
                            <Group className={classes.hiddenMobile}>
                                <Button radius="lg" className={classes.control} component="a" href="/dashboard/profile" >
                                    {t("dashboard")}
                                </Button>
                            </Group>
                        )
                    }

                </Group>
            </Header>

        </Box>
    );
}